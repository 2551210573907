import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],

  message: "no message",
  isLoading: false,
};

export const groupsSlice = createSlice({
  name: "groupsSlice",
  initialState,
  reducers: {
    GroupsFetchAll: (state, action) => {
      state = { ...state, groups: action.payload, isLoading: false };

      return state;
    },

    GroupsAreLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };
      return state;
    },
  },
});

export const { GroupsAreLoading, GroupsFetchAll } = groupsSlice.actions;

export default groupsSlice.reducer;

//---- Original code

// import { GROUP_FETCH_ALL, GROUPS_ARELOADING } from '../actions/groupActions.js';

// export type State = {
//   groups: array,

//   message: string,

//   isLoading: boolean
// };

// const initialState: State = {
//   groups: [],

//   message: 'no message',
//   isLoading: false
// };

// export default function groupsReducer(
//   state: State = initialState,
//   { type, payload }
// ): State {
//   switch (type) {
//     case GROUPS_ARELOADING:
//       state = { ...state, isLoading: payload };

//       break;

//     case GROUP_FETCH_ALL:
//       state = { ...state, groups: payload, isLoading: false };

//       break;

//     default:
//       return state;
//   }

//   return state;
// }

//---- Slices version
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   groups: [],

//   message: "no message",
//   isLoading: false,
// };

// export const groupsSlice = createSlice({
//   name: "groupsSlice",
//   initialState,
//   reducers: {
//     GroupsFetchAll: (state, action) => {
//       state = { ...state, groups: action.payload, isLoading: false };

//       return state;
//     },

//     GroupsAreLoading: (state, action) => {
//       state = { ...state, isLoading: action.payload };
//       return state;
//     },
//   },
// });

// export const { GroupsAreLoading, GroupsFetchAll } = groupsSlice.actions;

//export default groupsSlice.reducer;
