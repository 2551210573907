import { useNavigate, useParams } from "react-router-dom";
import CarryList from "./CarryList";

const CarryListFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <CarryList useNavigate={nav} useParams={params} {...props} />;
};

export default CarryListFn;
