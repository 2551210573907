import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",

  trades: [],

  orphans: [],

  message: "no message",
  isLoading: false,
  isSaving: false,
};

export const groupSlice = createSlice({
  name: "groupSlice",
  initialState,
  reducers: {
    GroupIsLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };

      return state;
    },

    GroupIsSaving: (state, action) => {
      state = { ...state, isSaving: action.payload };

      return state;
    },

    GroupAdd: (state, action) => {
      state = action.payload;

      return state;
    },

    ClearGroup: (state, action) => {
      state = action.payload;

      return state;
    },

    GroupAddTrade: (state, action) => {
      state = action.payload;

      return state;
    },

    GroupRemoveTrade: (state, action) => {
      state = action.payload;

      return state;
    },

    GroupDelete: (state, action) => {
      state = action.payload;

      return state;
    },

    GroupFetch: (state, action) => {
      action.payload.isLoading = false;
      state = action.payload;

      return state;
    },

    GroupFetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };

      return state;
    },

    GroupUpdate: (state, action) => {
      state = { ...state, name: action.payload.name };

      return state;
    },

    GroupChangeName: (state, action) => {
      state = {
        ...state,
        name: action.payload,
      };

      return state;
    },
  },
});

export const {
  ClearGroup,
  GroupAdd,
  GroupAddTrade,
  GroupDelete,
  GroupChangeName,
  GroupFetch,
  GroupFetchError,
  GroupIsLoading,
  GroupIsSaving,
  GroupRemoveTrade,
  GroupUpdate,
} = groupSlice.actions;

export default groupSlice.reducer;
