import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  animalTypeId: 1,
  animalSubTypeId: 1,
  animalTypeName: null,
  numberOfHead: null,
  tradeGroupId: null,
  isSaleOverprice: false,
  saleWeightkg: null,
  saleCostCKg: null,
  saleCostHd: null,
  saleSkinWeight: null,
  sale2Weightkg: null,
  sale2CostCKg: null,
  sale2CostHd: null,
  purchaseWeightkg: null,
  purchaseCostCKg: null,
  purchaseCostHd: null,
  purchaseSkinWeight: null,
  carryId: null,
  carryTypeId: 1,
  carryName: null,
  carryValue: null,

  purchaseCowDescription: null,
  saleCowDescription: null,

  capInvestInStock: null,
  capReqForCarry: null,
  costOfCarryPerHead: null,
  costOfCarryPerKg: null,
  profitPerHead: null,
  profitPerHeadPa: null,
  profitPerAnnum: null,
  profitReturnOnCapitalPa: null,
  inventoryChange: null,
  inventoryFromCapitalAcc: null,
  inventoryAccToCapitalAcc: null,

  woolPriceHd: null,
  woolPriceKg: null,
  woolSaleWeight: null,

  tradeCalcDescription: null,

  totalCapRequired: null,
  weeksHeld: null,
  weeksHeldTill: null,
  ProfitPerHdPerWeek: null,

  message: "no message",

  isLoading: false,
  isSaving: false,
};

export const currentTradeSlice = createSlice({
  name: "currentTradeSlice",
  initialState,
  reducers: {
    ClearTrade: (state, action) => {
      state = action.payload;

      return state;
    },

    TradeIsLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };

      return state;
    },
    TradeIsSaving: (state, action) => {
      state = {
        ...state,
        isSaving: action.payload,
        message: action.payload == true ? "Trade is saving" : "Trade is saved",
      };

      return state;
    },

    AddTrade: (state, action) => {
      state = action.payload;

      return state;
    },

    FetchTrade: (state, action) => {
      action.payload.isLoading = false;

      if (action.payload.saleCostHd && action.payload.saleWeightkg > 0)
        action.payload.saleCostCKg =
          action.payload.saleCostHd / action.payload.saleWeightkg;
      //.toFixed(2);
      else action.payload.saleCostCKg = 0;

      if (action.payload.purchaseCostHd && action.payload.purchaseWeightkg > 0)
        action.payload.purchaseCostCKg =
          action.payload.purchaseCostHd / action.payload.purchaseWeightkg;
      //.toFixed(2);
      else action.payload.purchaseCostCKg = 0;

      state = action.payload;

      return state;
    },

    FetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };

      return state;
    },

    UpdateTradeName: (state, action) => {
      state = { ...state, name: action.payload };

      return state;
    },

    UpdateTradeType: (state, action) => {
      state = { ...state, type: action.payload };

      return state;
    },

    UpdateTradeHead: (state, action) => {
      state = { ...state, numberHead: action.payload };
      return state;
    },

    TradeChangeAnimalType: (state, action) => {
      state = { ...state, animalTypeId: action.payload };

      return state;
    },

    TradeChangeAnimalSubType: (state, action) => {
      state = {
        ...state,
        animalSubTypeId: action.payload,
        purchaseSkinWeight: null,
        saleSkinWeight: null,
        purchaseWeightkg: null,
        saleWeightkg: null,
      };

      return state;
    },

    TradeChangeCarryType: (state, action) => {
      state = { ...state, carryTypeId: action.payload };

      return state;
    },

    TradeChangeSellCost: (state, action) => {
      // let saleCostCKgValue = 0;

      // if (action.payload.toString().slice(-1) == ".") return state;

      // if (state.saleWeightkg > 0) {
      //   saleCostCKgValue = (action.payload / state.saleWeightkg).toFixed(2);
      // }

      //if (saleCostCKgValue == null) saleCostCKgValue = 0;

      state = {
        ...state,
        // saleCostHd: action.payload,
        // saleCostCKg: saleCostCKgValue,
      };

      return state;
    },

    TradeChangeSellCosting: (state, action) => {
      state = {
        ...state,
        // saleCostCKg: action.payload,
        // saleCostHd: (state.saleWeightkg * action.payload).toFixed(2),
      };

      return state;
    },

    TradeChangeSellWeight: (state, action) => {
      //console.log("Trade Change Sell Weight");
      state = {
        ...state,
        // saleWeightkg: action.payload,
        // saleCostHd: (action.payload * state.saleCostCKg).toFixed(2),
      };

      //console.log(state);
      return state;
    },

    //---- Wool

    TradeChangeWoolWeight: (state, action) => {
      state = {
        ...state,
        woolSaleWeight: action.payload,
        // woolPriceHd: (action.payload * state.woolPriceKg).toFixed(2),
      };

      return state;
    },

    TradeChangeWoolPriceKg: (state, action) => {
      state = {
        ...state,
        // woolPriceKg: action.payload,
        // woolPriceHd: (state.woolSaleWeight * action.payload).toFixed(2),
      };

      return state;
    },

    TradeChangeWoolPriceHead: (state, action) => {
      //  let woolKgValue = null;

      //  if (state.woolSaleWeight > 0) {
      //    woolKgValue = (action.payload / state.woolSaleWeight).toFixed(2);
      //  }

      state = {
        ...state,
        // woolPriceHd: action.payload,
        // woolPriceKg: woolKgValue,
      };

      return state;
    },

    //---- buy

    TradeChangeBuyCost: (state, action) => {
      // let purchaseWeightkgValue = null;

      // if (state.purchaseWeightkg > 0) {
      //   purchaseWeightkgValue = (
      //     action.payload / state.purchaseWeightkg
      //   ).toFixed(2);
      // }

      state = {
        ...state,
        //purchaseCostHd: action.payload,
        //purchaseCostCKg: purchaseWeightkgValue,
      };

      return state;
    },

    TradeChangeNumber: (state, action) => {
      state = {
        ...state,
        numberOfHead: action.payload,
      };

      return state;
    },

    TradeChangeName: (state, action) => {
      state = {
        ...state,
        name: action.payload,
      };

      return state;
    },

    TradeChangeBuyCostKg: (state, action) => {
      state = {
        ...state,
        //purchaseCostCKg: action.payload,
        //purchaseCostHd: (state.purchaseWeightkg * action.payload).toFixed(2),
      };

      return state;
    },

    TradeChangeBuyWeight: (state, action) => {
      state = {
        ...state,
        purchaseWeightkg: action.payload,
        purchaseCostHd: action.payload * state.purchaseCostCKg, //.toFixed(2),
      };

      return state;
    },

    TradeChangeCarry: (state, action) => {
      state = {
        ...state,
        carryId: action.payload,
      };

      return state;
    },

    TradeMessage: (state, action) => {
      state = { ...state, message: action.payload };
      return state;
    },

    TradeChangeGroup: (state, action) => {
      state = {
        ...state,
        tradeGroupId: action.payload,
      };

      return state;
    },

    TradeChangeDraft: (state, action) => {
      var isChangePerHdSell = state.saleCostCKg == action.payload.saleCostCKg;
      var isChangePerHdPurchase =
        state.purchaseCostCKg == action.payload.purchaseCostCKg;

      var isChangePerHdWool = state.woolPriceKg == action.payload.woolPriceKg;

      console.log(state.tradeGroupId);

      //---- Sale
      var saleCostKgVal =
        action.payload.saleCostCKg == null
          ? 0
          : Number(action.payload.saleCostCKg);

      var saleCostHdVal =
        action.payload.saleCostHd == null
          ? 0
          : Number(action.payload.saleCostHd);

      var saleCostCKgValue = 0;

      if (state.saleWeightkg > 0) {
        saleCostCKgValue = saleCostHdVal / state.saleWeightkg;
      }

      //---- Purchase
      var purchaseCostKgVal =
        action.payload.purchaseCostCKg == null
          ? 0
          : Number(action.payload.purchaseCostCKg);

      var purchaseCostHdVal =
        action.payload.purchaseCostHd == null
          ? 0
          : Number(action.payload.purchaseCostHd);

      var purchaseCostCKgValue = 0;

      if (state.purchaseWeightkg > 0) {
        purchaseCostCKgValue = purchaseCostHdVal / state.purchaseWeightkg; //.toFixed(2);
      }

      //---- Wool
      var woolCostKgVal =
        action.payload.woolPriceKg == null
          ? 0
          : Number(action.payload.woolPriceKg);

      var woolCostHdVal =
        action.payload.woolPriceHd == null
          ? 0
          : Number(action.payload.woolPriceHd);

      var woolCostCKgValue = 0;

      if (state.woolSaleWeight > 0) {
        woolCostCKgValue = woolCostHdVal / state.woolSaleWeight; //.toFixed(2);
      }

      state = {
        ...state,
        name: action.payload.name,
        numberOfHead: action.payload.numberOfHead,

        purchaseCowDescription: action.payload.purchaseCowDescription,
        purchaseSkinWeight: action.payload.purchaseSkinWeight,
        purchaseWeightkg: action.payload.purchaseWeightkg,
        sale2CostCKg: action.payload.sale2CostCKg,
        sale2CostHd: action.payload.sale2CostHd,
        sale2Weightkg: action.payload.sale2Weightkg,

        saleCowDescription: action.payload.saleCowDescription,
        saleSkinWeight: action.payload.saleSkinWeight,
        saleWeightkg: action.payload.saleWeightkg,
        woolSaleWeight: action.payload.woolSaleWeight,

        tradeGroupId: action.payload.tradeGroupId,
        //woolPriceKg: action.payload.WoolPriceKg,

        //woolPriceHd: action.payload.woolPriceHd,

        woolPriceKg:
          isChangePerHdWool == true ? woolCostCKgValue : woolCostKgVal,

        woolPriceHd:
          isChangePerHdWool == true
            ? woolCostHdVal
            : Number(action.payload.woolSaleWeight) * woolCostKgVal, //.toFixed(2),

        purchaseCostCKg:
          isChangePerHdPurchase == true
            ? purchaseCostCKgValue
            : purchaseCostKgVal,
        purchaseCostHd:
          isChangePerHdPurchase == true
            ? purchaseCostHdVal
            : Number(action.payload.purchaseWeightkg) * purchaseCostKgVal, //.toFixed(2),

        saleCostCKg:
          isChangePerHdSell == true ? saleCostCKgValue : saleCostKgVal,
        saleCostHd:
          isChangePerHdSell == true
            ? saleCostHdVal
            : Number(action.payload.saleWeightkg) * saleCostKgVal, //.toFixed(2),
      };

      return state;
    },

    UpdateTrade: (state, action) => {
      state = action.payload;

      return state;
    },

    DeleteTrade: (state, action) => {
      state = action.payload;

      return state;
    },
  },
});

export const {
  UpdateTradeName,
  UpdateTradeType,
  UpdateTradeHead,
  UpdateTrade,
  FetchTrade,
  AddTrade,
  FetchError,
  TradeChangeAnimalSubType,
  TradeChangeAnimalType,
  TradeChangeBuyCost,
  TradeChangeBuyCostKg,
  TradeChangeBuyWeight,
  TradeChangeCarry,
  TradeChangeCarryType,
  TradeChangeDraft,
  TradeChangeGroup,
  TradeChangeName,
  TradeChangeNumber,
  TradeChangeSellCost,
  TradeChangeSellCosting,
  TradeChangeSellWeight,
  TradeChangeWoolPriceHead,
  TradeChangeWoolPriceKg,
  TradeChangeWoolWeight,
  TradeIsLoading,
  TradeIsSaving,
  ClearTrade,
  DeleteTrade,
  TradeMessage,
} = currentTradeSlice.actions;

export default currentTradeSlice.reducer;
