import React from "react";
import { Routes, Route } from "react-router";
import indexRoutes from "../routes/index";

const routes = (
  <div>
    {/* <Switch> */}
    <Routes>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} id={key} element={prop.component} />;
      })}
      {/* </Switch> */}
    </Routes>
  </div>
);

export default routes;
