import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carrys: [],
  weightCarrys: [],
  weeksHeldCarrys: [],
  message: "no message",
  isLoading: false,
};

export const carrysSlice = createSlice({
  name: "carrysSlice",
  initialState,
  reducers: {
    CarrysFetchAll: (state, action) => {
      state = { ...state, carrys: action.payload, isLoading: false };

      return state;
    },

    CarrysWeeksHeld: (state, action) => {
      state = { ...state, weeksHeldCarrys: action.payload };

      return state;
    },

    CarrysFetchWeight: (state, action) => {
      state = { ...state, weightCarrys: action.payload };

      return state;
    },

    CarrysAreLoading: (state, action) => {
      state = { ...state, carry: action.payload };

      return state;
    },

    CarrysGroupError: (state, action) => {
      state = { ...state, message: action.payload };

      return state;
    },
  },
});

export const {
  CarrysAreLoading,
  CarrysFetchAll,
  CarrysFetchWeight,
  CarrysGroupError,
  CarrysWeeksHeld,
} = carrysSlice.actions;

export default carrysSlice.reducer;
