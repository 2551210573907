import { batchActions } from "redux-batched-actions";

import { recalcCarry, recalcTrade } from "../actions/calcActions";


import axios from "axios";

import {
  CarryIsLoading,
  CarryIsSaving,
  CarryAdd,
  CarryChangeAnimalType,
  CarryChangeAnimalSubType,
  CarryChangeDraft,
  CarryClear,
  CarryFetch,
  CarryChangeType,
  CarryError,
  CarryFetchError,
  CarryUpdate,
  CarryMessage,
} from "../reducers/carryReducer";

import {
  CarrysAreLoading,
  CarrysFetchAll,
  CarrysFetchWeight,
  CarrysGroupError,
  CarrysWeeksHeld,
} from "../reducers/carrysReducer";

import { updateAllTrades } from "../actions/currentTradeActions";

import { loadTypes } from "../actions/animalTypeActions";
import {
  TradeChangeCarryType,
  TradeChangeCarry,
} from "../reducers/currentTradeReducer";

async function addNewCarry(dispatch, auth, userId, newCarry, newCarryCalc, nav) {
  try {
    dispatch(CarrysAreLoading(true));

    dispatch(CarryIsSaving(true));

    var updatedCarry = { ...newCarry };

    updatedCarry.carryPerHd = newCarryCalc;

    const headers = { Authorization: `Bearer ${auth}` };

    console.log("Carry Auth");
    console.log(headers);
    console.log(auth);

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    const response = await axios.post(updateURL, updatedCarry, { headers });

    dispatch(CarryIsSaving(false));

    nav(`/carry/${userId}/carrydetail/${response.data.id}`);

    batchActions([
      dispatch(CarryAdd(response)),

      loadCarry(dispatch, auth, userId, response.data.id),
      loadCarrySByType(
        dispatch,
        auth,
        userId,
        updatedCarry.carryTypeId,
        null,
        null
      ),
      loadCarryS(dispatch, auth, userId),
      dispatch(CarryMessage("Carry Saved")),
    ]);
  } catch (error) {
    console.error(error);
  }
}

async function deleteCurrentCarry(
  dispatch,
  auth,
  userId,
  id,
  newCarry,
  trades, 
  nav
) {
  try {
    dispatch(CarrysAreLoading(true));

    const headers = { Authorization: `Bearer ${auth}` };

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    let responseCarry = await axios.delete(updateURL, { headers });

    nav(`/carry/${userId}/CarryList`);

    dispatch(
      batchActions([
        loadCarrySByType(dispatch, auth, userId, 1, null, null),
        loadCarrySByType(dispatch, auth, userId, 2, null, null), //weeks held
        loadCarryS(dispatch, auth, userId),

        updateAllTrades(
          dispatch,
          auth,
          userId,
          responseCarry.Id,
          responseCarry,
          trades
        ),
        dispatch(CarryClear(null)),
      ])
    );
  } catch (error) {
    dispatch(
      CarryError("Cannot delete carry - as it is used in at least one trade ")
    );

    dispatch(CarrysAreLoading(false));
  }
}

async function deleteCurrentGroupCarrys(dispatch, auth, userId, ids, trades, nav) {
  try {
    if (ids.length > 0) {
      const headers = { Authorization: `Bearer ${auth}` };

     
      dispatch(CarrysAreLoading(true));

      for (let i = 0; i < ids.length; i++) {
        var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}/detail/${ids[i]}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
        let responseCarry = await axios.delete(updateURL, { headers });

        
      }

      nav(`/carry/${userId}/CarryList`);

      dispatch(
        batchActions([
          loadCarrySByType(dispatch, auth, userId, 1, null, null),
          loadCarrySByType(dispatch, auth, userId, 2, null, null), //weeks held
          loadCarryS(dispatch, auth, userId),
        ])
      );
    }
  } catch (error) {
    dispatch(
      CarrysGroupError(
        "Cannot delete carrys selected - one of the carrys is used in at least one trade "
      )
    );

    dispatch(CarrysAreLoading(false));
  }
}

async function saveCarry(
  dispatch,
  auth,
  userId,
  id,
  newCarry,
  newCarryCalc,
  trades
) {
  try {
    dispatch(CarrysAreLoading(true));

    dispatch(CarryIsSaving(true));

    const headers = { Authorization: `Bearer ${auth}` };

    var updatedCarry = { ...newCarry };

    console.log("newCarryCalc");
    console.log(newCarryCalc);

    updatedCarry.carryPerHd = newCarryCalc;

    console.log(updatedCarry);

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    const response = await axios.put(updateURL, updatedCarry, { headers });

    dispatch(CarryIsSaving(false));

    batchActions([
      dispatch(CarryUpdate(response)),

      loadCarry(dispatch, auth, userId, id),
      loadCarrySByType(
        dispatch,
        auth,
        userId,
        updatedCarry.carryTypeId,
        null,
        null
      ),
      loadCarryS(dispatch, auth, userId),
      updateAllTrades(dispatch, auth, userId, id, updatedCarry, trades), //also need to update all of trades with this carry
    ]);
  } catch (error) {
    console.error(error);
  }
}

export async function loadCarryS(dispatch, auth, userId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    dispatch(CarrysAreLoading(true));

    var urlCarrys = `${process.env.REACT_APP_API_DOMAIN}/api/carrys/${userId}`;

    const response = await axios.get(urlCarrys, { headers });

    var carrys = response.data;

    dispatch(CarrysFetchAll(carrys));
  } catch (error) {
    console.error(error);

    dispatch(CarryFetchError("Cannot load carrys"));
  }
}

export async function loadCarrySByType(
  dispatch,
  auth,
  userId,
  typeId,
  tradeId,
  carryId
) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    var urlCarrys = `${process.env.REACT_APP_API_DOMAIN}/api/carrys/${userId}/type/${typeId}`;

    const response = await axios.get(urlCarrys, { headers });

    var carrys = response.data;

    var firstCarryId = carrys[0].id;

    if (carryId) firstCarryId = carryId;

    var carryUrl = `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}/detail/${firstCarryId}`;

    const responseCarry = await axios.get(carryUrl, { headers });

    var responseTrade;
    if (tradeId) {
      responseTrade = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/api/trades/${userId}/detail/${tradeId}`,
        { headers }
      );

      responseTrade.carryTypeId = typeId;

      responseTrade.carryId = firstCarryId;
    }

    if (typeId === 1) {
      //weigt Held{
      batchActions([
        dispatch(CarrysFetchWeight(carrys)),
        dispatch(CarryFetch(responseCarry)),
        dispatch(TradeChangeCarry(firstCarryId)),
        dispatch(TradeChangeCarryType(typeId)),
        recalcCarry(dispatch, auth, userId, firstCarryId, responseCarry.data),
      ]);
    } else {
      batchActions([
        dispatch(CarrysWeeksHeld(carrys)),
        dispatch(CarryFetch(responseCarry)),
        dispatch(TradeChangeCarry(firstCarryId)),
        dispatch(TradeChangeCarryType(typeId)),
        recalcCarry(dispatch, auth, userId, firstCarryId, responseCarry.data),
      ]);
    }

    if (responseTrade) {
      recalcTrade(
        dispatch,
        auth,
        userId,
        tradeId,
        responseCarry.data,
        responseTrade.data
      );
    }
  } catch (error) {
    console.error(error);

    dispatch(CarryFetchError("Cannot load carrys"));
  }
}

export async function loadCarry(dispatch, auth, userId, id, trade) {
  try {
    dispatch(CarryIsLoading(true));

    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${userId}/detail/${id}`,
      { headers }
    );

    response.message = "no message";

    dispatch(CarryIsLoading(false));

    if (trade) {
      batchActions([
        dispatch(CarryFetch(response)),

        recalcCarry(dispatch, auth, userId, 0, response.data),
      ]);

      recalcTrade(dispatch, auth, userId, 0, response.data, trade);
    } else {
      batchActions([
        dispatch(CarryFetch(response)),

        loadTypes(dispatch, response.data.animalTypeId),

        recalcCarry(dispatch, auth, userId, 0, response.data),
      ]);
    }
  } catch (error) {
    console.error("Load Error");

    dispatch(CarryFetchError("Cannot load Carry"));
  }
}

export const fetchCarry = (auth, userId, id, trade) => (dispatch) => {
  loadCarry(dispatch, auth, userId, id, trade);
};

export const deleteCarry = (auth, userId, id, trades, nav) => (dispatch) => {
  deleteCurrentCarry(dispatch, auth, userId, id, null, trades, nav);
};

export const fetchCarrys = (auth, userId) => (dispatch) => {
  loadCarryS(dispatch, auth, userId);
};

export const fetchCarrysByType =
  (auth, userId, typeId, tradeId, carryId) => (dispatch) => {
    loadCarrySByType(dispatch, auth, userId, typeId, tradeId, carryId);
  };

export const updateCarry =
  (auth, userId, id, newCarry, newCarryCalc, trades) => (dispatch) => {
    saveCarry(dispatch, auth, userId, id, newCarry, newCarryCalc, trades);
  };

export const addCarry =
  (auth, userId, newCarry, newCarryCalc, nav) => (dispatch) => {
    addNewCarry(dispatch, auth, userId, newCarry, newCarryCalc, nav);
  };

export const clearCarry = (userId) => (dispatch) => {
  dispatch(CarryClear(null));
};

export const changeAnimalType = (newValue) => (dispatch) => {
  dispatch(CarryChangeAnimalType(newValue));
};

export const changeAnimalSubType = (newValue) => (dispatch) => {
  dispatch(CarryChangeAnimalSubType(newValue));
};

export const changeCarryType = (newValue) => (dispatch) => {
  dispatch(CarryChangeType(newValue));
};

export const changeCarryDraft = (newValue) => (dispatch) => {
  dispatch(CarryChangeDraft(newValue));
};

export const deleteGroupCarrys = (auth, userId, Ids, trades, nav) => (dispatch) => {
  deleteCurrentGroupCarrys(dispatch, auth, userId, Ids, trades, nav);
};
