import {
  InfoFetch,
  InfoFetchError,
  InfoIsLoading,
} from "../reducers/infoReducer";
import { InfosFetchAll } from "../reducers/infosReducer";

import axios from "axios";

async function loadInfos(dispatch, auth, fieldName) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/Info/all`,
      { headers }
    );

    const info = {
      infos: response.data,

      isLoading: false,
      message: "Fetched Successfully",
    };

    dispatch(InfosFetchAll(info));
  } catch (error) {
    console.error("Load Error");

    dispatch(InfoFetchError("Cannot load Infos"));
  }
}

async function loadInfo(dispatch, auth, fieldName) {
  try {
    dispatch(InfoIsLoading(true));

    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/Info/${fieldName}`,
      { headers }
    );

    var info = {
      title: response.data.title,
      description: response.data.description,

      isLoading: false,
      message: "Fetched Successfully",
    };

    dispatch(InfoFetch(info));
  } catch (error) {
    console.error("Load Error");

    dispatch(InfoFetchError("Cannot load Trade"));
  }
}

export const fetchInfo = (auth, fieldName) => (dispatch) => {
  loadInfo(dispatch, auth, fieldName);
};

export const fetchAllInfos = (auth, userId) => (dispatch) => {
  loadInfos(dispatch, auth, userId);
};
