import React from "react";

import { Nav } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import LoadingSmall from "../../components/spinners/loadingSmall";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PopoverDetail from "../../components/popover/popoverdetail.jsx";
import { Card, CardBody, CardTitle, Row, Col, Label } from "reactstrap";
import { navSummary, navLoad } from "../../actions/navActions.js";
class SidebarTree extends React.Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo?.bind(this);
    this.activeRoute.bind(this);
    this.state = {
      carryPages: this.activeRoute("/trades") !== "" ? true : false,
      tradePages: this.activeRoute("/carrys") !== "" ? true : false,
      dropdownOpen: false,
      isNew: false,
      SheepToken: 2,
      SplitterTokens: [6, 7, 8, 9, 10, 11],
      SheepShornToken: [12],
      isLoaded: false,
    };
    this.toggle = this.toggle?.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById("logobg").classList.toggle("expand-logo");
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    // return this.props.location.pathname.indexOf(routeName) > -1
    //   ? "selected"
    //   : "";

    return "";
  }
  getTitle = (id) => {
    if (this.props.infos.find((x) => x.id === id) === undefined)
      return "No Title";

    return this.props.infos.find((x) => x.id === id).title;
  };

  getDescription = (id) => {
    if (this.props.infos.find((x) => x.id === id) === undefined)
      return "No Decription";

    return this.props.infos.find((x) => x.id === id).description;
  };

  componentDidMount() {}

  currencyFormat = (num) => {
    if (typeof num !== typeof 1.1) return "$0.00";

    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  toggleViewType(event) {
    var isChecked = event.target.checked;

    this.props.navSummary(isChecked);
  }

  navigateUrl = (url, e) => {
    //---- Close the menu when its small screen

    //console.log("navigate");
    //console.log(url);

    let element = document.getElementById("main-wrapper");

    if (window.innerWidth < 767) {
      element.setAttribute("data-sidebartype", "overlay");
      element.classList.remove("show-sidebar");
    }

    //console.log(element);

    //   element.setAttribute("data-sidebartype", "overlay");
    //   //document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    //   element.classList.remove("show-sidebar");
    // } else {
    //   element.setAttribute("data-sidebartype", "full");
    //   element.classList.add("show-sidebar");
    // }

    this.props.navLoad(true);
    this.props.useNavigate(url); // navigate to another route
  };

  logout() {
    this.props.logout.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }

  render() {
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.data.settings[0].sidebarbg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                     */}
            {/*--------------------------------------------------------------------------------*/}

            {/* <Nav id="sidebarnav"> */}
            <div className="navMenu">
              <ul>
                <li key="navigation">
                  <i className="fas fa-list" />
                  <span className="hide-menu textLarger navBlueColor">
                    {" "}
                    Navigation{" "}
                  </span>
                </li>
                <li className="widthTreeItems">
                  <ul>
                    <li>
                      <i className="fas fa-arrow-right" />

                      <span className="menu-tree">
                        <span
                          className="menu-tree"
                          onClick={this.navigateUrl.bind(this, `/user/main`)}
                        >
                          <span>Home</span>
                        </span>
                      </span>
                    </li>

                    <li>
                      <i className="fas fa-arrow-right" />

                      {this.props.nav?.isShowSummary && (
                        <span className="menu-tree">
                          <span>&nbsp; Summary </span>
                        </span>
                      )}

                      {!this.props.nav?.isShowSummary && (
                        <span className="menu-tree">
                          <span>&nbsp; Lists </span>
                        </span>
                      )}

                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={this.props.nav?.isShowSummary}
                          onChange={this.toggleViewType.bind(this)}
                        />
                        <span className="slider" />
                      </label>
                    </li>

                    <li>
                      <i className="fas fa-arrow-right" />

                      {/* <Link to={-1}>
                        <span className="menu-tree">
                          <span>&nbsp; Back</span>
                        </span>
                      </Link> */}

                      <span className="menu-tree">
                        <span
                          className="menu-tree"
                          onClick={this.navigateUrl.bind(this, -1)}
                        >
                          <span>Back</span>
                        </span>
                      </span>
                    </li>

                    {this.props.user?.role === "Admin" && (
                      <li>
                        <hr />
                        <i className="fas fa-arrow-right" />

                        <span
                          className="menu-tree"
                          onClick={this.navigateUrl.bind(
                            this,
                            `/admin/adduser`
                          )}
                        >
                          <span className="menu-tree">
                            <span>&nbsp; Admin </span>
                          </span>
                        </span>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
            {/* </Nav> */}

            {!this.props.nav?.isShowSummary && (
              <Nav id="sidebarnav">
                {this.props.isLoadingGroups ? (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />

                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/trades/${this.props.user.slug}/tradeGroups`
                        )}
                      >
                        <span className="hide-menu textLarger">
                          {" "}
                          Trade Groups{" "}
                        </span>
                      </span>
                    </li>
                    <li className="moveUp">
                      <LoadingSmall title="Loading Groups..." />
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />

                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/trades/${this.props.user.slug}/tradeGroups`
                        )}
                      >
                        <span className="hide-menu textLarger navBlueColor">
                          {" "}
                          Trade Groups{" "}
                        </span>
                      </span>
                    </li>
                    <li className="widthTreeItems">
                      <ul>
                        <li>
                          <i className="fas fas fa-plus" />

                          <span className="menu-tree">
                            <span
                              className="menu-tree"
                              onClick={this.navigateUrl.bind(
                                this,
                                `/trades/${this.props.user.slug}/tradeGroupDetail/create`
                              )}
                            >
                              <span>&nbsp; Add New Group</span>
                            </span>
                          </span>
                        </li>

                        <li>
                          <hr />
                        </li>

                        {this.props.groups?.slice(0, 5).map((prop, key) => {
                          return (
                            <li key={key}>
                              <i className="fas fa-arrow-right " />

                              <span
                                className="menu-tree"
                                onClick={this.navigateUrl.bind(
                                  this,
                                  `/trades/${this.props.user.slug}/tradeGroupDetail/${prop.id}`
                                )}
                              >
                                <span>
                                  &nbsp;{" "}
                                  {prop.trades.length > 0 ? (
                                    <span className="green">
                                      ({prop.trades.length})
                                    </span>
                                  ) : (
                                    <span className="red">
                                      ({prop.trades.length})
                                    </span>
                                  )}{" "}
                                  {prop.name.substring(0, 8)}
                                </span>
                              </span>
                            </li>
                          );
                        })}

                        <li>
                          <hr />
                        </li>

                        <li>
                          <i className="fas fas fa-plus" />

                          <span
                            className="menu-tree"
                            onClick={this.navigateUrl.bind(
                              this,
                              `/trades/${this.props.user.slug}/tradeGroups`
                            )}
                          >
                            <span className="menu-tree textSmaller">
                              <span>&nbsp;Show All Groups</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}

                {this.props.isLoadingTrades ? (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />

                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/trades/${this.props.user.slug}/tradeList`
                        )}
                      ></span>
                    </li>

                    <li className="moveUp">
                      <LoadingSmall title="Loading Trades..." />
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />
                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/trades/${this.props.user.slug}/tradeList`
                        )}
                      >
                        <span className="hide-menu textLarger navBlueColor">
                          {" "}
                          Trades{" "}
                        </span>
                      </span>
                    </li>
                    <li className="widthTreeItems">
                      <ul>
                        <li>
                          <i className="fas fas fa-plus" />

                          <span
                            className="menu-tree"
                            onClick={this.navigateUrl.bind(
                              this,
                              `/trades/${this.props.user.slug}/tradedetail/create`
                            )}
                          >
                            <span className="menu-tree">
                              <span>&nbsp;Add New Trade</span>
                            </span>
                          </span>
                        </li>

                        <li>
                          <hr />
                        </li>

                        {this.props.trades &&
                          this.props.trades.slice(0, 10).map((prop, key) => {
                            return (
                              <li key={key}>
                                <i className="fas fa-arrow-right " />

                                <span
                                  className="menu-tree"
                                  onClick={this.navigateUrl.bind(
                                    this,
                                    `/trades/${this.props.user.slug}/tradedetail/${prop.id}`
                                  )}
                                >
                                  <span className="menu-tree">
                                    <span>
                                      &nbsp;
                                      {!prop.isSaleOverprice ? (
                                        <span className="red">
                                          <i className="fas fa-arrow-alt-circle-down" />
                                        </span>
                                      ) : (
                                        <span className="green">
                                          <i className="fas fa-arrow-alt-circle-up" />
                                        </span>
                                      )}{" "}
                                      {prop.name && prop.name.substring(0, 8)}{" "}
                                      &nbsp; (
                                      {prop.profitPerHeadPa &&
                                        this.currencyFormat(
                                          prop.profitPerHeadPa
                                        )}
                                      )
                                    </span>
                                  </span>
                                </span>
                              </li>
                            );
                          })}

                        <li>
                          <hr />
                        </li>
                        <li>
                          <i className="fas fas fa-plus" />

                          <span
                            className="menu-tree"
                            onClick={this.navigateUrl.bind(
                              this,
                              `/trades/${this.props.user.slug}/tradeList`
                            )}
                          >
                            <span className="menu-tree textSmaller navBlueColor">
                              <span>&nbsp;Show All Trades</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}

                {this.props.isLoadingCarrys ? (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />
                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/carry/${this.props.user.slug}/CarryList`
                        )}
                      >
                        <span className="hide-menu textLarger navBlueColor">
                          {" "}
                          Cost Of Carry{" "}
                        </span>
                      </span>
                    </li>
                    <li className="moveUp">
                      <LoadingSmall title="Loading Carry..." />
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li key="trades">
                      <i className="fas fa-list" />
                      <span
                        className="menu-tree"
                        onClick={this.navigateUrl.bind(
                          this,
                          `/carry/${this.props.user.slug}/CarryList`
                        )}
                      >
                        <span className="hide-menu textLarger navBlueColor">
                          {" "}
                          Cost Of Carry{" "}
                        </span>
                      </span>
                    </li>
                    <li className="widthTreeItems">
                      <ul>
                        <li>
                          <i className="fas fas fa-plus" />

                          <span
                            className="menu-tree"
                            onClick={this.navigateUrl.bind(
                              this,
                              `/carry/${this.props.user.slug}/CarryDetail/create`
                            )}
                          >
                            <span className="menu-tree">
                              <span>&nbsp; Add New Carry</span>
                            </span>
                          </span>
                        </li>

                        <li>
                          <hr />
                        </li>

                        {this.props.carrys &&
                          this.props.carrys.length > 0 &&
                          this.props.carrys.slice(0, 5).map((prop, key) => {
                            return (
                              <li key={key}>
                                <i className="fas fa-arrow-right " />

                                <span
                                  className="menu-tree"
                                  onClick={this.navigateUrl.bind(
                                    this,
                                    `/carry/${this.props.user.slug}/CarryDetail/${prop.id}`
                                  )}
                                >
                                  <span className="menu-tree">
                                    <span>
                                      &nbsp;
                                      {prop.carryTypeId === 2 ? (
                                        <span className="green">
                                          ({prop.weeksHeld}Wks)
                                        </span>
                                      ) : (
                                        <span className="blue">
                                          ({prop.weightGainKg}kg)
                                        </span>
                                      )}{" "}
                                      &nbsp;
                                      {prop.name && prop.name.substring(0, 8)}
                                    </span>
                                  </span>
                                </span>
                              </li>
                            );
                          })}

                        <li>
                          <hr />
                        </li>
                        <li>
                          <i className="fas fas fa-plus" />

                          <span
                            className="menu-tree"
                            onClick={this.navigateUrl.bind(
                              this,
                              `/carry/${this.props.user.slug}/CarryList`
                            )}
                          >
                            <span className="menu-tree textSmaller">
                              <span>&nbsp;Show All Cost of Carrys</span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                )}
              </Nav>
            )}
            {this.props.nav?.isShowSummary &&
              this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Capital Required{" "}
                      &nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="capInvestInStock"
                        title={this.getTitle("capInvestInStock")}
                        description={this.getDescription("capInvestInStock")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Capital Invested In Stock</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.capInvestInStock &&
                          this.currencyFormat(
                            this.props.calc.trade.capInvestInStock
                          )}
                        {this.props.calc.trade.capInvestInStock === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.capInvestInStock &&
                          this.currencyFormat(
                            this.props.calc.trade.capInvestInStock
                          )}
                        {this.props.calc.trade.capInvestInStock === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12">
                        <Label className="summaryLabel">
                          Capital Required For CofC
                        </Label>
                      </Col>

                      <Col md="4">
                        <Label className="shornLabel"> Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornTotalCost &&
                          this.currencyFormat(
                            this.props.calc.trade.shornTotalCost
                          )}
                        {this.props.calc.trade.shornTotalCost === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornTotalCost &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornTotalCost
                          )}
                        {this.props.calc.trade.notShornTotalCost ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              !this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Capital
                      Required&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="capInvestInStock"
                        title={this.getTitle("capInvestInStock")}
                        description={this.getDescription("capInvestInStock")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Capital Invested In Stock</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.capInvestInStock &&
                          this.currencyFormat(
                            this.props.calc.trade.capInvestInStock
                          )}
                        {this.props.calc.trade.capInvestInStock === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Capital Required For CofC</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$ Required</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.capReqForCarry &&
                          this.currencyFormat(
                            this.props.calc.trade.capReqForCarry
                          )}
                        {this.props.calc.trade.capReqForCarry === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Cost Of
                      Carry&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="costOfCarryPerHead"
                        title={this.getTitle("costOfCarryPerHead")}
                        description={this.getDescription("costOfCarryPerHead")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Cost Of Carry / Head</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornTotalCostPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.shornTotalCostPerHead
                          )}
                        {this.props.calc.trade.shornTotalCostPerHead ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornTotalCostPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornTotalCostPerHead
                          )}
                        {this.props.calc.trade.notShornTotalCostPerHead ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Cost Of Carry / Kg</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornCostOfCarryPerKg &&
                          this.currencyFormat(
                            this.props.calc.trade.shornCostOfCarryPerKg
                          )}
                        {this.props.calc.trade.shornCostOfCarryPerKg ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornCostOfCarryPerKg &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornCostOfCarryPerKg
                          )}
                        {this.props.calc.trade.notShornCostOfCarryPerKg ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              !this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Cost Of
                      Carry&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="costOfCarryPerHead"
                        title={this.getTitle("costOfCarryPerHead")}
                        description={this.getDescription("costOfCarryPerHead")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Cost Of Carry / Head</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$/Head</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.costOfCarryPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.costOfCarryPerHead
                          )}
                        {this.props.calc.trade.costOfCarryPerHead ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Profit This
                      Trade&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="profitPerHeadPa"
                        title={this.getTitle("profitPerHeadPa")}
                        description={this.getDescription("profitPerHeadPa")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Profit Per Head</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornProfitPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.shornProfitPerHead
                          )}
                        {this.props.calc.trade.shornProfitPerHead ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornProfitPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornProfitPerHead
                          )}
                        {this.props.calc.trade.notShornProfitPerHead ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Total Profit</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornTotalProfit &&
                          this.currencyFormat(
                            this.props.calc.trade.shornTotalProfit
                          )}
                        {this.props.calc.trade.shornTotalProfit === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornTotalProfit &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornTotalProfit
                          )}
                        {this.props.calc.trade.notShornTotalProfit ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Return On Capital</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornReturnOnCapital &&
                          `${this.props.calc.trade.shornReturnOnCapital.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.shornReturnOnCapital ===
                          undefined && `0.00%`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>

                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornReturnOnCapital &&
                          `${this.props.calc.trade.notShornReturnOnCapital.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.notShornReturnOnCapital ===
                          undefined && `0.00%`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              !this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Profit This
                      Trade&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="profitPerHeadPa"
                        title={this.getTitle("profitPerHeadPa")}
                        description={this.getDescription("profitPerHeadPa")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Profit Per Head</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$/Head</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.profitPerHead &&
                          this.currencyFormat(
                            this.props.calc.trade.profitPerHead
                          )}
                        {this.props.calc.trade.profitPerHead === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Total Profit</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$ Total</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.totalProfit &&
                          this.currencyFormat(
                            this.props.calc.trade.totalProfit
                          )}
                        {this.props.calc.trade.totalProfit === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Return On Capital</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">ROC%</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.returnOnCapital &&
                          `${this.props.calc.trade.returnOnCapital.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.returnOnCapital === undefined &&
                          `0.00%`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Profit Annual
                      ROC&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="profitPerAnnum"
                        title={this.getTitle("profitPerAnnum")}
                        description={this.getDescription("profitPerAnnum")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Per Head P.A</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornPerHeadPA &&
                          this.currencyFormat(
                            this.props.calc.trade.shornPerHeadPA
                          )}
                        {this.props.calc.trade.shornPerHeadPA === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornPerHeadPA &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornPerHeadPA
                          )}
                        {this.props.calc.trade.notShornPerHeadPA ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Per Annum Profit</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornPerAnnumProfit &&
                          this.currencyFormat(
                            this.props.calc.trade.shornPerAnnumProfit
                          )}
                        {this.props.calc.trade.shornPerAnnumProfit ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornPerAnnumProfit &&
                          this.currencyFormat(
                            this.props.calc.trade.notShornPerAnnumProfit
                          )}
                        {this.props.calc.trade.notShornPerAnnumProfit ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Return On Capital PA</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.shornReturnOnCapitalPA &&
                          `${this.props.calc.trade.shornReturnOnCapitalPA.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.shornReturnOnCapitalPA ===
                          undefined && `0.00%`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">Not Shorn</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.notShornReturnOnCapitalPA &&
                          `${this.props.calc.trade.notShornReturnOnCapitalPA.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.notShornReturnOnCapitalPA ===
                          undefined && `0.00%`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            {this.props.nav?.isShowSummary &&
              !this.state.SheepShornToken.includes(
                this.props.currentTrade.animalSubTypeId
              ) && (
                <Card className="greenBorder treeWidth">
                  <CardBody className="bg-light ">
                    <CardTitle className="mb-0 subTitle">
                      <img alt="buy" src="/favicon.png" /> Profit Annual
                      ROC&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="profitPerAnnum"
                        title={this.getTitle("profitPerAnnum")}
                        description={this.getDescription("profitPerAnnum")}
                      />
                    </CardTitle>
                  </CardBody>
                  <CardBody>
                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Per Head P.A.</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">$/Head/PA</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.profitPerHeadPa &&
                          this.currencyFormat(
                            this.props.calc.trade.profitPerHeadPa
                          )}
                        {this.props.calc.trade.profitPerHeadPa === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Per Annum Profit</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">PA</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.profitPerAnnum &&
                          this.currencyFormat(
                            this.props.calc.trade.profitPerAnnum
                          )}
                        {this.props.calc.trade.profitPerAnnum === undefined &&
                          `$0.00`}
                      </Col>
                    </Row>

                    <Row className="rowHeight">
                      <Col md="12" className="summaryLabel">
                        <Label>Return On Capital PA</Label>
                      </Col>
                    </Row>
                    <Row className="rowHeight">
                      <Col md="4">
                        <Label className="shornLabel">ROC PA</Label> &nbsp;
                      </Col>
                      <Col md="8" className="summaryValueText">
                        {this.props.calc.trade.profitReturnOnCapitalPa &&
                          `${this.props.calc.trade.profitReturnOnCapitalPa.toFixed(
                            2
                          )}%`}
                        {this.props.calc.trade.profitReturnOnCapitalPa ===
                          undefined && `$0.00`}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}

            <div>
              <ul>
                <li>
                  <i className="fas fa-arrow-right" />

                  <span className="menu-tree">
                    <span
                      className="menu-tree"
                      onClick={this.logout.bind(this)}
                    >
                      <span>Logout</span>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.groups.groups,
    groups: state.groups.groups,
    carrys: state.carrys.carrys,
    trades: state.trades.trades,
    currentTrade: state.currentTrade,
    infos: state.infos.infos,
    isLoading: state.user.isLoading,
    isLoadingTrades: state.trades.isLoading,
    isLoadingCarrys: state.carrys.isLoading,
    isLoadingTrade: state.currentTrade.isLoading,
    isLoadingGroups: state.groups.isLoading,
    calc: state.calc,
    user: state.user,
    nav: state.nav,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      navSummary,
      navLoad,
    },
    dispatch
  );
};

// // You have to connect() to any reducers that you wish to connect to yourself
SidebarTree = connect(mapStateToProps, mapActionsToProps)(SidebarTree);

export default SidebarTree;
