import React from "react";

import { Form } from "react-final-form";
import ReactTable from "../../components/table/table.js";

import { Card, CardBody, CardTitle } from "reactstrap";
//import "react-table/react-table.css";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loading from "../../components/spinners/loading";
import Footer from "../../components/footer/footer.jsx";
import { navControls, navDelete } from "../../actions/navActions.js";

import { deleteGroupTrades } from "../../actions/tradeActions.js";

class TradeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedData: [],
    };
  }

  componentDidUpdate() {
    if (this.props.navIsPendingDelete) {
      this.props.navDelete({ isPendingDelete: false });


      //console.log(this.props.user.auth)
      this.props.deleteGroupTrades(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.state.selectedData,
        this.props.useNavigate
      );

    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.navControls({
      isShowControls: false,
      isShowDelete: true,
      isShowSave: false,
    });
  }

  currencyFormat = (num) => {
    if (typeof num !== typeof 1.1) return "$0.00";

    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  handleClick = (id, e) => {
    this.props.useNavigate(
      `/trades/${this.props.useParams.Slug}/tradeDetail/${id}`
    );
  };

  handleInputChange = (id) => {
    const local = this.state.selectedData;

    var index = this.state.selectedData.indexOf(id);

    if (index < 0) {
      local.push(id);
    } else {
      local.splice(index, 1);
    }

    // this.setState({
    //   selectedData: local,
    // });

    console.log(this.state.selectedData)
  };

  render() {
    const columns = {
      columns: [
        // {
        //   Header: "",
        //   accessor: "isGoing",
        //   width: 30,

        //   Cell: (props) => (
        //     <span>
        //       <input
        //         name="isGoing"
        //         height="50px"
        //         type="checkbox"
        //         className="checkbox"
        //       />
        //     </span>
        //   ),
        // },
        {
          Header: "",
          accessor: "isSelected",
          width: 50,
          Cell: (props) => <span className="checkbox"><input name="isGoing" height="50px" type="checkbox" className="checkbox"></input></span>,
        },
        
        {
          Header: "Trade Name",
          accessor: "name",
          width: 160,
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "# Of Head",
          accessor: "numberOfHead",
          width: 100,
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "Capital",
          accessor: "capInvestInStock",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">
              {this.currencyFormat(props.value)}
            </span>
          ),
        },
        {
          Header: "Capital Req for COC",
          accessor: "capReqForCarry",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">
              {this.currencyFormat(props.value)}
            </span>
          ),
        },
        {
          Header: "COC Per Hd",
          accessor: "costOfCarryPerHead",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">
              {this.currencyFormat(props.value)}
            </span>
          ),
        },
        {
          Header: "Profit Per Hd",
          accessor: "profitPerHeadPa",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">
              {this.currencyFormat(props.value)}
            </span>
          ),
        },
        {
          Header: "ROC Pa",
          accessor: "profitReturnOnCapitalPa",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">
              {props.value && `${props.value}%`}
            </span>
          ),
          },
          {
            Header: "",
            accessor: "isSaleOverprice",
            width: 30,
  
            Cell: (props) => (
              <span>
                {props.value === false ? (
                  <span className="red">
                    <i className="fas fa-arrow-alt-circle-down detail" />{" "}
                    {props.value}
                  </span>
                ) : (
                  <span className="green">
                    <i className="fas fa-arrow-alt-circle-up detail" />
                  </span>
                )}
              </span>
            ),
          },
          // {
          //   Header: "Delete",
          //   accessor: "id",
          //   Cell: (props) => (
          //     <i className="fas fa-minus-circle"  name="delete" value="1" />
          //   ),
          // }
      ],
    };

    return (
      <div>
        {this.props.isLoading ? (
          <Loading />
        ) : (
          <Card className="listHeight">
            <CardTitle className="bg-light p-3 mb-0">
              <img alt="buy" src="/buyLogo.png" />
              <span>&nbsp;</span>Trades
            </CardTitle>
            <CardBody className="tableCells tableContainer">
              <ReactTable
                columns={columns.columns}
                data={this.props.trades}
                enableColumnResizing={true}
                // getColumnProps={(col) => ({
                //   style: {
                //     size: 300,
                //     backgroundColor: "red",
                //   },
                // })}
                // getCellProps={(cellInfo) => ({
                //   style: { backgroundColor: "red", width: "200px" },
                // })}



                
                getRowProps={(row) => ({
                  onClick: (e) => {
                    if (e.target.className === "cursorHand") {
                      this.handleClick(row.original.id);
                    } else {
                      if (e.target.className === "checkbox") {
                        this.handleInputChange(row.original.id);
                      }
                    }
                  },
                })}
                // getCellProps={(cellInfo) => ({
                //   style: {
                //     backgroundColor: `hsl(${
                //       120 * ((120 - cellInfo.value) / 120) * -1 + 120
                //     }, 100%, 67%)`,
                //   },
                // })}
              />

              {/* <table
              data={this.props.trades}
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: (e) => {
                  if (e.target.className === "cursorHand") {
                    this.handleClick(rowInfo.original.id);
                  } else {
                    if (e.target.className === "checkbox") {
                      this.handleInputChange(rowInfo.original.id);
                    }
                  }
                },
              })}
              columns={[
                {
                  Header: "",
                  accessor: "isSaleOverprice",
                  width: 30,

                  Cell: (props) => (
                    <span>
                      <input
                        name="isGoing"
                        height="50px"
                        type="checkbox"
                        className="checkbox"
                      />
                    </span>
                  ),
                },
                {
                  Header: "",
                  accessor: "isSaleOverprice",
                  width: 30,

                  Cell: (props) => (
                    <span>
                      {props.value === false ? (
                        <span className="red">
                          <i className="fas fa-arrow-alt-circle-down detail" />{" "}
                          {props.value}
                        </span>
                      ) : (
                        <span className="green">
                          <i className="fas fa-arrow-alt-circle-up detail" />
                        </span>
                      )}
                    </span>
                  ),
                },
                {
                  Header: "Trade Name",
                  accessor: "name",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">{props.value}</span>
                  ),
                },
                {
                  Header: "# Of Head",
                  accessor: "numberOfHead",
                  width: 100,
                  Cell: (props) => (
                    <span className="cursorHand">{props.value}</span>
                  ),
                },
                {
                  Header: "Capital",
                  accessor: "capInvestInStock",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">
                      {this.currencyFormat(props.value)}
                    </span>
                  ),
                },
                {
                  Header: "Capital Req for COC",
                  accessor: "capReqForCarry",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">
                      {this.currencyFormat(props.value)}
                    </span>
                  ),
                },
                {
                  Header: "COC Per Hd",
                  accessor: "costOfCarryPerHead",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">
                      {this.currencyFormat(props.value)}
                    </span>
                  ),
                },
                {
                  Header: "Profit Per Hd",
                  accessor: "profitPerHeadPa",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">
                      {this.currencyFormat(props.value)}
                    </span>
                  ),
                },
                {
                  Header: "ROC Pa",
                  accessor: "profitReturnOnCapitalPa",
                  width: 160,
                  Cell: (props) => (
                    <span className="cursorHand">
                      {props.value && `${props.value}%`}
                    </span>
                  ),
                },
              ]} //capReqForCarry
              defaultPageSize={20}
              className="-striped -highlight"
              showPageSizeOptions={false}
              showPagination={false}
            /> */}
            </CardBody>
          </Card>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.trades.trades,
    trades: state.trades.trades,
    isLoading: state.trades.isLoading,
    navIsPendingDelete: state.nav.isPendingDelete,
    user: state.user,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      navControls,
      navDelete,
      deleteGroupTrades,
    },
    dispatch
  );
};

// You have to connect() to any reducers that you wish to connect to yourself
TradeList = connect(mapStateToProps, mapActionsToProps)(TradeList);

//(Form({ form: "TRADE_LIST", enableReinitialize: true })(TradeList))

export default TradeList;
