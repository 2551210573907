/*--------------------------------------------------------------------------------*/
/*                          Form Layout Pages Dropdown                            */
/*--------------------------------------------------------------------------------*/

import TradeListFn from "../views/trades/tradeListFn.jsx";
import CarryListFn from "../views/carry/CarryListFn.jsx";
import CarryDetailFn from "../views/carry/CarryDetailFn";
import UserDetailFn from "../views/users/UserDetailFn.jsx";

import TradeDetailFn from "../views/trades/tradeDetailFn.jsx";
import InfoViewFn from "../views/info/infoFn.jsx";
import TradeGroupsFn from "../views/trades/tradeGroupsFn";
import TradeGroupDetailFn from "../views/trades/tradeGroupDetailFn";
import PrivacyViewFn from "../views/info/privacyPolicyFn";
import TermsViewFn from "../views/info/termsandconditionsFn.jsx";
import MainMenuViewFn from "../views/info/mainMenuFn.jsx";

var ThemeRoutes = [
  {
    collapse: true,
    path: "/trades",
    name: "Trades",
    state: "tradePages",
    icon: "fas fa-list",
    child: [
      {
        path: "/trades/:Slug/tradeGroups",
        name: "Trade Groups",
        visible: true,

        icon: "fas fa-clipboard-list",
        component: <TradeGroupsFn />,
      },
      {
        path: "/trades/:Slug/tradeGroupDetail/:Id",
        name: "Trade Groups Detail",
        visible: false,

        icon: " fas fa-plus-circle",
        component: <TradeGroupDetailFn />,
      },
      {
        path: "/trades/:Slug/tradeGroupDetail/create",
        name: "New Trade Group",
        visible: true,

        icon: "fas fa-plus",
        component: <TradeGroupDetailFn />,
      },
      {
        path: "/trades/:Slug/tradeList",
        name: "Trade List",
        visible: true,

        icon: "fas fa-clipboard-list",
        component: <TradeListFn />,
      },
      {
        path: "/trades/:Slug/tradeDetail/:Id",
        name: "Trade Detail",
        visible: false,
        icon: "fas fa-edit",
        component: <TradeDetailFn />,
      },
      {
        path: "/trades/:Slug/tradeDetail/create",
        name: "New Trade",
        visible: true,
        icon: "fas fa-plus",
        component: <TradeDetailFn />,
      },
      {
        path: "/trades/info/:Info",
        name: "Information",
        visible: false,

        icon: "fas fa-edit",
        component: <InfoViewFn />,
      },
    ],
  },
  {
    collapse: true,
    path: "/Carry",
    name: "Cost Of Carry",
    state: "carryPages",
    icon: "mdi mdi-account-card-details",
    child: [
      {
        path: "/Carry/:Slug/CarryList",
        name: "Cost Of Carry",
        visible: true,

        icon: "fas fa-clipboard-list",
        component: <CarryListFn />,
      },
      {
        path: "/Carry/:Slug/CarryDetail/:Id",
        name: "Cost Of Carry Detail",
        visible: false,

        icon: "fas fa-edit",
        component: <CarryDetailFn />,
      },
      {
        path: "/Carry/:Slug/CarryDetail/Create",
        name: "New Cost Of Carry",
        visible: true,

        icon: "fas fa-plus",
        component: <CarryDetailFn />,
      },
    ],
  },
  {
    collapse: true,
    path: "/admin",
    name: "User Admin",
    state: "userAdminPages",
    icon: "mdi mdi-account-card-details",
    child: [
      {
        path: "/admin/addUser",
        name: "Admin Add User",
        visible: false,

        icon: "fas fa-edit",
        component: <UserDetailFn />,
      },
    ],
  },
  {
    collapse: true,
    path: "/user",
    name: "User Info",
    state: "userInformation",
    icon: "mdi mdi-account-card-details",
    child: [
      {
        path: "/user/privacy",
        name: "Privacy Policy",
        visible: false,

        icon: "fas fa-edit",
        component: <PrivacyViewFn />,
      },
      {
        path: "/user/terms",
        name: "Terms And Conditions",
        visible: false,

        icon: "fas fa-edit",
        component: <TermsViewFn />,
      },
      {
        path: "/user/main",
        name: "Main Menu",
        visible: false,

        icon: "fas fa-edit",
        component: <MainMenuViewFn />,
      },
    ],
  },
];
export default ThemeRoutes;
