import React from "react";
import ReactTable from "../../components/table/table.js"; //"../../components/table/table.js";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  Button,
  InputGroupText,
} from "reactstrap";
//import "react-table/react-table.css";
import Footer from "../../components/footer/footer.jsx";
import { Field, Form } from "react-final-form"; //formValueSelector
import {
  fetchGroup,
  addTrade,
  removeTrade,
  saveGroup,
  deleteGroup,
  addGroup,
  changeGroupName,
  clearGroup,
} from "../../actions/groupActions.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loading from "../../components/spinners/loading";
import {
  navControls,
  navSave,
  navDelete,
  navDuplicate,
  navLoad,
} from "../../actions/navActions.js";
import { useNavigate } from "react-router-dom";

class TradeGroupDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      obj: {},
      newTradeId: 0,
      isDeletePending: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  onNameChange = (event) => {
    var target = event.target.value;

    //--- Need to check that this is not null
    this.props.changeGroupName(target);
  };

  currencyFormat = (num) => {
    if (typeof num !== typeof 1.1) return "$0.00";

    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  componentDidUpdate() {
    window.scrollTo(0, 0);

    //console.log("Component Did update");

    //console.log(this.props);

    if (this.props.navIsPendingSave == true) {
      this.props.navSave({ isPendingSave: false });

      var newGroup = {
        name: this.props.group.name == "" ? `New Group` : this.props.group.name,
      };

      if (this.props.useParams.Id) {
        //console.log("saveGroup");

        this.props.saveGroup(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id,
          newGroup
        );
      } else {
        //console.log("add group");
        this.props.addGroup(
          this.props.user.auth,
          this.props.useParams.Slug,
          newGroup,
          this.props.useNavigate
        );

       
      }

      //console.log(newGroup);
    }

    if (this.props.navIsPendingDuplicate == true) {
      var duplicate = { ...this.props.group };

      duplicate.name = `${this.props.group.name}-copy`;

      this.props.navDuplicate({ isPendingDuplicate: false });

      //console.log("Copy");
      // console.log(duplicate);

      this.props.addGroup(
        this.props.user.auth,
        this.props.useParams.Slug,
        duplicate,
        this.props.useNavigate
      );
    }

    if (this.props.navIsPendingDelete == true) {
      //console.log("Delete Group");
      this.props.navDelete({ isPendingDelete: false });
      this.props.deleteGroup(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.props.useNavigate
      );
    }

    if (this.props.navIsPendingLoad == true) {
      if (this.props.location.pathname.endsWith("create")) {
        //console.log("Clear Group");
        this.props.clearGroup(this.props.user.auth, this.props.useParams.Slug);
      } else {
        this.props.fetchGroup(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id
        );
      }

      this.props.navLoad(false);
    }
  }

  componentDidMount() {
    this.props.navControls({ isShowControls: true });

    if (this.props.useParams.Id === ":Id")
      this.props.useNavigate("/trades/info?Details=noTradeId");

    if (this.props.location.pathname.endsWith("create")) {
      this.props.clearGroup(this.props.user.auth, this.props.useParams.Slug);
    } else {
      this.props.fetchGroup(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id
      );
    }
  }

  changeTrade(e) {
    this.setState({ newTradeId: e.target.value });
  }

  removeTrade(id) {
    this.props.removeTrade(
      this.props.user.auth,
      this.props.useParams.Slug,
      this.props.useParams.Id,
      id
    );
  }

  addTrade() {
    if (this.state.newTradeId === 0 && this.props.group.orphans.length > 0) {
      this.setState({ newTradeId: this.props.group.orphans[0].id });

      this.props.addTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.museParams.Id,
        this.props.group.orphans[0].id
      );
    } else {
      this.props.addTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.state.newTradeId
      );
    }
  }

  onCellClick = (cell) => {

    //console.log(cell);

    if (cell.column.Header == "Remove") {

      console.log("Delete");

      this.removeTrade(cell.row.original.id);
    }
    else{
      console.log(cell.row.original.id);

      this.props.useNavigate(
        `/trades/${this.props.useParams.Slug}/tradeDetail/${cell.row.original.id}`
      ); // navigate to another route
    }

  }

  
  handleDelete = (id) => {

    console.log("Delete");
    console.log(id);

    //this.removeTrade(id);
  };

  handleClick = (id, e) => {
    this.props.useNavigate(
      `/trades/${this.props.useParams.Slug}/tradeDetail/${id}`
    ); // navigate to another route
  };

  render() {
    let options = this.props.group?.orphans.map((td) => (
      <option key={td.id} value={td.id}>
        {td.name}
      </option>
    ));

    const columns = {
      columns: [
        {
          Header: "Trade Name",
          accessor: "name",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">{props.value}</span>
          ),
        },
        {
          Header: "# Head",
          accessor: "numberOfHead",
          width: 160,
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "Buy Price $/Hd",
          accessor: "purchaseCostHd",
          Cell: (props) => (
            <span className="cursorHand">
              {props.value ? this.currencyFormat(props.value) : 0}
            </span>
          ),
        },
        {
          Header: "Purchase Weight",
          accessor: "purchaseWeightkg",
          Cell: (props) => <span className="cursorHand">{props.value} kg</span>,
        },
        {
          Header: "Profit Per Hd",
          accessor: "profitPerHeadPa",
          Cell: (props) => (
            <span className="cursorHand">
              {props.value ? this.currencyFormat(props.value) : 0}
            </span>
          ),
        },
        {
          Header: "ROC pa",
          accessor: "profitReturnOnCapitalPa",
          Cell: (props) => <span className="cursorHand">{props.value}%</span>,
        },

        {
          Header: "Cap Invest In Stock (inc CoC)",
          accessor: "capInvestInStockIncCarry",
          Cell: (props) => (
            <span className="cursorHand">
              {props.value ? this.currencyFormat(props.value) : 0}
            </span>
          ),
        },
        {
          Header: "Profit Per Head Per Week",
          accessor: "profitPerHdPerWeek",
          Cell: (props) => (
            <span className="cursorHand">
              {props.value ? this.currencyFormat(props.value) : 0}
            </span>
          ),
        },
        {
          Header: "Weeks Held",
          accessor: "weeksHeld",
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "Held Till Date",
          accessor: "weeksHeldTill",
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },

        {
          Header: "Remove",
          accessor: "id",
          Cell: (props) => (
            <i className="fas fa-minus-circle"  name="delete" value="1" />
          ),
        },
      ],
    };

    const onSubmit = (values) => {
      console("Do it");
    };

    return (
      <div className="listHeight">
        <Form
          onSubmit={onSubmit}
          initialValues={this.props.group}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              {this.props.group.isLoading ? (
                <Loading />
              ) : (
                <Card className="listHeight">
                  <CardTitle className="bg-light p-3 mb-0">
                    <Row>
                      <Col md="3">
                        <img alt="buy" src="/buyLogo.png" />
                        <span>&nbsp;</span>Trade Group
                      </Col>

                      <Col md="3">
                        <InputGroup>
                          <div className="input-group-append">
                            <InputGroupText>
                              <i className="far fa-user" />
                            </InputGroupText>
                          </div>
                          <Field
                            name="name"
                            className="field textSmaller"
                            onChange={this.onNameChange.bind(this)}
                            component="input"
                            placeholder="Create New Trade Group"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <CardBody className="tableCells">
                      <ReactTable
                        columns={columns.columns}
                        data={this.props.group.trades}
                        // getRowProps={(row) => ({
                         
                        //   onClick: () => this.onRowClick(row.original.id),
                        // })}
                        getCellProps={(cell) => ({
                          onClick: () => this.onCellClick(cell)
                        })}
                       

                      />
                    </CardBody>
                  </CardTitle>
                </Card>
              )}
            </form>
          )}
        />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.group,
    group: state.group,
    orphans: state.orphans,
    name: state.name,
    navIsPendingDelete: state.nav.isPendingDelete,
    navIsPendingSave: state.nav.isPendingSave,
    navIsPendingDuplicate: state.nav.isPendingDuplicate,
    navIsPendingLoad: state.nav.isPendingLoad,
    user: state.user,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchGroup,
      addTrade,
      removeTrade,
      saveGroup,
      addGroup,
      deleteGroup,
      navControls,
      navSave,
      navDelete,
      navDuplicate,
      changeGroupName,
      clearGroup,
      navLoad,
    },
    dispatch
  );
};

// You have to connect() to any reducers that you wish to connect to yourself
TradeGroupDetail = connect(
  mapStateToProps,
  mapActionsToProps
)(TradeGroupDetail);

//(Form({ form: "GROUP_DETAIL", enableReinitialize: true })(TradeGroupDetail))

export default TradeGroupDetail;
