import React from "react";
import { Form } from "react-final-form";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchInfo } from "../../actions/infoActions.js";

import { Card, CardBody, CardTitle, Row, Col, Label, Button } from "reactstrap";

import Footer from "../../components/footer/footer.jsx";

class TermsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchInfo(this.props.useParams.Info);
  }

  back() {
    this.props.useNavigate(-1);
  }

  // outside your render() method
  // renderField = (field) => (
  //   <div className="input-row">
  //     <Label>{field.input.value}</Label>
  //     {/* <input {...field.input} type="text"/> */}
  //     {field.meta.touched && field.meta.error && (
  //       <span className="error">{field.meta.error}</span>
  //     )}
  //   </div>
  // );

  render() {
    var banner;

    return (
      // <div className="d-flex justify-content-center ">
      <div className="minWidth">
        <div className="screenView">
          <Card>
            <CardTitle className="mb-0 bg-light p-3 ">
              <Row>
                <Col>
                  <h2>Terms and Conditions</h2>
                </Col>
              </Row>
            </CardTitle>

            <CardBody>
              <Row>
                <Col>
                  <b>KLR Marketing Pty Ltd – WEBSITE TERMS OF USE</b>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  This website www.klrmarketing.com.au is operated by KLR
                  Marketing Pty Ltd . It is available at:
                  www.klrmarketing.com.au and may be available through other
                  addresses or channels.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Consent:</b> By accessing and/or using our Site, you agree
                  to these terms of use and our Privacy Policy.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>Refund Policy: Our refund policy is available here. </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Terms.</b> Please read these Terms carefully and
                  immediately cease using our Site if you do not agree to them.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Variations:</b> We may, at any time and at our discretion,
                  vary these Terms by publishing the varied terms on our Site.
                  We recommend you check our Site regularly to ensure you are
                  aware of our current terms. Materials and information on this
                  Site (Content) are subject to change without notice. We do not
                  undertake to keep our Site up-to-date and we are not liable if
                  any Content is inaccurate or out-of-date.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Licence to use our Site:</b> We grant you a non-exclusive,
                  royalty-free, revocable, worldwide, non-transferable licence
                  to use our Site in accordance with these Terms. All other uses
                  are prohibited without our prior written consent.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Prohibited conduct:</b> You must not do or attempt to do
                  anything: that is unlawful; prohibited by any laws applicable
                  to our Site; which we would consider inappropriate; or which
                  might bring us or our Site into disrepute, including (without
                  limitation):{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (a) anything that would constitute a breach of an individual’s
                  privacy (including uploading private or personal information
                  without an individual's consent) or any other legal rights;{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (b) using our Site to defame, harass, threaten, menace or
                  offend any person;{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>(c) interfering with any user using our Site; </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (d) tampering with or modifying our Site, knowingly
                  transmitting viruses or other disabling features, or damaging
                  or interfering with our Site, including (without limitation)
                  using trojan horses, viruses or piracy or programming routines
                  that may damage or interfere with our Site;{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (e) using our Site to send unsolicited email messages; or{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (f) facilitating or assisting a third party to do any of the
                  above acts.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Exclusion of competitors:</b> You are prohibited from using
                  our Site, including the Content, in any way that competes with
                  our business.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Information:</b> The Content is not comprehensive and is
                  for general information and educational purposes only. It does
                  not take into account your specific needs, objectives or
                  circumstances, and it is not advice. While we use reasonable
                  attempts to ensure the accuracy and completeness of the
                  Content, we make no representation or warranty in relation to
                  it, to the maximum extent permitted by law.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Intellectual Property rights:</b> Unless otherwise
                  indicated, we own or licence all rights, title and interest
                  (including intellectual property rights) in our Site and all
                  of the Content. Your use of our Site and your use of and
                  access to any Content does not grant or transfer to you any
                  rights, title or interest in relation to our Site or the
                  Content. You must not:{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>(a) copy or use, in whole or in part, any Content;</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (b) reproduce, retransmit, distribute, disseminate, sell,
                  publish, broadcast or circulate any Content to any third
                  party; or{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (c) breach any intellectual property rights connected with our
                  Site or the Content, including (without limitation) altering
                  or modifying any of the Content, causing any of the Content to
                  be framed or embedded in another website or platform, or
                  creating derivative works from the Content.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>User Content:</b> You may be permitted to post, upload,
                  publish, submit or transmit relevant information and content
                  (User Content) on our Site. By making available any User
                  Content on or through our Site, you grant to us a worldwide,
                  irrevocable, perpetual, non-exclusive, transferable,
                  royalty-free licence to use the User Content, with the right
                  to use, view, copy, adapt, modify, distribute, license, sell,
                  transfer, communicate, publicly display, publicly perform,
                  transmit, stream, broadcast, access, or otherwise exploit such
                  User Content on, through or by means of our Site.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  You agree that you are solely responsible for all User Content
                  that you make available on or through our Site. You represent
                  and warrant that:{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (a) you are either the sole and exclusive owner of all User
                  Content or you have all rights, licences, consents and
                  releases that are necessary to grant to us the rights in such
                  User Content (as contemplated by these Terms); and{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (b) neither the User Content nor the posting, uploading,
                  publication, submission or transmission of the User Content or
                  our use of the User Content on, through or by means of our
                  Site will infringe, misappropriate or violate a third party’s
                  intellectual property rights, or rights of publicity or
                  privacy, or result in the violation of any applicable law or
                  regulation.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  We do not endorse or approve, and are not responsible for, any
                  User Content. We may, at any time (at our sole discretion),
                  remove any User Content.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Mastermind Member User Tools and Content</b>{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Members of the KLR Mastermind will have access to the
                  membership website, 30 second market reports and all current
                  calculators hosted on the membership site.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Calculators time out on the 30th June each year and are
                  updated prior to June 30 for download from the membership
                  website.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Access to the members site is restricted to paid up members of
                  the KLR Mastermind.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Conditions of Use:</b> The KLR Mastermind membership
                  platform, tools and calculaotrs are for exclusive use of
                  members of the mastermind and the people in their direct
                  business.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Sharing of the log in details, content and tools hosted on the
                  Mastermind platform is a violation of our terms and
                  conditions.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Mastermind KLR Web App Calculator</b>{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  The Mastermind Web App Calculator is a cloud based tool for
                  desktops and mobile devices and will only be available to paid
                  up KLR Mastermind Members. Internet service is required to use
                  the KLR Web App Calculator.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Third party sites:</b> Our Site may contain links to
                  websites operated by third parties. Unless expressly stated
                  otherwise, we do not control, endorse or approve, and are not
                  responsible for, the content on those websites. You should
                  make your own investigations with respect to the suitability
                  of those websites.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Discontinuance:</b> We may, at any time and without notice
                  to you, discontinue our Site, in whole or in part. We may also
                  exclude any person from using our Site, at any time and at our
                  sole discretion. We are not responsible for any Liability you
                  may suffer arising from or in connection with any such
                  discontinuance or exclusion.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Warranties and disclaimers:</b> To the maximum extent
                  permitted by law, we make no representations or warranties
                  about our Site or the Content, including (without limitation)
                  that:{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (a) they are complete, accurate, reliable, up-to-date and
                  suitable for any particular purpose;{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  (b) access will be uninterrupted, error-free or free from
                  viruses; or{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>(c) our Site will be secure. </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  You read, use and act on our Site and the Content at your own
                  risk.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Limitation of liability:</b> To the maximum extent
                  permitted by law, we are not responsible for any loss, damage
                  or expense, howsoever arising, whether direct or indirect
                  and/or whether present, unascertained, future or contingent
                  (Liability) suffered by you or any third party, arising from
                  or in connection with your use of our Site and/or the Content
                  and/or any inaccessibility of, interruption to or outage of
                  our Site and/or any loss or corruption of data and/or the fact
                  that the Content is incorrect, incomplete or out-of-date.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Indemnity:</b> To the maximum extent permitted by law, you
                  must indemnify us, and hold us harmless, against any Liability
                  suffered or incurred by us arising from or in connection with
                  your use of our Site or any breach of these Terms or any
                  applicable laws by you. This indemnity is a continuing
                  obligation, independent from the other obligations under these
                  Terms, and continues after these Terms end. It is not
                  necessary for us to suffer or incur any Liability before
                  enforcing a right of indemnity under these Terms.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Termination:</b> These Terms are effective until terminated
                  by us, which we may do at any time and without notice to you.
                  In the event of termination, all restrictions imposed on you
                  by these Terms and limitations of liability set out in these
                  Terms will survive.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Our Site may be accessed throughout Australia and overseas. We
                  make no representation that our Site complies with the laws
                  (including intellectual property laws) of any country outside
                  Australia. If you access our Site from outside Australia, you
                  do so at your own risk and are responsible for complying with
                  the laws of the jurisdiction where you access our Site.{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>For any questions and notices, please contact us at:</b>{" "}
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>KLR Marketing </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>Email: admin@klrmarketing.com.au </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <b>Last update: 21 October, 2019.</b>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.info,
    currentInfo: state.info,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInfo,
    },
    dispatch
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// TermsView = Form({ form: "TERMS_FORM", enableReinitialize: true })(TermsView);

// You have to connect() to any reducers that you wish to connect to yourself
TermsView = connect(mapStateToProps, mapActionsToProps)(TermsView);

export default TermsView;
