import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AnimalSubTypeId: 0,
  AnimalSubType: "",
  AnimalType: "",
  AnimalTypeId: 0,
};

export const animalTypeSlice = createSlice({
  name: "animalType",
  initialState,
  reducers: {
    animalTypefetch: (state, action) => {
      state = action.payload;

      return state;
    },
    animalTypeFetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };
    },
  },
});

export const { animalTypefetch, animalTypeFetchError } =
  animalTypeSlice.actions;

export default animalTypeSlice.reducer;
