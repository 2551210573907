import React from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
  InputGroupText,
} from "reactstrap";

import PopoverDetail from "../../components/popover/popoverdetail.jsx";
import Footer from "../../components/footer/footer.jsx";
import { Field, Form, FormSpy } from "react-final-form"; //, formValueSelector, isDirty

//import FormStateToRedux from "./FormStateToRedux";
import {
  fetchCarry,
  updateCarry,
  addCarry,
  deleteCarry,
  changeAnimalType,
  changeAnimalSubType,
  changeCarryType,
  changeCarryDraft,
  clearCarry,
} from "../../actions/carryActions.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Banner from "../../components/banner/banner";
import Expire from "../../components/expire/expire";

import Loading from "../../components/spinners/loading";

import { fetchAllAnimalSubTypes } from "../../actions/animalTypeActions.js";

import {
  navControls,
  navSave,
  navDelete,
  navDuplicate,
  navDirty,
  navLoad,
} from "../../actions/navActions.js";

import { fetchAllInfos } from "../../actions/infoActions.js";

import { updateCarryCalc } from "../../actions/calcActions.js";

class CarryDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
      isDeletePending: false,
      LambToken: 14,
      SheepToken: 2,
      isDirty: false,
    };
  }

  componentDidUpdate() {
    if (this.state.isDirty == true) {
      this.setState({ isDirty: false });

      //console.log("Carry Calc Before");
      //console.log(this.props.calc.carry);

      this.props.updateCarryCalc(
        this.props.user.auth,
        this.props.useParams.Slug,
        0,
        this.props.carry
      );

      //console.log("Carry Calc After");
      // console.log(this.props.calc.carry);

      this.props.changeCarryDraft(this.props.carry);
      this.props.navDirty({ isDirty: true });
    } else {
      this.props.navDirty({ isDirty: false });
    }
    if (this.props.navIsPendingSave) {
      this.props.navSave({ isPendingSave: false });

      if (this.props.useParams.Id) {
        //---- this is the update of the carry
        //values = useFormState();
        //console.log("SAVE CARRY");
        //console.log(this.props.carry);
        this.props.updateCarry(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id,
          this.props.carry,
          this.props.calc.carry,
          this.props.trades
        );
      } else {
        this.props.addCarry(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.carry,
          this.props.calc.carry,
          this.props.useNavigate
        );
      }
      this.props.navDirty({ isDirty: false });
    }
    if (this.props.navIsPendingDuplicate) {
      var duplicate = { ...this.props.carry };
      duplicate.name = `${duplicate.name}-copy`;
      this.props.navDuplicate({ isPendingDuplicate: false });
      this.props.addCarry(
        this.props.user.auth,
        this.props.useParams.Slug,
        duplicate,
        this.props.calc.carry,
        this.props.useNavigate
      );
    }
    if (this.props.navIsPendingDelete) {
      this.props.navDelete({ isPendingDelete: false });
      this.props.deleteCarry(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.props.trades,
        this.props.useNavigate
      );
    }

    if (this.props.navIsPendingLoad == true) {
      if (this.props.location.pathname.endsWith("create")) {
        this.setState({
          isNew: true,
        });

        this.props.clearCarry(this.props.useParams.Slug);
      } else {
        this.setState({
          isNew: false,
        });

        this.props.fetchCarry(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id
        );
      }

      this.props.navLoad(false);
    }
  }

  getTitle = (id) => {
    if (this.props.infos?.find((x) => x.id === id) === undefined)
      return "No Title";

    return this.props.infos?.find((x) => x.id === id).title;
  };

  getDescription = (id) => {
    if (this.props.infos?.find((x) => x.id === id) === undefined)
      return "No Decription";

    return this.props.infos?.find((x) => x.id === id).description;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.fetchAllInfos(this.props.user.auth, this.props.useParams.Slug);

    this.props.navDirty({ isDirty: false });
    this.props.navControls({ isShowControls: true, isShowSave: true });
    if (this.props.useParams.Id === ":Id")
      this.props.useNavigate("/trades/info?Details=noTradeId");

    //--- Cattle as default
    this.props.fetchAllAnimalSubTypes(1);

    if (this.props.location.pathname.endsWith("create")) {
      this.setState({
        isNew: true,
      });
      this.props.clearCarry(this.props.useParams.Slug);
    } else {
      this.setState({
        isNew: false,
      });

      //console.log("Fetch Carry");
      this.props.fetchCarry(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id
      );
    }
  }

  toggleAnimalType(event) {
    var target = event.target.value;

    this.props.fetchAllAnimalSubTypes(Number(target));

    this.props.changeAnimalType(Number(target));

    if (Number(target) === 1) {
      this.props.changeAnimalSubType(1);
      this.props.carry.animalSubTypeId = 1;
    } else {
      this.props.changeAnimalSubType(12);
      this.props.carry.animalSubTypeId = 12;
    }

    this.props.navDirty({ isDirty: true });
  }

  toggleCarryType = (event) => {
    var target = event.target.value;

    this.props.changeCarryType(Number(target));

    this.props.navDirty({ isDirty: true });
  };

  toggleAnimalSubType(event) {
    var target = event.target.value;

    this.props.changeAnimalSubType(Number(target));
    this.props.carry.animalSubTypeId = Number(target);
    this.props.navDirty({ isDirty: true });
  }

  handleDataRecalc = () => {
    this.props.updateCarryCalc(
      this.props.user.auth,
      this.props.useParams.Slug,
      this.props.useParams.Id,
      this.props.carry
    );
  };

  onNameChange = (event) => {
    var target = event.target.value;
    this.props.changeCarryName(target);

    this.props.navDirty({ isDirty: true });
  };

  handleInfo = (name, e) => {
    this.props.useNavigate(`/trades/info/${name}`); // navigate to another route
  };

  doSave = (values) => {};

  onSubmit = async (values) => {};

  doSaveTopLevel = () => {};

  changeValues = (values) => {
    this.props.changeCarryDraft(values);

    this.setState({ isDirty: true });
  };

  render() {
    var banner;

    if (this.props.carry?.message !== "no message") {
      banner = (
        <Expire delay={5000}>
          {" "}
          <Banner message={this.props.carry?.message} />
        </Expire>
      );
    }

    const onSubmit = (values) => {
      console("Do it");
    };

    return (
      <div className=" d-flex justify-content-center paddingTop">
        {this.props.carry.isLoading && !this.state.isNew ? (
          <Loading />
        ) : (
          <Col md="12">
            {banner}

            <Form
              onSubmit={onSubmit}
              initialValues={this.props.carry}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Cost Of Carry&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="animalSubTypeIdLabel"
                        title={this.getTitle("animalSubTypeId")}
                        description={this.getDescription("animalSubTypeId")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Name</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="carryName"
                              name="name"
                              className="field"
                              component="input"
                              type="text"
                              placeholder="name"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Animal Type</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                            <Field
                              className="field"
                              component="select"
                              name="animalTypeId"
                              id="animalTypeId"
                              onChange={this.toggleAnimalType.bind(this)}
                            >
                              <option value="1">Cattle</option>
                              <option value="2">Sheep</option>
                            </Field>
                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Animal Sub Type</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                            <Field
                              component="select"
                              className="field"
                              name="animalSubTypeId"
                              id="animalSubTypeId"
                              onChange={this.toggleAnimalSubType.bind(this)}
                            >
                              {this.props.animalSubTypes?.map((prop, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={prop.animalSubTypeId}
                                  >
                                    {prop.animalSubType}
                                  </option>
                                );
                              })}
                            </Field>
                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Time Held / Growth
                      Rate&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="carryTypeId"
                        title={this.getTitle("carryTypeId")}
                        description={this.getDescription("carryTypeId")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>CofC Type</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="carryTypeId"
                              component="select"
                              name="carryTypeId"
                              className="field"
                              onChange={this.toggleCarryType}
                            >
                              <option value="1">Growth Rate</option>
                              <option value="2">Time Held</option>
                            </Field>
                          </InputGroup>
                        </Col>
                      </Row>

                      {this.props.carry?.carryTypeId === 1 ? (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Growth Rate</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-clock" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="weightGainKgVal"
                                name="weightGainKg"
                                className="fieldShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">kg/day</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      ) : (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Weeks Held</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-clock" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="weeksHeld"
                                name="weeksHeld"
                                className="fieldShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">weeks</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Losses</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-percent" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="losePercentage"
                              name="losePercentage"
                              className="fieldShort"
                              component="input"
                              inputmode="decimal"
                              placeholder="0"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">%</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Purchase Costs&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="freightIn"
                        title={this.getTitle("freightIn")}
                        description={this.getDescription("freightIn")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Freight In</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="freightInVal"
                              name="freightIn"
                              className="fieldShort"
                              component="input"
                              inputmode="decimal"
                              placeholder="0"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Buying Fee</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="buyingFeeVal"
                              name="buyingFee"
                              className="fieldShort"
                              component="input"
                              inputmode="decimal"
                              placeholder="0"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Processing Costs&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="drench"
                        title={this.getTitle("drench")}
                        description={this.getDescription("drench")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      {this.props.carry?.animalTypeId !==
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Drench</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="drenchVal"
                                name="drench"
                                className="fieldShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId ===
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Drench</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="drenchVal"
                                name="drench"
                                className="fieldReallyShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                              <Field
                                id="drenchDoseVal"
                                name="drenchDose"
                                className="fieldReallyShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">doses</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId !==
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Vaccine</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="vaccineVal"
                                name="vaccine"
                                className="fieldShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId ===
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Vaccine</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="vaccineVal"
                                name="vaccine"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                              <Field
                                id="vaccineDoseVal"
                                name="vaccineDose"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">doses</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId ===
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Dipping</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="dippingVal"
                                name="dipping"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                              <Field
                                id="dippingDoseVal"
                                name="dippingDose"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">doses</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId ===
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Jetting</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="jettingVal"
                                name="jetting"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                              <Field
                                id="jettingDoseVal"
                                name="jettingDose"
                                className="fieldReallyShort"
                                component="input"
                                inputmode="decimal"
                                placeholder="0"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">doses</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId !==
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Other Vet</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="vetVal"
                                name="vet"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.carry?.animalTypeId ===
                        this.state.SheepToken && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Other Vet</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="vetVal"
                                name="vet"
                                className="fieldReallyShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                              <Field
                                id="vetDoseVal"
                                name="vetDose"
                                className="fieldReallyShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">doses</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Tags</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="tagsVal"
                              name="tags"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Other</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="processingOtherVal"
                              name="processingOther"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {this.props.carry?.animalTypeId === this.state.SheepToken && (
                    <Card>
                      <CardBody className="bg-light">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/carry.png" /> Shearing
                          Costs&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="shearingPerHHead"
                            title={this.getTitle("shearingPerHHead")}
                            description={this.getDescription(
                              "shearingPerHHead"
                            )}
                          />
                        </CardTitle>
                      </CardBody>

                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Cost Per Head</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="shearingPerHHeadVal"
                                name="shearingPerHHead"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Sundry Shearing Exp</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="shearingSundryVal"
                                name="shearingSundry"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Maintenance Costs&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="agistmentRate"
                        title={this.getTitle("agistmentRate")}
                        description={this.getDescription("agistmentRate")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Agistment Rate</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="agistmentRateVal"
                              name="agistmentRate"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/week</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Supplements</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="supplementsVal"
                              name="supplements"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/week</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {this.props.carry?.animalTypeId === this.state.SheepToken && (
                    <Card>
                      <CardTitle className="bg-light p-3 mb-0">
                        <img alt="buy" src="/carry.png" />
                        <span>&nbsp;</span>Wool Selling Costs&nbsp;&nbsp;&nbsp;
                        <PopoverDetail
                          id="freightPerBale"
                          title={this.getTitle("freightPerBale")}
                          description={this.getDescription("freightPerBale")}
                        />
                        <br />
                      </CardTitle>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Freight per Bale</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="freightPerBaleVal"
                                name="freightPerBale"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/bale</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Wool Levy</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>%</InputGroupText>
                              </div>
                              <Field
                                id="woolLevyVal"
                                name="woolLevy"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">%</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Brokerage Fee</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>%</InputGroupText>
                              </div>
                              <Field
                                id="brokerageFeeVal"
                                name="brokerageFee"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">%</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Awex Trading Fee</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>%</InputGroupText>
                              </div>
                              <Field
                                id="awexTradingFeeVal"
                                name="awexTradingFee"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">%</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Other testing costs</Label>
                          </Col>
                          <Col md="8">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                id="othingTestingCostsVal"
                                name="othingTestingCosts"
                                className="fieldShort"
                                component="input"
                                placeholder="0"
                                inputmode="decimal"
                                type="number"
                              />
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/bale</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      </CardBody>{" "}
                    </Card>
                  )}

                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Selling Costs&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="yardLevy"
                        title={this.getTitle("yardLevy")}
                        description={this.getDescription("yardLevy")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Yard Due / Levy</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="yardLevyVal"
                              name="yardLevy"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Advertising</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas  fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="advertisingVal"
                              name="advertising"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Agent Commission</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-percent" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="agentCommissionVal"
                              name="agentCommission"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Freight Out</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="freightOutVal"
                              name="freightOut"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/carry.png" />
                      <span>&nbsp;</span>Interest Costs&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="annualInterestRate"
                        title={this.getTitle("annualInterestRate")}
                        description={this.getDescription("annualInterestRate")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Annual</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="annualInterestRateVal"
                              name="annualInterestRate"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>%</InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody className="bg-light">
                      <CardTitle className="mb-0 subTitle">
                        <img alt="buy" src="/carry.png" /> Time Spent With
                        Stock&nbsp;&nbsp;&nbsp;
                        <PopoverDetail
                          id="labourDays"
                          title={this.getTitle("labourDays")}
                          description={this.getDescription("labourDays")}
                        />
                      </CardTitle>
                    </CardBody>
                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Aircraft</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fa fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="aircraftVal"
                              name="aircraft"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Labour Days</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="labourDaysVal"
                              name="labourDays"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Labour Units</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="labourUnitsVal"
                              name="labourUnits"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Labour Rate</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="labourRateVal"
                              name="labourRate"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">/day</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Cost Of Labour</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="fas fa-dollar-sign" />
                              </InputGroupText>
                            </div>
                            <Field
                              id="costOfLabourVal"
                              name="costOfLabour"
                              className="fieldShort"
                              component="input"
                              placeholder="0"
                              inputmode="decimal"
                              type="number"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={(state) => {
                      const { values, valid } = state;
                      this.changeValues(values);
                    }}
                  />
                </form>
              )}
            />

            <Footer />
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.carry,
    carry: state.carry,
    calc: state.calc,
    user: state.user,
    navIsPendingDelete: state.nav.isPendingDelete,
    navIsPendingSave: state.nav.isPendingSave,
    navIsPendingDuplicate: state.nav.isPendingDuplicate,
    animalSubTypes: state.animalTypes.animalTypes,
    navIsShowControls: state.nav.isShowControls,
    navIsPendingLoad: state.nav.isPendingLoad,
    infos: state.infos.infos,
    trades: state.trades.trades,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCarry,
      updateCarry,
      addCarry,
      clearCarry,
      deleteCarry,
      navControls,
      navSave,
      navDelete,
      navDuplicate,
      updateCarryCalc,
      changeAnimalType,
      changeAnimalSubType,
      changeCarryType,
      navDirty,
      fetchAllAnimalSubTypes,
      fetchAllInfos,
      changeCarryDraft,
      navLoad,
    },
    dispatch
  );
};

//You have to connect() to any reducers that you wish to connect to yourself
CarryDetail = connect(mapStateToProps, mapActionsToProps)(CarryDetail);

export default CarryDetail;
