import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  description: "no desciption available",

  message: "no message",
  isLoading: false,
};

export const infoSlice = createSlice({
  name: "infoSlice",
  initialState,
  reducers: {
    InfoFetch: (state, action) => {
      action.payload.isLoading = false;
      state = action.payload;
      return state;
    },

    InfoFetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };
      return state;
    },

    InfoIsLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };
      return state;
    },
  },
});

export const { InfoFetch, InfoFetchError, InfoIsLoading } = infoSlice.actions;

export default infoSlice.reducer;

// import {INFO_FETCH, INFO_FETCH_ERROR, INFO_ISLOADING} from '../actions/infoActions.js';

// export type State = {
//     title: string,
//     description: string,

//     message: string,
//     isLoading: boolean

//   };

// const initialState: State = {
//     title: '',
//     description: 'no desciption available',

//     message: 'no message',
//     isLoading: false

//   };

// export default function infoReducer(state: State = initialState, {type, payload}): State {

//   switch(type)
//     {

//         case INFO_ISLOADING:

//             state = {...state, isLoading: payload };

//             break;

//         case INFO_FETCH:
//             payload.isLoading = false;
//             state = payload;
//             break;

//         case INFO_FETCH_ERROR:
//             state = {...state, message:payload, isLoading:false};
//             break;

//         default:
//             return state;
//     }

//     return state;
//   }
