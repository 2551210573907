import { batchActions } from "redux-batched-actions";
import { loadGroups } from "../actions/groupActions";
import { recalcTrade, recalcCarry, tradeCalc } from "../actions/calcActions";

import { loadCarrySByType } from "../actions/carryActions";
import { loadTypes } from "../actions/animalTypeActions";

import { FetchTrades, TradesAreLoading } from "../reducers/tradesReducer";

import axios from "axios";

import {
  FetchTrade,
  AddTrade,
  FetchError,
  TradeChangeAnimalSubType,
  TradeChangeAnimalType,
  TradeChangeBuyCost,
  TradeChangeBuyCostKg,
  TradeChangeBuyWeight,
  TradeChangeCarry,
  TradeChangeCarryType,
  TradeChangeDraft,
  TradeChangeGroup,
  TradeChangeName,
  TradeChangeNumber,
  TradeChangeSellCost,
  TradeChangeSellCosting,
  TradeChangeSellWeight,
  TradeChangeWoolPriceHead,
  TradeChangeWoolPriceKg,
  TradeChangeWoolWeight,
  TradeIsLoading,
  TradeIsSaving,
  ClearTrade,
  UpdateTradeName,
  UpdateTradeHead,
  UpdateTradeType,
  TradeMessage,
} from "../reducers/currentTradeReducer";

import { CarryFetch } from "../reducers/carryReducer";

import { LoadingUser } from "../reducers/userReducer";

async function addNewTrade(dispatch, auth, userId, newTrade, newCalc, nav) {
  try {
    dispatch(TradesAreLoading(true));

    dispatch(TradeIsSaving(true));

    var updatedTrade = { ...newTrade };

    updatedTrade.isSaleOverprice = newCalc.isSaleOverprice;

    updatedTrade.capInvestInStock = newCalc.capInvestInStock;
    updatedTrade.capReqForCarry = newCalc.capReqForCarry;
    updatedTrade.costOfCarryPerHead = newCalc.costOfCarryPerHead;
    updatedTrade.costOfCarryPerKg = newCalc.costOfCarryPerKg;
    updatedTrade.inventoryAccToCapitalAcc = newCalc.inventoryAccToCapitalAcc;
    updatedTrade.inventoryChange = newCalc.inventoryChange;
    updatedTrade.inventoryFromCapitalAcc = newCalc.inventoryFromCapitalAcc;
    updatedTrade.profitPerAnnum = newCalc.profitPerAnnum;
    updatedTrade.profitPerHeadPa = newCalc.profitPerHead;
    updatedTrade.profitReturnOnCapitalPa = newCalc.profitReturnOnCapitalPa;
    updatedTrade.tradeCalcDescription = newCalc.tradeCalcDescription;

    const headers = {
      Authorization: `Bearer ${auth}`,
    };

    //console.log("Trade Auth");
    //console.log(headers);
    //console.log(auth);

    var postURL = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${userId}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'

    var response = await axios.post(postURL, updatedTrade, { headers });

    dispatch(TradeIsSaving(false));

   nav(`/trades/${userId}/tradedetail/${response.data.id}`);

    dispatch(
      batchActions([
        dispatch(AddTrade(response)),
        loadTrade(dispatch, auth, userId, response.data.id),
        loadTrades(dispatch, auth, userId),
        loadGroups(dispatch, auth, userId),
        dispatch(TradeMessage("Trade Added Successfully")),
      ])
    );
  } catch (error) {
    console.error(error);
  }
}

async function deleteCurrentTrade(dispatch, auth, userId, id, nav) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    dispatch(TradesAreLoading(true));

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    await axios.delete(updateURL, { headers });

    nav(`/trades/${userId}/TradeList`);

    dispatch(batchActions([loadTrades(dispatch, auth, userId)]));
  } catch (error) {
    console.error(error);
  }
}

async function saveCurrentTrade(dispatch, auth, userId, id, newTrade, newCalc) {
  try {
    dispatch(TradeIsLoading(true));

    dispatch(TradeIsSaving(true));

    var updatedTrade = { ...newTrade };

    updatedTrade.isSaleOverprice = newCalc.isSaleOverprice;

    updatedTrade.capInvestInStock = newCalc.capInvestInStock;
    updatedTrade.capReqForCarry = newCalc.capReqForCarry;
    updatedTrade.costOfCarryPerHead = newCalc.costOfCarryPerHead;
    updatedTrade.costOfCarryPerKg = newCalc.costOfCarryPerKg;
    updatedTrade.inventoryAccToCapitalAcc = newCalc.inventoryAccToCapitalAcc;
    updatedTrade.inventoryChange = newCalc.inventoryChange;
    updatedTrade.inventoryFromCapitalAcc = newCalc.inventoryFromCapitalAcc;
    updatedTrade.profitPerAnnum = newCalc.profitPerAnnum;
    updatedTrade.profitPerHeadPa = newCalc.profitPerHead;
    updatedTrade.profitReturnOnCapitalPa = newCalc.profitReturnOnCapitalPa;
    updatedTrade.tradeCalcDescription = newCalc.tradeCalcDescription;

    const headers = {
      Authorization: `Bearer ${auth}`,
      "Accept-Encoding": "gzip, deflate, br",
      Accept: "application/json, text/plain, */*",
    };

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    //var updateURL = `ws.ydesign.com.au/api/Trades/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    await axios.put(updateURL, updatedTrade, { headers });

    console.log(updateURL);
    console.log(updatedTrade);

    dispatch(TradeIsSaving(false));

    dispatch(
      batchActions([
        loadTrades(dispatch, auth, userId),
        loadTrade(dispatch, auth, userId, id),
        loadGroups(dispatch, auth, userId),
      ])
    );
  } catch (error) {
    console.error(error);
    dispatch(TradeIsSaving(false));

    dispatch(
      batchActions([
        loadTrades(dispatch, auth, userId),
        loadTrade(dispatch, auth, userId, id),
        loadGroups(dispatch, auth, userId),
      ])
    );
  }
}

export async function updateAllTrades(
  dispatch,
  auth,
  userId,
  carryId,
  newCarry,
  trades
) {
  //console.log("Up date all trades");
  dispatch(TradeIsLoading(true));

  const headers = { Authorization: `Bearer ${auth}` };

  if (trades) {
    for (const item of trades) {
      if (Number(item.carryId) === Number(carryId)) {
        //---- Its effected
        let newCalc = tradeCalc(newCarry, item);

        var newTrade = { ...item };

        newTrade.isSaleOverprice = newCalc.isSaleOverprice;

        newTrade.capInvestInStock = newCalc.capInvestInStock;
        newTrade.capReqForCarry = newCalc.capReqForCarry;
        newTrade.costOfCarryPerHead = newCalc.costOfCarryPerHead;
        newTrade.costOfCarryPerKg = newCalc.costOfCarryPerKg;
        newTrade.inventoryAccToCapitalAcc = newCalc.inventoryAccToCapitalAcc;
        newTrade.inventoryChange = newCalc.inventoryChange;
        newTrade.inventoryFromCapitalAcc = newCalc.inventoryFromCapitalAcc;
        newTrade.profitPerAnnum = newCalc.profitPerAnnum;
        newTrade.profitPerHeadPa = newCalc.profitPerHead;
        newTrade.profitPerHead = newCalc.profitPerHead;
        newTrade.profitReturnOnCapitalPa = newCalc.profitReturnOnCapitalPa;
        newTrade.tradeCalcDescription = newCalc.tradeCalcDescription;

        var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${userId}/detail/${item.id}`;
        await axios.put(updateURL, newTrade, { headers });
      }
    }
  }

  batchActions([loadTrades(dispatch, auth, userId)]);
}

async function loadBlankTrade(dispatch, auth, slug, defaultGroupId) {
  //Get first carry of type 1

  let newTrade = {
    name: null,
    animalTypeId: 1,
    animalSubTypeId: 1,
    animalTypeName: null,
    numberOfHead: null,
    tradeGroupId: defaultGroupId,
    isSaleOverprice: false,
    saleWeightkg: null,
    saleCostCKg: null,
    saleCostHd: null,
    saleSkinWeight: null,
    sale2Weightkg: null,
    sale2CostCKg: null,
    sale2CostHd: null,
    purchaseWeightkg: null,
    purchaseCostCKg: null,
    purchaseCostHd: null,
    purchaseSkinWeight: null,
    carryId: null,
    carryTypeId: null,
    carryName: null,
    carryValue: null,

    purchaseCowDescription: null,
    saleCowDescription: null,

    capInvestInStock: null,
    capReqForCarry: null,
    costOfCarryPerHead: null,
    costOfCarryPerKg: null,
    profitPerHead: null,
    profitPerHeadPa: null,
    profitPerAnnum: null,
    profitReturnOnCapitalPa: null,
    inventoryChange: null,
    inventoryFromCapitalAcc: null,
    inventoryAccToCapitalAcc: null,

    woolPriceHd: null,
    woolPriceKg: null,
    woolSaleWeight: null,

    totalCapRequired: null,
    weeksHeld: null,
    weeksHeldTill: null,
    profitPerWeek: null,

    tradeCalcDescription: null,

    message: "no message",
    isLoading: false,
  };

  const headers = { Authorization: `Bearer ${auth}` };

  const carryResponse = await axios.get(
    `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${slug}/baseCarry`,
    { headers }
  );

  newTrade.carryId = carryResponse.data.id;
  newTrade.carryTypeId = carryResponse.data.carryTypeId;

  batchActions([
    dispatch(ClearTrade(newTrade)),
    recalcCarry(dispatch, auth, null, null, carryResponse.data), // carryResponse.data),
    recalcTrade(dispatch, auth, null, null, carryResponse.data, newTrade), //carryResponse.data, newTrade),
    loadTypes(dispatch, 1),
  ]);
}

export async function loadTrades(dispatch, auth, slug) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    dispatch(TradesAreLoading(true));

    var urlTrades = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${slug}`;

    const response = await axios.get(urlTrades, { headers });

    var trades = response.data;

    dispatch(FetchTrades(trades));

    dispatch(LoadingUser(false));
  } catch (error) {
    console.error(error);

    dispatch(FetchError("Cannot load trades"));
  }
}

async function loadTrade(dispatch, auth, slug, id) {
  try {
    dispatch(TradeIsLoading(true));

    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${slug}/detail/${id}`,
      { headers }
    );

    var trade = response.data;

    trade.message = "Fetched Successfully";

    const carryResponse = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/Carrys/${slug}/detail/${trade.carryId}`,
      { headers }
    );

    batchActions([
      dispatch(FetchTrade(trade)),
      dispatch(CarryFetch(carryResponse)),
      loadCarrySByType(
        dispatch,
        auth,
        slug,
        carryResponse.data.carryTypeId,
        id,
        carryResponse.data.id
      ),
      recalcCarry(dispatch, auth, slug, null, carryResponse.data),
      recalcTrade(dispatch, auth, slug, null, carryResponse.data, trade),
      loadTypes(dispatch, trade.animalTypeId),
    ]);

    dispatch(TradeIsLoading(false));
  } catch (error) {
    console.error("Load Error");
    console.log(error);

    dispatch(FetchError("Cannot load Trade"));
  }
}

export const fetchTrade = (auth, userid, id) => (dispatch) => {
  loadTrade(dispatch, auth, userid, id);
};

export const fetchTrades = (auth, slug) => (dispatch) => {
  loadTrades(dispatch, auth, slug);
};

export const clearTrade = (auth, slug, groupId) => (dispatch) => {
  loadBlankTrade(dispatch, auth, slug, groupId);
};

export const updateCurrentName = (newValue) => (dispatch) => {
  dispatch(UpdateTradeName(newValue));
};

export const updateCurrentType = (newValue) => (dispatch) => {
  dispatch(UpdateTradeType(newValue));
};

export const updateCurrentHead = (newValue) => (dispatch) => {
  dispatch(UpdateTradeHead(newValue));
};

export const saveTrade =
  (auth, userId, Id, newTrade, newCalc) => (dispatch) => {
    saveCurrentTrade(dispatch, auth, userId, Id, newTrade, newCalc);
  };

export const addTrade = (auth, userId, newTrade, newCalc, nav) => (dispatch) => {
  addNewTrade(dispatch, auth, userId, newTrade, newCalc, nav);
};

export const deleteTrade = (auth, userId, Id, nav) => (dispatch) => {
  deleteCurrentTrade(dispatch, auth, userId, Id, nav);
};

export const changeAnimalType = (newValue) => (dispatch) => {
  dispatch(TradeChangeAnimalType(newValue));
};

export const changeAnimalSubType = (newValue) => (dispatch) => {
  dispatch(TradeChangeAnimalSubType(newValue));
};

export const changeCarryType = (newValue) => (dispatch) => {
  dispatch(TradeChangeCarryType(newValue));
};

//---- Sell
export const changeSellWeight = (newValue) => (dispatch) => {
  dispatch(TradeChangeSellWeight(newValue));
};

export const changeSellCost = (newValue) => (dispatch) => {
  dispatch(TradeChangeSellCost(newValue));
};

export const changeSellCostKg = (newValue) => (dispatch) => {
  dispatch(TradeChangeSellCosting(newValue));
};

//---- Wool
export const changeWoolWeight = (newValue) => (dispatch) => {
  dispatch(TradeChangeWoolWeight(newValue));
};

export const changeWoolPriceKg = (newValue) => (dispatch) => {
  dispatch(TradeChangeWoolPriceKg(newValue));
};

export const changeWoolPriceHead = (newValue) => (dispatch) => {
  dispatch(TradeChangeWoolPriceHead(newValue));
};

//---- Buy
export const changeBuyWeight = (newValue) => (dispatch) => {
  dispatch(TradeChangeBuyWeight(newValue));
};

export const changeBuyCost = (newValue) => (dispatch) => {
  dispatch(TradeChangeBuyCost(newValue));
};

export const changeBuyCostKg = (newValue) => (dispatch) => {
  dispatch(TradeChangeBuyCostKg(newValue));
};

export const changeTradeName = (newValue) => (dispatch) => {
  dispatch(TradeChangeName(newValue));
};

export const changeTradeNumber = (newValue) => (dispatch) => {
  dispatch(TradeChangeNumber(newValue));
};

export const changeCarry = (newValue) => (dispatch) => {
  dispatch(TradeChangeCarry(newValue));
};

export const changeGroup = (newValue) => (dispatch) => {
  dispatch(TradeChangeGroup(newValue));
};

export const updateDraft = (newValue) => (dispatch) => {
  dispatch(TradeChangeDraft(newValue));
};
