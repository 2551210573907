import React from "react";
import { Form } from "react-final-form";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchInfo } from "../../actions/infoActions.js";

import { Card, CardBody, CardTitle, Row, Col, Label, Button } from "reactstrap";

import Footer from "../../components/footer/footer.jsx";

class PrivacyView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchInfo(this.props.useParams.Info);
  }

  back() {
    this.props.useNavigate(-1);
  }

  // outside your render() method
  // renderField = (field) => (
  //   <div className="input-row">
  //     <Label>{field.input.value}</Label>
  //     {/* <input {...field.input} type="text"/> */}
  //     {field.meta.touched && field.meta.error && (
  //       <span className="error">{field.meta.error}</span>
  //     )}
  //   </div>
  // );

  render() {
    var banner;

    return (
      <div className="minWidth">
        <div className="screenView">
          <Card>
            <CardTitle className="mb-0 bg-light p-3 ">
              <Row>
                <Col>
                  <h2>Privacy Policy</h2>
                </Col>
              </Row>
            </CardTitle>

            <CardBody>
              <Row>
                <Col>
                  KLR Marketing is dedicated to keeping your details private.
                  Any information, we collect in relation to you, is kept
                  strictly secured. We do not pass on/sell/swap any of your
                  personal details with anyone. We use this information to
                  identify your orders, provide you with our monthly newsletter
                  (if applicable) and to personalise your shopping experience
                  with us; that's all.
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  KLR Marketing uses cookies to allow you to login to your
                  account, maintain a shopping cart and to purchase items in
                  your shopping cart. Cookies sent to your computer from KLR
                  Marketing only last while you’re browsing our website. We do
                  not store persistent cookies on your computer. Cookies also
                  allow us to give you a more personalised shopping experience
                  by displaying products that interest you throughout our
                  product pages, thus providing you with a more friendly,
                  interesting and enjoyable shopping experience.
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  Whenever you use our web site, or any other web site, the
                  computer on which the web pages are stored (the Web server)
                  needs to know the network address of your computer so that it
                  can send the requested web pages to your Internet browser. The
                  unique network address of your computer is called its "IP
                  address," and is sent automatically each time you access any
                  Internet site. From a computer's IP address, it is possible to
                  determine the general geographic location of that computer,
                  but otherwise it is anonymous.
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  We do not keep a record of the IP addresses from which users
                  access our site except where you have specifically provided us
                  with information about yourself, in which case we also record
                  your IP address for security purposes. An example of this
                  would be when proceeding to a checkout to finalise an order
                  you may wish to make. After completing the form provided, your
                  IP address will be stored along with a transaction number that
                  allows us to track your order.
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.info,
    currentInfo: state.info,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInfo,
    },
    dispatch
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// PrivacyView = Form({ form: "PRIVACY_FORM", enableReinitialize: true })(
//   PrivacyView
// );

// You have to connect() to any reducers that you wish to connect to yourself
PrivacyView = connect(mapStateToProps, mapActionsToProps)(PrivacyView);

export default PrivacyView;
