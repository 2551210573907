import React from "react";
import { Route, Routes, redirect } from "react-router-dom";
import HeaderFn from "../components/header/headerFn.jsx";
import SidebarTreeFn from "../components/sidebar/sidebarTreeFn.jsx";

//import Customizer from '../components/customizer/customizer';
import ThemeRoutes from "../routes/routing";
import BasicInfoView from "../views/info/basicInfo.jsx";
import MainMenuView from "../views/info/mainMenu.jsx";

import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../assets/scss/style.css";

//import Auth from "../auth/auth";
//import Callback from "../callback/callback";
//import Home from "../views/home/home";
//import Login from "../views/home/Login";

// const handleAuthentication = (auth, { location }) => {
//   if (/access_token|id_token|error/.test(location.hash)) {
//     auth.handleAuthentication();
//   }
// };

class Fulllayout extends React.Component {
  /*--------------------------------------------------------------------------------*/
  /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);

    const auth = "null"; // new Auth();

    this.state = {
      auth: auth,
      isOpen: false,
      width: window.innerWidth,
      settings: [
        {
          theme: "light",
          layout: "vertical",
          dir: "ltr",
          sidebartype: "full",
          sidebarpos: "fixed",
          headerpos: "fixed",
          boxed: "full",
          navbarbg: "skin4",
          sidebarbg: "skin6",
          logobg: "skin4",
        },
      ],
    };

    // this.props.history.listen((location, action) => {
    //   if (
    //     window.innerWidth < 767 &&
    //     document
    //       .getElementById("main-wrapper")
    //       .className.indexOf("show-sidebar") !== -1
    //   ) {
    //     document
    //       .getElementById("main-wrapper")
    //       .classList.toggle("show-sidebar");
    //   }
    // });
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    // if (this.state.auth.isAuthenticated() === "true") {
    //   this.state.auth.renewSession();
    // }
    // // } else {
    // //   this.state.auth.login();
    // // }
    this.updateDimensions();
  }

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById("main-wrapper");
    this.setState({
      width: window.innerWidth,
    });

    if (this.state.width < 767) {
      element.classList.remove("show-sidebar");
      element.setAttribute("data-sidebartype", "overlay");
    } else {
      element.setAttribute("data-sidebartype", "full");
      element.classList.add("show-sidebar");
    }
  }

  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    //window.removeEventListener('resize', this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes default(LIGHT) THEME to DARK COLOR:-                   */
  /*--------------------------------------------------------------------------------*/
  darkTheme = (a) => {
    if (a.target.checked) {
      let darktheme = JSON.parse(JSON.stringify(this.state.settings));
      darktheme[0].theme = "dark";
      this.setState({ settings: darktheme });
    } else {
      let lighttheme = JSON.parse(JSON.stringify(this.state.settings));
      lighttheme[0].theme = "light";
      this.setState({ settings: lighttheme });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FULL) LAYOUT to BOXED LAYOUT                   */
  /*--------------------------------------------------------------------------------*/
  boxedTheme = (b) => {
    if (b.target.checked) {
      let boxtheme = JSON.parse(JSON.stringify(this.state.settings));
      boxtheme[0].boxed = "boxed";
      this.setState({ settings: boxtheme });
    } else {
      let fulltheme = JSON.parse(JSON.stringify(this.state.settings));
      fulltheme[0].boxed = "full";
      this.setState({ settings: fulltheme });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(ltr) DIRECTION to rtl DIRECTION                   */
  /*--------------------------------------------------------------------------------*/
  rtl = (h) => {
    if (h.target.checked) {
      let rtl = JSON.parse(JSON.stringify(this.state.settings));
      rtl[0].dir = "rtl";
      this.setState({ settings: rtl });
    } else {
      let ltr = JSON.parse(JSON.stringify(this.state.settings));
      ltr[0].dir = "ltr";
      this.setState({ settings: ltr });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of HEADER to ABSOLUTE POSITION */
  /*--------------------------------------------------------------------------------*/
  headerPosition = (c) => {
    if (c.target.checked) {
      let fixedpos = JSON.parse(JSON.stringify(this.state.settings));
      fixedpos[0].headerpos = "fixed";
      this.setState({ settings: fixedpos });
    } else {
      let absolutepos = JSON.parse(JSON.stringify(this.state.settings));
      absolutepos[0].headerpos = "absolute";
      this.setState({ settings: absolutepos });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*Theme Setting && Changes Default(FIXED) POSITION of SIDEBAR to ABSOLUTE POSITION*/
  /*--------------------------------------------------------------------------------*/
  sidebarPosition = (d) => {
    if (d.target.checked) {
      let sidebarfixedpos = JSON.parse(JSON.stringify(this.state.settings));
      sidebarfixedpos[0].sidebarpos = "fixed";
      this.setState({ settings: sidebarfixedpos });
    } else {
      let sidebarabsolutepos = JSON.parse(JSON.stringify(this.state.settings));
      sidebarabsolutepos[0].sidebarpos = "absolute";
      this.setState({ settings: sidebarabsolutepos });
    }
  };
  // /*--------------------------------------------------------------------------------*/
  // /*Theme Setting && Changes NAVBAR BACKGROUND-COLOR from given options             */
  // /*--------------------------------------------------------------------------------*/
  navbarbgChange = (e) => {
    let navskin = e.currentTarget.dataset.navbarbg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].navbarbg = navskin;
    this.setState({
      settings: newsettings,
    });
  };
  // /*--------------------------------------------------------------------------------*/
  // /*Theme Setting && Changes SIDEBAR-MENU BACKGROUND-COLOR from given options       */
  // /*--------------------------------------------------------------------------------*/
  sidebarbgChange = (f) => {
    let sidebarskin = f.currentTarget.dataset.sidebarbg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].sidebarbg = sidebarskin;
    this.setState({
      settings: newsettings,
    });
  };
  // /*--------------------------------------------------------------------------------*/
  // /*Theme Setting && Changes LOGO BACKGROUND-COLOR from given options               */
  // /*--------------------------------------------------------------------------------*/
  logobgChange = (g) => {
    let logoskin = g.currentTarget.dataset.logobg;
    let newsettings = JSON.parse(JSON.stringify(this.state.settings));
    newsettings[0].logobg = logoskin;
    this.setState({
      settings: newsettings,
    });
  };

  render() {
    const renderMergedProps = (component, ...rest) => {
      const finalProps = Object.assign({}, ...rest);
      return React.createElement(component, finalProps);
    };

    const PropsRoute = ({ component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(routeProps) => {
            return renderMergedProps(component, routeProps, rest);
          }}
        />
      );
    };

    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <div
        id="main-wrapper"
        dir={this.state.settings[0].dir}
        data-theme={this.state.settings[0].theme}
        data-layout={this.state.settings[0].layout}
        data-sidebartype={this.state.settings[0].sidebartype}
        data-sidebar-position={this.state.settings[0].sidebarpos}
        data-header-position={this.state.settings[0].headerpos}
        data-boxed-layout={this.state.settings[0].boxed}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/* Header                                                                         */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {true || this.state.auth.isAuthenticated() ? (
          <Route
            path="/"
            render={(props) => (
              <Header
                auth={this.state.auth}
                login={this.state.auth.userProfile}
                {...props}
                data={this.state}
              />
            )}
          />
        ) : (
          <div />
        )} */}

        <HeaderFn />

        {/* <Header
          //auth={this.state.auth}
          //login={this.state.auth.userProfile}
          //{...props}
          data={this.state}
        /> */}

        {/*--------------------------------------------------------------------------------*/}
        {/* Sidebar                                                                        */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {true || this.state.auth.isAuthenticated() ? (
          <SidebarTree data={this.state} {...this.props} routes={ThemeRoutes} />
        ) : (
          <div />
        )} */}

        <SidebarTreeFn data={this.state} {...this.props} routes={ThemeRoutes} />

        {/*--------------------------------------------------------------------------------*/}
        {/* Page Main-Content                                                              */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="page-wrapper d-block">
          <div className="page-content container-fluid">
            {/* {this.state.routes} */}
            {/* <Router history={history}> */}

            {/* <div className="navBlackBg">{this.state.width}</div> */}
            <Routes>
              {/* <Route
                path="/user/main"
                id="main"
                key="main"
                element={<MainMenuView />}
              />
              <Route
                path="/"
                id="landing"
                key="landing"
                element={<BasicInfoView />}
              /> */}

              {/* <Switch> */}
              {/* <Routes>
              <Route
                path="/"
                render={(props) =>
                  false && !this.state.auth.isAuthenticated() ? (
                    <redirect to="/login" />
                  ) : (
                    <redirect to="/home" />
                    // <Home auth={this.state.auth} {...props} />
                  )
                }
              /> */}

              {/* CRAIG TODO */}
              {/* <Route
                  path="/callback"
                  render={(props) => {
                    handleAuthentication(this.state.auth, props);
                    return <Callback {...props} />;
                  }}
                /> */}

              {/* CRAIG TODO */}
              {/* <Route
                  path="/login"
                  render={(props) => <Login auth={this.state.auth} {...props} />}
                /> */}

              {ThemeRoutes.map((prop, key) => {
                if (prop.navlabel) {
                  return null;
                } else if (prop.collapse) {
                  return prop.child.map((prop2, key2) => {
                    if (prop2.collapse) {
                      return prop2.subchild.map((prop3, key3) => {
                        // return !this.state.auth.isAuthenticated() ? (
                        //   <redirect to="/login" />
                        // ) : (
                        return (
                          <Route
                            path={prop3.path}
                            element={prop3.component}
                            //auth={this.state.auth}
                            key={key3}
                          />
                        );
                        // );
                      });
                    }
                    // return false && !this.state.auth.isAuthenticated() ? (
                    //   <redirect to="/login" />
                    // ) : (
                    return (
                      <Route
                        path={prop2.path}
                        element={prop2.component}
                        //auth={this.state.auth}
                        key={key2}
                      />
                    );

                    //   );
                  });
                  // } else if (prop.redirect) {
                  //   return (
                  //     <redirect from={prop.path} to={prop.pathTo} key={key} />
                  //   );
                } else {
                  // return false && !this.state.auth.isAuthenticated() ? (
                  //   <redirect to="/login" />
                  // ) : (
                  return (
                    <Route
                      path={prop.path}
                      element={prop.component}
                      //auth={this.state.auth}
                      key={key}
                    />
                  );
                  //   );
                }
              })}
            </Routes>
          </div>
        </div>
      </div>
    );
  }
}
export default Fulllayout;
