import { useNavigate, useParams, useLocation } from "react-router-dom";
import TradeGroupDetail from "./tradeGroupDetail";

const TradeGroupDetailFn = (props) => {
  const nav = useNavigate();

  const location = useLocation();
  const params = useParams();

  return (
    <TradeGroupDetail
      useNavigate={nav}
      useParams={params}
      location={location}
      {...props}
    />
  );
};

export default TradeGroupDetailFn;
