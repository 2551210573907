import { useNavigate, useParams } from "react-router-dom";
import PrivacyView from "./privacypolicy";

const PrivacyViewFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <PrivacyView useNavigate={nav} useParams={params} {...props} />;
};

export default PrivacyViewFn;
