import { batchActions } from "redux-batched-actions";

import { CarryUpdateCalc, TradeUpdateCalc } from "../reducers/calcReducer";

export const getValue = (val) => {
  if (val === null || val === undefined) return 0;

  return val;
};

export const tradeCalc = (newCarry, newTrade) => {
  var labourCost = 0;
  var numberAfterLosses = 0;
  //var numberAfterLossesForProfit = 0;
  var lostAnimals = 0;
  var lossCost = 0;
  var capInvestInStock = 0;
  var costOfCarry = 0;
  var carryInterest = 0;
  var capReqForCarry = 0;
  var costOfCarryPerHead = 0;
  var costOfCarryPerKg = 0;
  var profitPerHead = 0;
  var totalProfit = 0;
  var profitPerAnnum = 0;
  var returnOnCapital = 0;
  var profitReturnOnCapitalPa = 0;
  var inventoryChange = 0;
  var inventoryFromCapitalAcc = 0;
  var inventoryAccToCapitalAcc = 0;
  var shornTotalCost = 0;
  var notShornTotalCost = 0;
  var shornTotalCostPerHead = 0;
  var notShornTotalCostPerHead = 0;
  var isSaleOverprice = false;
  let profitPerHeadPa = 0;

  let notShornReturnOnCapital = 0;
  let shornReturnOnCapital = 0;

  let shornTotalProfit = 0;
  let notShornTotalProfit = 0;

  let shornProfitPerHead = 0;
  let notShornProfitPerHead = 0;

  let shornPerHeadPA = 0;
  let notShornPerHeadPA = 0;
  let shornPerAnnumProfit = 0;
  let notShornPerAnnumProfit = 0;
  let shornReturnOnCapitalPA = 0;
  let notShornReturnOnCapitalPA = 0;

  let reduceSellingCostsBasedOnLosses = 0;

  let tradeCalcDescription = "";

  let totalCapRequired = 0;
  let weeksHeldTill = "";
  let profitPerWeek = 0;

  tradeCalcDescription += "Trade Calc \n\n";

  //---- Not using losses in the calc of profit per head but I will need it to show overall profit
  numberAfterLosses = newTrade.numberOfHead;
  //numberAfterLossesForProfit = newTrade.numberOfHead;

  if (newCarry && newTrade) {
    var weeksHeld = 1;

    if (newCarry.carryTypeId === 2) {
      tradeCalcDescription += "Carry Type = Weeks Held \n\n";
      weeksHeld = Number(newCarry.weeksHeld);
    }

    if (Number(newCarry.labourRate) && Number(newCarry.labourDays))
      labourCost = Number(newCarry.labourRate) * Number(newCarry.labourDays);

    if (
      newTrade.numberOfHead &&
      newTrade.saleCostHd &&
      newTrade.purchaseCostHd
    ) {
      numberAfterLosses = //numberAfterLossesForProfit
        Number(newTrade.numberOfHead) -
        Math.ceil(
          Number(newTrade.numberOfHead) *
            (Number(newCarry.losePercentage) / 100)
        );

      lostAnimals = newTrade.numberOfHead - numberAfterLosses; //numberAfterLossesForProfit

      tradeCalcDescription += "lostAnimals = " + lostAnimals + "\n\n";

      tradeCalcDescription +=
        "numberOfHead = " + newTrade.numberOfHead + "\n\n";
      tradeCalcDescription +=
        "losePercentage = " + newCarry.losePercentage + "\n\n";
      tradeCalcDescription +=
        "numberAfterLosses = numberOfHead - numberOfHead * (losePercentage / 100) \n";
      tradeCalcDescription +=
        "\t\t = " +
        newTrade.numberOfHead +
        " - " +
        newTrade.numberOfHead +
        " * (" +
        newCarry.losePercentage +
        " / 100) \n";

      tradeCalcDescription += "\t\t  = " + numberAfterLosses + "\n";

      if (newCarry.carryTypeId === 1) {
        tradeCalcDescription += "Carry Type = Growth \n";
        weeksHeld = Number(
          (Number(newTrade.saleWeightkg) - Number(newTrade.purchaseWeightkg)) /
            Number(newCarry.weightGainKg) /
            7
        );

        tradeCalcDescription +=
          "weeksHeld = (saleWeightkg - purchaseWeightkg) / weightGainKg / 7 \n";
        tradeCalcDescription +=
          "\t\t  = (" +
          newTrade.saleWeightkg +
          " - " +
          newTrade.purchaseWeightkg +
          ") / " +
          newCarry.weightGainKg +
          "/ 7 \n";
      }

      tradeCalcDescription +=
        "\n Weeks Held = " + weeksHeld.toFixed(2) + "\n\n";

      //----- capInvestInStock
      capInvestInStock =
        Number(newTrade.numberOfHead) *
        (Number(newTrade.purchaseCostHd) + Number(newTrade.purchaseSkinWeight));

      tradeCalcDescription +=
        "capInvestInStock = numberOfHead * purchaseCostHd + purchaseSkinWeight \n";
      tradeCalcDescription +=
        "\t\t  = " +
        newTrade.numberOfHead +
        " * " +
        newTrade.purchaseCostHd +
        " + " +
        newTrade.purchaseSkinWeight +
        " \n";
      tradeCalcDescription += "\t\t  = " + capInvestInStock + "\n\n";

      //---- interestOnCapital
      let interestOnCapital =
        (((capInvestInStock * Number(newCarry.annualInterestRate)) / 52) *
          weeksHeld) /
        100;

      tradeCalcDescription +=
        "interestOnCapital = (((capInvestInStock * annualInterestRate) / 52) * weeksHeld) / 100 \n";
      tradeCalcDescription +=
        "\t\t  = (((" +
        capInvestInStock +
        " * " +
        newCarry.annualInterestRate +
        ") / 52) * " +
        weeksHeld +
        ") / 100 \n";
      tradeCalcDescription += "\t\t  = " + interestOnCapital + "\n\n";

      //---- maintenance costs
      let maintenanceCosts =
        Number(newCarry.agistmentRate) *
          weeksHeld *
          Number(newTrade.numberOfHead) +
        Number(newCarry.supplements) *
          weeksHeld *
          Number(newTrade.numberOfHead);

      tradeCalcDescription +=
        "maintenanceCosts = (agistmentRate * weeksheld * numberofHead) +  (supplements * weeksHeld * numberofHead) \n";

      tradeCalcDescription +=
        "\t\t  = (" +
        newCarry.agistmentRate +
        " * " +
        weeksHeld +
        " * " +
        newTrade.numberOfHead +
        ") +  (" +
        newCarry.supplements +
        " * " +
        weeksHeld +
        " * " +
        newTrade.numberOfHead +
        ")\n";

      tradeCalcDescription += "\t\t  = " + maintenanceCosts + "\n\n";

      //---- Shearing Costs
      let shearingCosts =
        Number(newCarry.shearingPerHHead) * Number(numberAfterLosses) +
        Number(newCarry.shearingSundry) * Number(numberAfterLosses);

      tradeCalcDescription +=
        "shearingCosts = (shearingPerHHead * numberAfterLosses) + (shearingSundry * numberAfterLosses)\n";

      tradeCalcDescription +=
        "\t\t  = (" +
        newCarry.shearingPerHHead +
        " * " +
        numberAfterLosses +
        ") + (" +
        newCarry.shearingSundry +
        " * " +
        numberAfterLosses +
        ")\n";

      tradeCalcDescription += "\t\t  = " + shearingCosts + "\n\n";

      //---- Agents Commmisions
      let agentsCommission =
        Number(numberAfterLosses) *
        (Number(newCarry.agentCommission) / 100) *
        (Number(newTrade.saleCostHd) +
          Number(newTrade.sale2CostHd) +
          Number(newTrade.saleSkinWeight));

      tradeCalcDescription +=
        "agentsCommission = numberAfterLosses * (agentCommission / 100 * (saleCostHd + sale2CostHd + saleSkinWeight))\n";

      tradeCalcDescription +=
        "\t\t  = " +
        numberAfterLosses +
        " * ( " +
        newCarry.agentCommission +
        " / 100 * (" +
        newTrade.saleCostHd +
        " + " +
        newTrade.sale2CostHd +
        " + " +
        newTrade.saleSkinWeight +
        "))\n";

      tradeCalcDescription += "\t\t  = " + agentsCommission + "\n\n";

      //---- Wool Price
      let woolPrice = Number(numberAfterLosses) * Number(newTrade.woolPriceHd);

      tradeCalcDescription += "woolPrice = numberAfterLosses * woolPriceHd\n";

      tradeCalcDescription +=
        "\t\t  = " + numberAfterLosses + " * " + newTrade.woolPriceHd + "\n";

      tradeCalcDescription += "\t\t  = " + woolPrice + "\n\n";

      //--- woolSellingCosts
      let woolSellingCosts =
        ((Number(numberAfterLosses) * Number(newTrade.woolSaleWeight)) / 180) *
          Number(newCarry.freightPerBale) +
        (woolPrice * Number(newCarry.woolLevy)) / 100 +
        (woolPrice * Number(newCarry.brokerageFee)) / 100 +
        (woolPrice * Number(newCarry.awexTradingFee)) / 100 +
        Number(newCarry.othingTestingCosts);

      tradeCalcDescription +=
        "woolSellingCosts =  ((numberAfterLosses * newTrade.woolSaleWeight) / 180) * newCarry.freightPerBale +  (woolPrice * newCarry.woolLevy) / 100 +  (woolPrice * newCarry.brokerageFee) / 100 +  (woolPrice * newCarry.awexTradingFee) / 100 + newCarry.othingTestingCosts\n";

      tradeCalcDescription +=
        "\t\t  =  ((" +
        numberAfterLosses +
        " * " +
        newTrade.woolSaleWeight +
        ") / 180) * " +
        newCarry.freightPerBale +
        " +  (" +
        woolPrice +
        " * " +
        newCarry.woolLevy +
        ") / 100 +  (" +
        woolPrice +
        " * " +
        newCarry.brokerageFee +
        ") / 100 +  (" +
        woolPrice +
        " * " +
        newCarry.awexTradingFee +
        ") / 100 + " +
        newCarry.othingTestingCosts +
        "\n";

      tradeCalcDescription += "\t\t  =  " + woolSellingCosts + "\n\n";

      //---- costOfCarry
      costOfCarry =
        Number(newTrade.numberOfHead) * Number(newCarry.carryPerHd) +
        Number(agentsCommission) +
        Number(labourCost) +
        Number(maintenanceCosts) +
        Number(interestOnCapital) -
        Number(reduceSellingCostsBasedOnLosses);

      if (woolSellingCosts) {
        costOfCarry += Number(woolSellingCosts);
      }

      if (shearingCosts) {
        costOfCarry += Number(shearingCosts);
      }

      //---- Loss Cost
      if (lostAnimals > 0 && newTrade.purchaseCostHd) {
        tradeCalcDescription += "carry Pre Loss = " + costOfCarry + "\n";

        lossCost = Number(lostAnimals * newTrade.purchaseCostHd);
        costOfCarry += lossCost; //Adding this back in see Grahame 20230718 0; //We dont use losses against carry - see Grahame - 20230504 lossCost;

        tradeCalcDescription += "lossCost = lostAnimals * purchaseCostHd;\n";

        tradeCalcDescription +=
          "\t\t  = " + lostAnimals + " *  " + newTrade.purchaseCostHd + "\n";

        tradeCalcDescription += "\t\t  = " + lossCost + "\n\n";

        reduceSellingCostsBasedOnLosses =
          lostAnimals *
          (newCarry.advertising + newCarry.yardLevy + newCarry.freightOut);

        costOfCarry -= reduceSellingCostsBasedOnLosses;

        tradeCalcDescription +=
          "reduceSellingCostsBasedOnLosses = lostAnimals * (advertising + yardLevy + freightOut);\n";

        tradeCalcDescription +=
          "\t\t  = (" +
          lostAnimals +
          " *  (" +
          newCarry.advertising +
          " + " +
          newCarry.yardLevy +
          " + " +
          newCarry.freightOut +
          ")\n";

        tradeCalcDescription +=
          "\t\t  = " + reduceSellingCostsBasedOnLosses + "\n\n";
      }

      tradeCalcDescription +=
        "costOfCarry = (newTrade.numberOfHead * newCarry.carryPerHd) + agentsCommission + labourCost + maintenanceCosts + interestOnCapital + woolSellingCosts + shearingCosts + lossCost - reduceSellingCostsBasedOnLosses;\n";

      tradeCalcDescription +=
        "\t\t  = (" +
        newTrade.numberOfHead +
        " * " +
        newCarry.carryPerHd +
        ") + " +
        agentsCommission +
        " + " +
        labourCost +
        " + " +
        maintenanceCosts +
        " + " +
        interestOnCapital +
        " + " +
        woolSellingCosts +
        " + " +
        shearingCosts +
        " + " +
        lossCost +
        " - " +
        reduceSellingCostsBasedOnLosses +
        "\n";

      tradeCalcDescription += "\t\t  =  " + costOfCarry + "\n\n";

      let interestOnCapitalShorn = 0;
      let interestOnCapitalNotShorn = 0;

      let shornCost = 0;
      let notShornCost = 0;

      if (newTrade.animalSubTypeId === 12) {
        shornCost =
          Number(newTrade.numberOfHead) * Number(newCarry.carryPerHd) +
          agentsCommission +
          labourCost +
          maintenanceCosts +
          woolSellingCosts +
          shearingCosts;

        notShornCost =
          Number(newTrade.numberOfHead * newCarry.carryPerHd) +
          Number(labourCost) +
          Number(maintenanceCosts) +
          Number(newTrade.saleCostHd);

        interestOnCapitalShorn = shornCost * 0.0075 * (weeksHeld / 52);
        interestOnCapitalNotShorn = notShornCost * 0.0075 * (weeksHeld / 52);

        shornTotalCost = shornCost + interestOnCapitalShorn;
        notShornTotalCost = notShornCost + interestOnCapitalNotShorn;

        shornTotalCostPerHead = shornTotalCost / Number(newTrade.numberOfHead);
        notShornTotalCostPerHead =
          notShornTotalCost / Number(newTrade.numberOfHead);

        shornProfitPerHead =
          Number(newTrade.saleCostHd) -
          Number(newTrade.purchaseCostHd) +
          Number(newTrade.woolPriceHd) -
          Number(shornTotalCostPerHead);

        notShornProfitPerHead =
          Number(newTrade.saleCostHd) -
          Number(newTrade.purchaseCostHd) -
          notShornTotalCostPerHead;

        shornTotalProfit = Number(newTrade.numberOfHead) * shornProfitPerHead;
        notShornTotalProfit =
          Number(newTrade.numberOfHead) * notShornProfitPerHead;

        notShornReturnOnCapital =
          (notShornProfitPerHead /
            (Number(newTrade.purchaseCostHd) + notShornTotalCostPerHead / 2)) *
          100;

        shornReturnOnCapital =
          (shornProfitPerHead /
            (Number(newTrade.purchaseCostHd) + shornTotalCostPerHead / 2)) *
          100;

        //shornROC;

        shornPerHeadPA = (shornProfitPerHead / weeksHeld) * 52;
        notShornPerHeadPA = (notShornProfitPerHead / weeksHeld) * 52;
        shornPerAnnumProfit = (shornTotalProfit / weeksHeld) * 52;
        notShornPerAnnumProfit = (notShornTotalProfit / weeksHeld) * 52;
        shornReturnOnCapitalPA = (shornReturnOnCapital / weeksHeld) * 52;
        notShornReturnOnCapitalPA = (notShornReturnOnCapital / weeksHeld) * 52;
      }

      //---- CarryInterest
      carryInterest =
        ((costOfCarry * newCarry.annualInterestRate * 0.0075) / 52) * weeksHeld;

      tradeCalcDescription +=
        "carryInterest = ((costOfCarry * newCarry.annualInterestRate * 0.0075) / 52) * weeksHeld\n";

      tradeCalcDescription +=
        " \t = ((" +
        costOfCarry +
        " * " +
        newCarry.annualInterestRate +
        " * 0.0075) / 52) * " +
        weeksHeld +
        "\n";

      tradeCalcDescription += "\t\t  = " + carryInterest + "\n\n";

      //---- Capital req for Carry
      capReqForCarry = costOfCarry + carryInterest;

      tradeCalcDescription += "capReqForCarry = costOfCarry + carryInterest\n";

      tradeCalcDescription +=
        "\t\t  = " + costOfCarry + " + " + carryInterest + "\n";

      tradeCalcDescription += "\t\t  = " + capReqForCarry + "\n\n";

      //---- Cost Of Carry per head

      costOfCarryPerHead = capReqForCarry / Number(newTrade.numberOfHead);

      tradeCalcDescription +=
        "costOfCarryPerHead = capReqForCarry / Number(newTrade.numberOfHead)\n";

      tradeCalcDescription +=
        "\t\t  = " + capReqForCarry + " / " + newTrade.numberOfHead + ")\n";

      tradeCalcDescription += "\t\t  = " + costOfCarryPerHead + "\n\n";

      //---- costOfCarryPerKg
      costOfCarryPerKg =
        costOfCarryPerHead /
        (Number(newTrade.saleWeightkg) - Number(newTrade.purchaseWeightkg));

      tradeCalcDescription +=
        "costOfCarryPerKg = costOfCarryPerHead / (newTrade.saleWeightkg - newTrade.purchaseWeightkg)\n";

      tradeCalcDescription +=
        "\t\t  = " +
        costOfCarryPerHead +
        " / (" +
        newTrade.saleWeightkg +
        " - " +
        newTrade.purchaseWeightkg +
        ")\n";

      tradeCalcDescription += "\t\t  = " + costOfCarryPerKg + "\n\n";

      //---- profitPerHead
      profitPerHead =
        Number(newTrade.saleCostHd) +
        Number(newTrade.sale2CostHd) -
        Number(newTrade.purchaseCostHd) -
        costOfCarryPerHead; // -

      if (newTrade.purchaseSkinWeight)
        profitPerHead += Number(newTrade.purchaseSkinWeight);
      if (newTrade.saleSkinWeight)
        profitPerHead += Number(newTrade.saleSkinWeight);
      if (newTrade.woolPriceHd) profitPerHead += Number(newTrade.woolPriceHd);

      tradeCalcDescription +=
        "profitPerHead = newTrade.saleCostHd +  newTrade.sale2CostHd = - newTrade.purchaseCostHd - costOfCarryPerHead + newTrade.purchaseSkinWeight + newTrade.saleSkinWeight + newTrade.woolPriceHd\n";

      tradeCalcDescription +=
        "\t\t  = " +
        newTrade.saleCostHd +
        " + " +
        newTrade.sale2CostHd +
        " - " +
        newTrade.purchaseCostHd +
        " - " +
        costOfCarryPerHead +
        " + " +
        newTrade.saleSkinWeight +
        " + " +
        newTrade.woolPriceHd +
        "\n";

      tradeCalcDescription += "\t\t  = " + profitPerHead + "\n\n";

      //---- Total Profit
      totalProfit = profitPerHead * numberAfterLosses; //Number(newTrade.numberOfHead); //numberAfterLossesForProfit

      tradeCalcDescription += "totalProfit = profitPerHead * numberSold\n"; // newTrade.numberOfHead\n";
      tradeCalcDescription +=
        "\t\t  = " + profitPerHead + " * " + numberAfterLosses + "\n"; //numberAfterLossesForProfit
      tradeCalcDescription += "\t\t  = " + totalProfit + "\n\n";

      //---- Profit Per amum
      profitPerAnnum = (totalProfit / weeksHeld) * 52;

      tradeCalcDescription +=
        "profitPerAnnum = (totalProfit / weeksHeld) * 52\n";

      tradeCalcDescription +=
        "\t\t  = (" + totalProfit + " / " + weeksHeld + ") * 52\n";

      tradeCalcDescription += "\t\t  = " + profitPerAnnum + "\n\n";

      //---- ReturnonCapital
      returnOnCapital =
        (profitPerHead /
          (Number(newTrade.purchaseCostHd) +
            Number(newTrade.purchaseSkinWeight) +
            costOfCarryPerHead / 2)) *
        100;

      tradeCalcDescription +=
        "returnOnCapital = (profitPerHead / (newTrade.purchaseCostHd +newTrade.purchaseSkinWeight + costOfCarryPerHead / 2)) * 100\n";

      tradeCalcDescription +=
        "\t\t  = (" +
        profitPerHead +
        " / (" +
        newTrade.purchaseCostHd +
        " + " +
        newTrade.purchaseSkinWeight +
        " + " +
        costOfCarryPerHead +
        " / 2)) * 100\n";

      tradeCalcDescription += "\t\t  = " + returnOnCapital + "\n\n";

      profitReturnOnCapitalPa = returnOnCapital * (52 / weeksHeld);

      tradeCalcDescription +=
        " profitReturnOnCapitalPa = returnOnCapital * (52 / weeksHeld)\n";
      tradeCalcDescription +=
        "\t\t  = " + returnOnCapital + " * (52 / " + weeksHeld + ")\n";
      tradeCalcDescription += "\t\t  = " + profitReturnOnCapitalPa + "\n\n";

      //---- Should be on what we sold
      profitPerHeadPa = profitPerAnnum / numberAfterLosses; //  Number(newTrade.numberOfHead); //numberAfterLossesForProfit

      tradeCalcDescription +=
        "profitPerHeadPa = profitPerAnnum / number sold \n"; //newTrade.numberOfHead\n";
      tradeCalcDescription +=
        "\t\t  = " + profitPerAnnum + " / " + numberAfterLosses + "\n"; //numberAfterLossesForProfit

      tradeCalcDescription += "\t\t  = " + profitPerHeadPa + "\n\n";

      isSaleOverprice = profitPerHead > 0;

      tradeCalcDescription += "isSaleOverprice = profitPerHead > 0\n";
      tradeCalcDescription += "\t\t  = " + profitPerHead + " > 0\n";
      tradeCalcDescription += "\t\t  = " + isSaleOverprice + "\n\n";
    }
  }

  totalCapRequired = capInvestInStock + capReqForCarry;
  profitPerWeek = totalProfit / weeksHeld;
  weeksHeldTill = "June 2020";

  var result = {
    labourCost,
    numberAfterLosses,
    capInvestInStock,
    costOfCarry,
    carryInterest,
    capReqForCarry,
    costOfCarryPerHead,
    costOfCarryPerKg,
    profitPerHead,
    totalProfit,
    profitPerAnnum,
    returnOnCapital,
    profitReturnOnCapitalPa,
    profitPerHeadPa,
    inventoryChange,
    inventoryFromCapitalAcc,
    inventoryAccToCapitalAcc,
    isSaleOverprice,
    shornTotalCost,
    notShornTotalCost,
    shornTotalCostPerHead,
    notShornTotalCostPerHead,

    notShornReturnOnCapital,
    shornReturnOnCapital,

    shornTotalProfit,
    notShornTotalProfit,

    shornProfitPerHead,
    notShornProfitPerHead,

    shornPerHeadPA,
    notShornPerHeadPA,
    shornPerAnnumProfit,
    notShornPerAnnumProfit,
    shornReturnOnCapitalPA,
    notShornReturnOnCapitalPA,
    weeksHeld,
    tradeCalcDescription,
    weeksHeldTill,
    totalCapRequired,
    profitPerWeek,
  };

  return result;
};

export async function recalcTrade(
  dispatch,
  auth,
  userId,
  id,
  newCarry,
  newTrade
) {
  try {
    //console.log("reclac");
    //console.log(newCarry);
    //console.log(newTrade);
    let result = tradeCalc(newCarry, newTrade);

    batchActions([dispatch(TradeUpdateCalc(result))]);
  } catch (error) {
    console.error(error);
  }
}

export async function recalcCarry(dispatch, auth, userId, id, newCarry) {
  try {
    var result = 0;

    if (newCarry == null) return result;

    // all coc is based on 1 animal and 1 week no losses

    if (newCarry.freightIn) result += Number(newCarry.freightIn);
    if (newCarry.buyingFee) result += Number(newCarry.buyingFee);

    if (newCarry.drenchDose && newCarry.drench)
      result += Number(newCarry.drench) * Number(newCarry.drenchDose);
    else result += Number(newCarry.drench);

    if (newCarry.vetDose && newCarry.vet)
      result += Number(newCarry.vet) * Number(newCarry.vetDose);
    else result += Number(newCarry.vet);

    if (newCarry.vaccine && newCarry.vaccineDose)
      result += Number(newCarry.vaccine) * Number(newCarry.vaccineDose);
    else result += Number(newCarry.vaccine);

    if (newCarry.dipping && newCarry.dippingDose)
      result += Number(newCarry.dipping) * Number(newCarry.dippingDose);
    else result += Number(newCarry.dipping);

    if (newCarry.jetting && newCarry.jettingDose)
      result += Number(newCarry.jetting) * Number(newCarry.jettingDose);
    else result += Number(newCarry.jetting);

    if (newCarry.tags) result += Number(newCarry.tags);
    if (newCarry.processingOther) result += Number(newCarry.processingOther);

    //---- this includes sale price and losses which are not part of carry
    if (newCarry.yardLevy) result += Number(newCarry.yardLevy);
    if (newCarry.advertising) result += Number(newCarry.advertising);
    if (newCarry.freightOut) result += Number(newCarry.freightOut);

    //console.log("Carry Calc");
    //console.log(result);

    batchActions([dispatch(CarryUpdateCalc(result))]);
  } catch (error) {
    console.error(error);
  }
}

export const updateCarryCalc = (auth, userId, id, newCarry) => (dispatch) => {
  recalcCarry(dispatch, auth, userId, id, newCarry);
};

export const updateTradeCalc =
  (auth, userId, id, newCarry, newTrade) => (dispatch) => {
    recalcTrade(dispatch, auth, userId, id, newCarry, newTrade);
  };
