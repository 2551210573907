import { useNavigate, useParams, useLocation } from "react-router-dom";
import TradeDetail from "./tradeDetail";
import { useAuth0 } from "@auth0/auth0-react";

const TradeDetailFn = (props) => {
  const nav = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();

  return (
    <TradeDetail
      useNavigate={nav}
      useParams={params}
      authUser={user}
      location={location}
      isAuthenticated={isAuthenticated}
      {...props}
    />
  );
};

export default TradeDetailFn;
