import { useNavigate, useParams } from "react-router-dom";
import SidebarTree from "./sidebarTree";

import { useAuth0 } from "@auth0/auth0-react";

const SidebarTreeFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  const logout = useAuth0();

  return (
    <SidebarTree
      useNavigate={nav}
      useParams={params}
      logout={logout}
      {...props}
    />
  );
};

export default SidebarTreeFn;
