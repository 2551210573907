import React, { Component } from "react";
import { Card, CardTitle, Row, Col, Button } from "reactstrap";

import { navControls } from "../../actions/navActions.js";

import { Form } from "react-final-form";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Footer from "../../components/footer/footer.jsx";

import axios from "axios";

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
      SheepToken: 2,
      SplitterTokens: [6, 7, 8, 9, 10, 11],
      SheepShornToken: [12],
      isLoaded: false,
    };
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    this.props.navControls({ isShowControls: false });
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-center paddingTop screenView nearTop whiteBack">
          <Card className="mainTitle">
            <CardTitle className="mb-0   p-3">
              <Row>
                <Col>
                  <div className="centerImage">
                    <a href="/user/main">
                      <img
                        src="/mainmenuicon.png"
                        alt="KLR App"
                        width="190px"
                      ></img>
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <a href="/user/main">
                    <img
                      src="/mainmenuitemsMainMenu.png"
                      alt="Main Menu"
                      width="300px"
                    ></img>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <a href="https://klrmarketing.com/apptraining/">
                    <img
                      src="/mainmenuitemsTraining.png"
                      alt="Main Menu"
                      width="300px"
                    ></img>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <a href="https://www.klrmastermind.com/account/login">
                    <img
                      src="/mainmenuitemsMastermind.png"
                      alt="Main Menu"
                      width="300px"
                    ></img>
                  </a>
                </Col>
              </Row>

              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
            </CardTitle>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,

    isLoadedTrades: state.trades.isLoading,

    isLoadedCarry: state.carrys.isLoading,

    isLoadedGroups: state.groups.isLoading,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      navControls,
    },
    dispatch
  );
};

//You have to connect() to any reducers that you wish to connect to yourself
Home = connect(mapStateToProps, mapActionsToProps)(Home);

//(Form({ form: "HOME", enableReinitialize: true })(Home))

export default Home;
