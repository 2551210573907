import { useNavigate, useParams } from "react-router-dom";
import TradeList from "./tradeList";

const TradeListFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <TradeList useNavigate={nav} useParams={params} {...props} />;
};

export default TradeListFn;
