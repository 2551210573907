import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  infos: [],
  message: "no message",
  isLoading: false,
};

export const infosSlice = createSlice({
  name: "infosSlice",
  initialState,
  reducers: {
    InfosFetchAll: (state, action) => {
      state = action.payload;

      return state;
    },
  },
});

export const { InfosFetchAll } = infosSlice.actions;

export default infosSlice.reducer;

// import {INFOS_FETCH_ALL} from '../actions/infoActions.js';

// export type State = {
//     infos: Array,

//     message: string,
//     isLoading: boolean

//   };

// const initialState: State = {
//     infos: [],

//     message: 'no message',
//     isLoading: false

//   };

// export default function infosReducer(state: State = initialState, {type, payload}): State {

//   switch(type)
//     {

//         case INFOS_FETCH_ALL:
//             state = payload;
//             break;

//         default:
//             return state;
//     }

//     return state;
//   }
