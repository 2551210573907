import React from "react";

class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShow: true,
    };
  }

  close() {
    this.setState({
      isShow: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isShow && (
          <div className="banner">
            {this.props.message}

            <div className="alignRight">
              <span className="textLarger" onClick={this.close.bind(this)}>
                X &nbsp; &nbsp;
              </span>
              {/* <i className="fas fa-chevron-down mr-1 ml-1" onClick={this.close.bind(this)} ></i>   */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Banner;
