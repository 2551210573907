import React from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
  InputGroupText,
} from "reactstrap";

import PopoverDetail from "../../components/popover/popoverdetail.jsx";
import Footer from "../../components/footer/footer.jsx";
import { Field, Form, FormSpy } from "react-final-form";
import { addUser, changeUserDraft } from "../../actions/userActions.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Banner from "../../components/banner/banner";
import Expire from "../../components/expire/expire";

import Loading from "../../components/spinners/loading";

import {
  navControls,
  navSave,
  navDelete,
  navDuplicate,
  navDirty,
} from "../../actions/navActions.js";

import { fetchAllInfos } from "../../actions/infoActions.js";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
    };
  }

  componentDidUpdate() {
    if (this.props.navIsPendingSave) {
      this.props.navSave({ isPendingSave: false });

      this.props.addUser(this.props.user.auth, this.props.user);
    }

    this.props.navDirty({ isDirty: false });
  }

  getTitle = (id) => {
    if (this.props.infos.find((x) => x.id === id) === undefined)
      return "No Title";

    return this.props.infos.find((x) => x.id === id).title;
  };

  getDescription = (id) => {
    if (this.props.infos.find((x) => x.id === id) === undefined)
      return "No Decription";

    return this.props.infos.find((x) => x.id === id).description;
  };

  componentDidMount() {
    if (this.props.user.role !== "Admin") {
      this.props.useNavigate(-1);
    }

    window.scrollTo(0, 0);

    this.props.fetchAllInfos(this.props.user.auth, this.props.useParams.Slug);

    this.props.navDirty({ isDirty: false });
    this.props.navControls({ isShowControls: false, isShowSave: true });

    this.setState({
      isNew: true,
    });
  }

  onSubit = (event) => {};

  onNameChange = (event) => {
    var target = event.target.value;
    this.props.changeTradeName(target);
  };

  toggleRoleType(event) {
    var target = event.target.value;
  }

  handleInfo = (name, e) => {
    this.props.history.push(`/trades/info/${name}`); // navigate to another route
  };

  onSubmit = async (values) => {
    //window.alert(JSON.stringify(values, 0, 2));
  };

  changeValues = (values) => {
    this.props.changeUserDraft(values);

    this.setState({ isDirty: true });
  };

  render() {
    var banner;

    if (this.props.user.message !== "no message") {
      banner = (
        <Expire delay={5000}>
          {" "}
          <Banner message={this.props.user.message} />
        </Expire>
      );
    }

    return (
      <div className=" d-flex justify-content-center paddingTop ">
        {this.props.user.isLoading && !this.state.isNew ? (
          <Loading />
        ) : (
          <Col md="12">
            {banner}

            <Form
              onSubmit={this.onSubmit}
              // initialValues={}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardTitle className="bg-light p-3 mb-0">
                      <img alt="buy" src="/leafIcon.png" width="30px" />
                      <span>&nbsp;</span>Add User&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="animalSubTypeIdLabel"
                        title={this.getTitle("animalSubTypeId")}
                        description={this.getDescription("animalSubTypeId")}
                      />
                      <br />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>User name</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="user name"
                              onChange={this.onNameChange.bind(this)}
                              name="username"
                            />

                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Company</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="company"
                              onChange={this.onNameChange.bind(this)}
                              name="company"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>First Name</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="first name"
                              onChange={this.onNameChange.bind(this)}
                              name="firstname"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Last Name</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="last name"
                              onChange={this.onNameChange.bind(this)}
                              name="lastName"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Email</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="Email"
                              onChange={this.onNameChange.bind(this)}
                              name="email"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Auth0 Security Id</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="security id"
                              onChange={this.onNameChange.bind(this)}
                              name="providerAccountId"
                            />
                            <div className="input-group-append">
                              <InputGroupText>
                                <i className="far fa-user" />
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Role</Label>
                        </Col>
                        <Col md="8">
                          <InputGroup>
                            <Field
                              className="field"
                              component="select"
                              name="role"
                              onChange={this.toggleRoleType.bind(this)}
                            >
                              <option value="1">Normal</option>
                              <option value="2">Restricted</option>
                              <option value="2">Admin</option>
                            </Field>
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={(state) => {
                      const { values, valid } = state;
                      this.changeValues(values);
                    }}
                  />
                </form>
              )}
            />

            <Footer />
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //const selector = formValueSelector("USER_DETAIL");

  return {
    navIsPendingDelete: state.nav.isPendingDelete,
    navIsPendingLoad: state.nav.isPendingLoad,
    navIsPendingSave: state.nav.isPendingSave,
    navIsPendingDuplicate: state.nav.isPendingDuplicate,
    navIsShowControls: state.nav.isShowControls,
    infos: state.infos.infos,
    user: state.user,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addUser,
      navControls,
      navSave,
      navDelete,
      navDuplicate,
      navDirty,
      fetchAllInfos,
      changeUserDraft,
    },
    dispatch
  );
};

// You have to connect() to any reducers that you wish to connect to yourself
UserDetail = connect(mapStateToProps, mapActionsToProps)(UserDetail);

export default UserDetail;
