import React from "react";
import { fetchAllInfos } from "../../actions/infoActions.js";
import { Field, Form, FormSpy } from "react-final-form"; //formValueSelector formValueSelector, isDirty
import PopoverDetail from "../../components/popover/popoverdetail.jsx";

//import klrTextBox from '../../components/text/klrTextBox.jsx';

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import {
  updateCurrentType,
  saveTrade,
  fetchTrade,
  addTrade,
  clearTrade,
  deleteTrade,
  changeAnimalType,
  changeAnimalSubType,
  changeCarryType,
  changeSellWeight,
  changeSellCost,
  changeSellCostKg,
  changeWoolWeight,
  changeWoolPriceKg,
  changeWoolPriceHead,
  changeBuyWeight,
  changeBuyCost,
  changeBuyCostKg,
  changeTradeName,
  changeTradeNumber,
  changeCarry,
  changeGroup,
  updateDraft,
} from "../../actions/currentTradeActions.js";

import { fetchGroups, addGroup } from "../../actions/groupActions.js";

import { fetchCarry, fetchCarrysByType } from "../../actions/carryActions.js";
import Footer from "../../components/footer/footer.jsx";
import {
  navControls,
  navSave,
  navDelete,
  navDuplicate,
  navDirty,
  navSummary,
  navLoad,
} from "../../actions/navActions.js";

import { fetchAllAnimalSubTypes } from "../../actions/animalTypeActions.js";

// import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loading from "../../components/spinners/loading";

import { updateTradeCalc, updateCarryCalc } from "../../actions/calcActions.js";

class TradeDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
      SheepToken: 2,
      SplitterTokens: [7, 8, 9, 10, 11, 18, 19],
      SheepShornToken: [12],
      PerHeadOnlyToken: [12, 2, 4, 15, 16],
      isLoaded: false,
      isDirty: false,
    };
  }

  componentDidUpdate() {
    //console.log("Did update");

    //console.log(this.state.isDirty);
    if (this.state.isDirty == true) {
      //console.log("dirty");
      this.setState({ isDirty: false });
      this.props.navDirty({ isDirty: true });

      // console.log(this.props.currentTrade);

      this.props.updateTradeCalc(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.props.carry,
        this.props.currentTrade
      );
      //this.props.updateDraft(this.props.currentTrade);
    } else {
      //this.props.navDirty({ isDirty: false });
    }
    if (this.props.navIsPendingSave == true) {
      this.props.navSave({ isPendingSave: false });

      if (this.props.useParams.Id) {
        //console.log("Save Trade");

        this.props.saveTrade(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id,
          this.props.currentTrade,
          this.props.calc?.trade
        );
      } else {
        //console.log("Add Trade");

        var saveTrade = { ...this.props.currentTrade };

        if (saveTrade.numberOfHead === null) {
          saveTrade.numberOfHead = 0;
        }
        this.props.addTrade(
          this.props.user.auth,
          this.props.useParams.Slug,
          saveTrade,
          this.props.calc?.trade,
          this.props.useNavigate
        );
      }
      this.props.navDirty({ isDirty: false });
    }
    if (this.props.navIsPendingDuplicate == true) {
      var duplicate = { ...this.props.currentTrade };
      duplicate.name = `${duplicate.name}-copy`;
      this.props.updateTradeCalc(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.props.carry,
        this.props.currentTrade
      );
      this.props.navDuplicate({ isPendingDuplicate: false });
      this.props.addTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        duplicate,
        this.props.calc?.trade,
        this.props.useNavigate
      );
    }
    if (this.props.navIsPendingDelete) {
      this.props.navDelete({ isPendingDelete: false });
      this.props.deleteTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id,
        this.props.useNavigate
      );
      this.props.useNavigate(`/trades/${this.props.useParams.Slug}/tradeList`);
    }

    if (this.props.navIsPendingLoad == true) {
      if (this.props.location.pathname.endsWith("create")) {
        this.setState({ isNew: true });

        var defaultGroupId = null;

        if (this.props.groups.length > 0) {
          defaultGroupId = this.props.groups[0].id;
        }
        this.props.clearTrade(
          this.props.user.auth,
          this.props.useParams.Slug,
          defaultGroupId
        );

        this.props.fetchAllAnimalSubTypes(1); //--- Cattle as default

        //---- Load tyoe
        this.props.fetchCarrysByType(
          this.props.user.auth,
          this.props.useParams.Slug,
          1,
          null
        );
      } else {
        this.props.fetchTrade(
          this.props.user.auth,
          this.props.useParams.Slug,
          this.props.useParams.Id
        );
      }

      this.props.navLoad(false);
    }
  }

  getTitle = (id) => {
    if (this.props.infos?.find((x) => x.id === id) === undefined)
      return "No Title";

    return this.props.infos?.find((x) => x.id === id).title;
  };

  getDescription = (id) => {
    if (this.props.infos?.find((x) => x.id === id) === undefined)
      return "No Decription";

    return this.props.infos?.find((x) => x.id === id).description;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.isLoaded === false)
      this.props.fetchAllInfos(this.props.user.auth, this.props.useParams.Slug);

    this.props.navDirty({ isDirty: false });

    this.props.navControls({ isShowControls: true });

    if (this.props.useParams.Id === ":Id")
      this.props.useNavigate("/trades/info?Details=noTradeId");

    if (this.props.location.pathname.endsWith("create")) {
      this.setState({ isNew: true });

      var defaultGroupId = null;

      if (this.props.groups.length > 0) {
        defaultGroupId = this.props.groups[0].id;
      }
      this.props.clearTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        defaultGroupId
      );

      this.props.fetchAllAnimalSubTypes(1); //--- Cattle as default

      //---- Load tyoe
      this.props.fetchCarrysByType(
        this.props.user.auth,
        this.props.useParams.Slug,
        1,
        null
      );
    } else {
      this.props.fetchTrade(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.props.useParams.Id
      );
    }

    this.setState({ isLoaded: true });
  }

  currencyFormat = (num) => {
    if (typeof num !== typeof 1.1) return "$0.00";

    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  handleInfo = (name, e) => {
    this.props.useNavigate(`/trades/info/${name}`); // navigate to another route
  };

  clickCarry = (name, e) => {
    this.props.useNavigate(
      `/carry/${this.props.useParams.Slug}/CarryDetail/${this.props.currentTrade?.carryId}`
    ); // navigate to another route
  };

  toggleAnimalType(event) {
    var target = event.target.value;

    this.props.fetchAllAnimalSubTypes(Number(target));

    this.props.changeAnimalType(Number(target));

    if (Number(target) === 1) {
      this.props.changeAnimalSubType(1);
    } else {
      this.props.changeAnimalSubType(12);
    }

    this.props.navDirty({ isDirty: true });
  }

  toggleAnimalSubType(event) {
    var target = event.target.value;

    this.props.changeAnimalSubType(Number(target));
  }

  toggleCarryType(event) {
    var target = event.target.value;

    this.props.changeCarryType(Number(target));

    this.props.fetchCarrysByType(
      this.props.user.auth,
      this.props.useParams.Slug,
      Number(target),
      this.props.currentTrade?.id
    );

    this.props.navDirty({ isDirty: true });
  }

  //----- Sell
  onSellWeightChange(event) {
    var target = event.target.value;

    this.props.changeSellWeight(Number(target));

    //console.log("Sell weight change");
  }

  onSellCostChange(event) {
    var target = event.target.value;

    // // var val = parseFloat(target.toString());

    // var myString = target.toString();

    // var part = myString.slice(-1);

    // var result = myString;

    // if (part == ".") {
    //   result = myString.substring(0, myString.length - 1) + "0";
    // }

    //this.props.changeSellCost(Number(target));
  }

  onSellCostKgChange(event) {
    var target = event.target.value;

    //this.props.changeSellCostKg(Number(target));
  }

  //---- Wool
  onWoolWeightChange(event) {
    var target = event.target.value;

    this.props.changeWoolWeight(Number(target));
  }

  onWoolPriceKgChange(event) {
    var target = event.target.value;

    //this.props.changeWoolPriceKg(Number(target));
  }

  onWoolPriceHeadChange(event) {
    var target = event.target.value;

    //this.props.changeWoolPriceHead(Number(target));
  }

  //---- Buy
  onBuyWeightChange(event) {
    var target = event.target.value;

    //this.props.changeBuyWeight(Number(target));
  }

  onBuyCostChange(event) {
    var target = event.target.value;

    //this.props.changeBuyCost(Number(target));
  }

  onBuyCostKgChange(event) {
    var target = event.target.value;

    this.props.changeTradeName(this.props.currentTrade.name);

    //this.props.changeBuyCostKg(Number(target));
  }

  onNumberOfHeadChanged = (event) => {
    var target = event.target.value;
    this.props.changeTradeNumber(target);
  };

  onNameChange = (event) => {
    var target = event.target.value;
    this.props.changeTradeName(target);
  };

  onCarryChange = (event) => {
    this.props.changeCarry(event.target.value);

    this.props.fetchCarry(
      this.props.user.auth,
      this.props.useParams.Slug,
      event.target.value,
      this.props.currentTrade
    );
  };

  onChangeGroupName = (event) => {
    this.props.changeGroup(event.target.value);
  };

  doSave = () => {};

  changeValues = (values) => {
    console.log(values);
    this.props.updateDraft(values);

    this.setState({ isDirty: true });

    //console.log("Change Values");
    //console.log(this.state.isDirty);
  };

  formatNumber = (value) => {
    if (!value) return value;

    let val = value.toString();

    let indexOfDot = val.indexOf(".");

    if (indexOfDot > 0) {
      let len = val.length;

      if (len > indexOfDot + 3) {
        return val.substring(0, indexOfDot + 3);
      } else {
        return val;
      }
    } else {
      return val;
    }
  };

  render() {
    const onSubmit = (values) => {
      console("Do it");
    };

    return (
      <div className=" d-flex justify-content-center paddingTop">
        {this.props.currentTrade?.isLoading && !this.state.isNew ? (
          <Loading />
        ) : (
          <Col md="12">
            <Form
              onSubmit={onSubmit}
              initialValues={this.props.currentTrade}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardTitle className="mb-0 bg-light p-3 cardTitle">
                      <img alt="buy" src="/sellLogo.png" /> Trade
                      Calculator&nbsp;&nbsp;&nbsp;
                      <PopoverDetail
                        id="carryId"
                        title={this.getTitle("carryId")}
                        description={this.getDescription("carryId")}
                      />
                    </CardTitle>

                    <CardBody>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Name</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              className="field"
                              component="input"
                              type="text"
                              placeholder="name"
                              //onChange={this.onNameChange.bind(this)}
                              name="name"
                            />
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Animal Type</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              className="field"
                              component="select"
                              name="animalTypeId"
                              default="1"
                              onChange={this.toggleAnimalType.bind(this)}
                            >
                              <option value="1">Cattle</option>
                              <option value="2">Sheep</option>
                            </Field>
                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Animal Sub Type</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              component="select"
                              className="field"
                              name="animalSubTypeId"
                              default="1"
                              onChange={this.toggleAnimalSubType.bind(this)}
                            >
                              {this.props.animalSubTypes.map((prop, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={prop.animalSubTypeId}
                                  >
                                    {prop.animalSubType}
                                  </option>
                                );
                              })}
                            </Field>
                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Number of Head</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <div className="input-group-append">
                              <InputGroupText>X</InputGroupText>
                            </div>
                            <Field
                              component="input"
                              className="fieldReallyShort"
                              placeholder="0"
                              name="numberOfHead"
                              //onChange={this.onNumberOfHeadChanged.bind(this)}
                              type="number"
                              inputmode="decimal"
                            />

                            <div className="input-group-append">
                              <InputGroupText>
                                <div className="unitType">hd</div>
                              </InputGroupText>
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label> Group</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              className="field"
                              component="select"
                              name="tradeGroupId"
                              //onChange={this.onChangeGroupName.bind(this)}
                            >
                              {this.props.groups.map((prop, key) => {
                                return (
                                  <option key={key} value={prop.id}>
                                    {prop.name}
                                  </option>
                                );
                              })}
                            </Field>
                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Weight Gain/Time</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              className="field"
                              component="select"
                              name="carryTypeId"
                              default="1"
                              onChange={this.toggleCarryType.bind(this)}
                            >
                              <option value="1">Growth Rate</option>
                              <option value="2">Time Held</option>
                            </Field>

                            <Label className="buttonUp"></Label>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Cost Of Carry</Label>
                        </Col>
                        <Col md="5">
                          <InputGroup>
                            <Field
                              className="field"
                              component="select"
                              name="carryId"
                              onChange={this.onCarryChange.bind(this)}
                            >
                              {this.props.currentTrade.carryTypeId === 1
                                ? this.props.weightCarrys.map((prop, key) => {
                                    if (prop.weightGainKg) {
                                      return (
                                        <option key={key} value={prop.id}>
                                          ({prop.weightGainKg}kg) {prop.name}
                                        </option>
                                      );
                                    }
                                  })
                                : this.props.weeksHeldCarrys.map(
                                    (prop, key) => {
                                      if (prop.weeksHeld) {
                                        return (
                                          <option key={key} value={prop.id}>
                                            ({prop.weeksHeld}wks) {prop.name}
                                          </option>
                                        );
                                      }
                                    }
                                  )}
                            </Field>
                            {/* <Button
                              className="buttonUp"
                              onClick={this.clickCarry.bind(this)}
                            > */}
                            &nbsp;
                            <InputGroupText>
                              <i
                                className="fas fa-arrow-right cursorHand"
                                onClick={this.clickCarry.bind(this)}
                              />
                            </InputGroupText>
                            {/* </Button> */}
                          </InputGroup>
                        </Col>

                        <Col md="1"></Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Weeks Held</Label>
                        </Col>

                        <Col md="4">
                          {this.props.calc?.trade?.weeksHeld &&
                            this.props.calc?.trade?.weeksHeld.toFixed(2)}
                          {!this.props.calc?.trade?.weeksHeld && 0}&nbsp; Weeks
                          &nbsp;&nbsp; (
                          {this.props.calc?.trade?.weeksHeld &&
                            (this.props.calc?.trade?.weeksHeld / 4).toFixed(2)}
                          &nbsp;
                          {!this.props.calc?.trade?.weeksHeld && 0} Months)
                        </Col>
                      </Row>

                      <Row className="rowHeight">
                        <Col md="3">
                          <Label>Profit Per Head</Label>
                        </Col>
                        <Col md="5">
                          ${" "}
                          {this.props.calc?.trade?.profitPerHead &&
                            this.props.calc?.trade?.profitPerHead
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          {!this.props.calc?.trade?.profitPerHead && 0.0}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody className="bg-light">
                      <CardTitle className="mb-0 subTitle">
                        <img alt="buy" src="/sellLogo.png" />
                        <Label className="sellTitle">
                          Sell&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="saleWeightkg"
                            title={this.getTitle("saleWeightkg")}
                            description={this.getDescription("saleWeightkg")}
                          />
                        </Label>
                        {this.props.calc?.trade?.isSaleOverprice && (
                          <Label className="Overpriced">Overpriced</Label>
                        )}
                        {!this.props.calc?.trade?.isSaleOverprice && (
                          <Label className="Underpriced">Underpriced</Label>
                        )}
                      </CardTitle>
                    </CardBody>
                    <CardBody>
                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) &&
                        !this.state.PerHeadOnlyToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">Sale Live Weight</Col>
                            <Col md="5">
                              <InputGroup>
                                <Field
                                  name="saleWeightkg"
                                  className="fieldMed"
                                  component="input"
                                  placeholder="Weight"
                                  onInput={this.onSellWeightChange.bind(this)}
                                  type="text"
                                  inputmode="decimal"
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">kg</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}

                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) &&
                        !this.state.PerHeadOnlyToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">
                              <InputGroup>
                                <Label> $/kg </Label>
                              </InputGroup>
                            </Col>
                            <Col md="5">
                              <InputGroup>
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <i>$</i>
                                  </InputGroupText>
                                </div>
                                <Field
                                  name="saleCostCKg"
                                  placeholder="0"
                                  className="fieldShort"
                                  format={this.formatNumber}
                                  component="input"
                                  type="text"
                                  inputmode="decimal"
                                  onInput={this.onSellCostKgChange.bind(this)}
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">/kg</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}

                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <InputGroup>
                              <div className="halfWidth alignRight"> </div>

                              <Label>$/Head</Label>
                            </InputGroup>
                          </Col>

                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="saleCostHd"
                                placeholder="0.00"
                                className="fieldShort"
                                format={this.formatNumber}
                                component="input"
                                type="text"
                                inputmode="decimal"
                                onInput={this.onSellCostChange.bind(this)}
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            {this.props.currentTrade?.animalTypeId ===
                            this.state.SheepToken
                              ? "Sale Sheep"
                              : "Sale Cow"}
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <Field
                                name="saleCowDescription"
                                className="field"
                                component="input"
                                placeholder={
                                  this.props.currentTrade?.animalTypeId ===
                                  this.state.SheepToken
                                    ? "Sale Sheep Description"
                                    : "Sale Cow Description"
                                }
                                type="text"
                              />{" "}
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>
                              {this.props.currentTrade?.animalTypeId ===
                              this.state.SheepToken
                                ? "Sheep"
                                : "Cow"}
                            </Label>
                          </Col>
                          <Col md="2">
                            <InputGroup>
                              <Label> $/hd </Label>
                            </InputGroup>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="saleCostHd"
                                placeholder="0.00"
                                component="input"
                                format={this.formatNumber}
                                className="fieldShort"
                                type="text"
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>
                              {this.props.currentTrade?.animalTypeId ===
                              this.state.SheepToken
                                ? "Lamb"
                                : "Calf"}
                            </Label>
                          </Col>
                          <Col md="2">
                            <InputGroup>
                              <Label> $/hd </Label>
                            </InputGroup>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="sale2CostHd"
                                placeholder="0.00"
                                component="input"
                                className="fieldShort"
                                type="text"
                                inputmode="decimal"
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.currentTrade?.animalTypeId ===
                        this.state.SheepToken &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">
                              <Label>Skin</Label>
                            </Col>

                            <Col md="5">
                              <InputGroup>
                                <div className="halfWidth"> </div>
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <i className="fas fa-dollar-sign" />
                                  </InputGroupText>
                                </div>
                                <Field
                                  name="saleSkinWeight"
                                  className="fieldShort"
                                  placeholder="0.00"
                                  component="input"
                                  type="text"
                                  inputmode="decimal"
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">/hd</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody className="bg-light">
                      <CardTitle className="mb-0 subTitle">
                        <img alt="buy" src="/buyLogo.png" />{" "}
                        <Label className="purchaseTitle">
                          Purchase&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="purchaseCostHd"
                            title={this.getTitle("purchaseCostHd")}
                            description={this.getDescription("purchaseCostHd")}
                          />
                        </Label>
                        {this.props.calc?.trade?.isSaleOverprice && (
                          <Label className="Underpriced">Underpriced</Label>
                        )}
                        {!this.props.calc?.trade?.isSaleOverprice && (
                          <Label className="Overpriced">Overpriced</Label>
                        )}
                      </CardTitle>
                    </CardBody>
                    <CardBody>
                      {this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            {this.props.currentTrade?.animalTypeId ===
                            this.state.SheepToken
                              ? "Purchase Sheep"
                              : "Purchase Cow"}{" "}
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <Field
                                name="purchaseCowDescription"
                                className="field"
                                component="input"
                                type="text"
                                placeholder={
                                  this.props.currentTrade?.animalTypeId ===
                                  this.state.SheepToken
                                    ? "Purchase Sheep Description"
                                    : "Purchase Cow Description"
                                }
                              />{" "}
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>
                              {this.props.currentTrade?.animalTypeId ===
                              this.state.SheepToken
                                ? "Sheep"
                                : "Cow"}
                            </Label>
                          </Col>
                          <Col md="2">
                            <InputGroup>
                              <Label> $/hd </Label>
                            </InputGroup>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="purchaseCostHd"
                                placeholder="0.00"
                                component="input"
                                format={this.formatNumber}
                                type="text"
                                className="fieldShort"
                                inputmode="decimal"
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) &&
                        !this.state.PerHeadOnlyToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">Purchase Live Wgt</Col>
                            <Col md="5">
                              <InputGroup>
                                <Field
                                  name="purchaseWeightkg"
                                  component="input"
                                  type="text"
                                  className="fieldMed"
                                  placeholder="Weight"
                                  onInput={this.onBuyWeightChange.bind(this)}
                                  inputmode="decimal"
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">kg</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}

                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) &&
                        !this.state.PerHeadOnlyToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">
                              <InputGroup>
                                <Label> $/kg </Label>
                              </InputGroup>
                            </Col>
                            <Col md="5">
                              <InputGroup>
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <i>$</i>
                                  </InputGroupText>
                                </div>
                                <Field
                                  name="purchaseCostCKg"
                                  component="input"
                                  className="fieldShort"
                                  format={this.formatNumber}
                                  placeholder="0"
                                  type="text"
                                  inputmode="decimal"
                                  onInput={this.onBuyCostKgChange.bind(this)}
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">/kg</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}

                      {!this.state.SplitterTokens.includes(
                        this.props.currentTrade?.animalSubTypeId
                      ) && (
                        <Row className="rowHeight">
                          <Col md="3">
                            <InputGroup>
                              <Label> $/head </Label>
                            </InputGroup>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="purchaseCostHd"
                                component="input"
                                type="text"
                                className="fieldShort"
                                format={this.formatNumber}
                                placeholder="0.00"
                                inputmode="decimal"
                                onInput={this.onBuyCostChange.bind(this)}
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}

                      {this.props.currentTrade?.animalTypeId ===
                        this.state.SheepToken &&
                        !this.state.SheepShornToken.includes(
                          this.props.currentTrade?.animalSubTypeId
                        ) && (
                          <Row className="rowHeight">
                            <Col md="3">
                              <Label>Skin</Label>
                            </Col>

                            <Col md="5">
                              <InputGroup>
                                <div className="halfWidth"> </div>
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <i className="fas fa-dollar-sign" />
                                  </InputGroupText>
                                </div>
                                <Field
                                  name="purchaseSkinWeight"
                                  component="input"
                                  type="text"
                                  className="fieldShort"
                                  inputmode="decimal"
                                  placeholder="0.00"
                                />{" "}
                                <div className="input-group-append">
                                  <InputGroupText>
                                    <div className="unitType">/hd</div>
                                  </InputGroupText>
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                        )}
                    </CardBody>
                  </Card>

                  {this.props.currentTrade?.animalTypeId ===
                    this.state.SheepToken && (
                    <Card>
                      <CardBody className="bg-light">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/carry.png" /> Wool Sold
                          &nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="woolWeight"
                            title={this.getTitle("woolWeight")}
                            description={this.getDescription("woolWeight")}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>Weight</Label>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-weight" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="woolSaleWeight"
                                component="input"
                                type="text"
                                inputmode="decimal"
                                className="fieldShort"
                                placeholder="0.00"
                                onInput={this.onWoolWeightChange.bind(this)}
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">kg</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>$ / Kg</Label>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="woolPriceKg"
                                component="input"
                                className="fieldShort"
                                format={this.formatNumber}
                                placeholder="0"
                                inputmode="decimal"
                                type="text"
                                onInput={this.onWoolPriceKgChange.bind(this)}
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">kg</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="3">
                            <Label>$/Head</Label>
                          </Col>
                          <Col md="5">
                            <InputGroup>
                              <div className="input-group-append">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" />
                                </InputGroupText>
                              </div>
                              <Field
                                name="woolPriceHd"
                                component="input"
                                type="text"
                                className="fieldShort"
                                format={this.formatNumber}
                                inputmode="decimal"
                                placeholder="0.00"
                                onInput={this.onWoolPriceHeadChange.bind(this)}
                              />{" "}
                              <div className="input-group-append">
                                <InputGroupText>
                                  <div className="unitType">/hd</div>
                                </InputGroupText>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Capital Required{" "}
                          &nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="capInvestInStock"
                            title={this.getTitle("capInvestInStock")}
                            description={this.getDescription(
                              "capInvestInStock"
                            )}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Capital Invested In Stock</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.capInvestInStock &&
                              this.currencyFormat(
                                this.props.calc?.trade?.capInvestInStock
                              )}
                            {this.props.calc?.trade?.capInvestInStock ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.capInvestInStock &&
                              this.currencyFormat(
                                this.props.calc?.trade?.capInvestInStock
                              )}
                            {this.props.calc?.trade?.capInvestInStock ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12">
                            <Label className="summaryLabel">
                              Capital Required For CofC
                            </Label>
                          </Col>

                          <Col md="4">
                            <Label className="shornLabel"> Shorn</Label> &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornTotalCost &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornTotalCost
                              )}
                            {this.props.calc?.trade?.shornTotalCost ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornTotalCost &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornTotalCost
                              )}
                            {this.props.calc?.trade?.notShornTotalCost ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {!this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Capital
                          Required&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="capInvestInStock"
                            title={this.getTitle("capInvestInStock")}
                            description={this.getDescription(
                              "capInvestInStock"
                            )}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Capital Invested In Stock</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$ Required</Label>{" "}
                            &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.capInvestInStock &&
                              this.currencyFormat(
                                this.props.calc?.trade?.capInvestInStock
                              )}
                            {this.props.calc?.trade?.capInvestInStock ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Capital Required For CofC</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$ Required</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.capReqForCarry &&
                              this.currencyFormat(
                                this.props.calc?.trade?.capReqForCarry
                              )}
                            {this.props.calc?.trade?.capReqForCarry ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Cost Of
                          Carry&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="costOfCarryPerHead"
                            title={this.getTitle("costOfCarryPerHead")}
                            description={this.getDescription(
                              "costOfCarryPerHead"
                            )}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Cost Of Carry / Head</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornTotalCostPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornTotalCostPerHead
                              )}
                            {this.props.calc?.trade?.shornTotalCostPerHead ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornTotalCostPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornTotalCostPerHead
                              )}
                            {this.props.calc?.trade
                              ?.notShornTotalCostPerHead === undefined &&
                              `$0.00`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {!this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Cost Of
                          Carry&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="costOfCarryPerHead"
                            title={this.getTitle("costOfCarryPerHead")}
                            description={this.getDescription(
                              "costOfCarryPerHead"
                            )}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Cost Of Carry / Head</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$/Head</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.costOfCarryPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.costOfCarryPerHead
                              )}
                            {this.props.calc?.trade?.costOfCarryPerHead ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Profit This
                          Trade&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="profitPerHeadPa"
                            title={this.getTitle("profitPerHeadPa")}
                            description={this.getDescription("profitPerHeadPa")}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Profit Per Head</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornProfitPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornProfitPerHead
                              )}
                            {this.props.calc?.trade?.shornProfitPerHead ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornProfitPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornProfitPerHead
                              )}
                            {this.props.calc?.trade?.notShornProfitPerHead ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Total Profit</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornTotalProfit &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornTotalProfit
                              )}
                            {this.props.calc?.trade?.shornTotalProfit ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornTotalProfit &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornTotalProfit
                              )}
                            {this.props.calc?.trade?.notShornTotalProfit ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Return On Capital</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornReturnOnCapital &&
                              `${this.props.calc?.trade?.shornReturnOnCapital.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade?.shornReturnOnCapital ===
                              undefined && `0.00%`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>

                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornReturnOnCapital &&
                              `${this.props.calc?.trade?.notShornReturnOnCapital.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade?.notShornReturnOnCapital ===
                              undefined && `0.00%`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {!this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Profit This
                          Trade&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="profitPerHeadPa"
                            title={this.getTitle("profitPerHeadPa")}
                            description={this.getDescription("profitPerHeadPa")}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Profit Per Head</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$/Head</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.profitPerHead &&
                              this.currencyFormat(
                                this.props.calc?.trade?.profitPerHead
                              )}
                            {this.props.calc?.trade?.profitPerHead ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Total Profit</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$ Total</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.totalProfit &&
                              this.currencyFormat(
                                this.props.calc?.trade?.totalProfit
                              )}
                            {this.props.calc?.trade?.totalProfit ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Return On Capital</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">ROC%</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.returnOnCapital &&
                              `${this.props.calc?.trade?.returnOnCapital.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade?.returnOnCapital ===
                              undefined && `0.00%`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Profit Annual
                          ROC&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="profitPerAnnum"
                            title={this.getTitle("profitPerAnnum")}
                            description={this.getDescription("profitPerAnnum")}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Per Head P.A</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornPerHeadPA &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornPerHeadPA
                              )}
                            {this.props.calc?.trade?.shornPerHeadPA ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornPerHeadPA &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornPerHeadPA
                              )}
                            {this.props.calc?.trade?.notShornPerHeadPA ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Per Annum Profit</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornPerAnnumProfit &&
                              this.currencyFormat(
                                this.props.calc?.trade?.shornPerAnnumProfit
                              )}
                            {this.props.calc?.trade?.shornPerAnnumProfit ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.notShornPerAnnumProfit &&
                              this.currencyFormat(
                                this.props.calc?.trade?.notShornPerAnnumProfit
                              )}
                            {this.props.calc?.trade?.notShornPerAnnumProfit ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Return On Capital PA</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Shorn</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.shornReturnOnCapitalPA &&
                              `${this.props.calc?.trade?.shornReturnOnCapitalPA.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade?.shornReturnOnCapitalPA ===
                              undefined && `0.00%`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">Not Shorn</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade
                              ?.notShornReturnOnCapitalPA &&
                              `${this.props.calc?.trade?.notShornReturnOnCapitalPA.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade
                              ?.notShornReturnOnCapitalPA === undefined &&
                              `0.00%`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}

                  {!this.state.SheepShornToken.includes(
                    this.props.currentTrade?.animalSubTypeId
                  ) && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" /> Profit Annual
                          ROC&nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="profitPerAnnum"
                            title={this.getTitle("profitPerAnnum")}
                            description={this.getDescription("profitPerAnnum")}
                          />
                        </CardTitle>
                      </CardBody>
                      <CardBody>
                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Per Head P.A.</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">$/Head/PA</Label>{" "}
                            &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.profitPerHeadPa &&
                              this.currencyFormat(
                                this.props.calc?.trade?.profitPerHeadPa
                              )}
                            {this.props.calc?.trade?.profitPerHeadPa ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Per Annum Profit</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">PA</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.profitPerAnnum &&
                              this.currencyFormat(
                                this.props.calc?.trade?.profitPerAnnum
                              )}
                            {this.props.calc?.trade?.profitPerAnnum ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryLabel">
                            <Label>Return On Capital PA</Label>
                          </Col>
                        </Row>
                        <Row className="rowHeight">
                          <Col md="4">
                            <Label className="shornLabel">ROC PA</Label> &nbsp;
                          </Col>
                          <Col md="8" className="summaryValueText">
                            {this.props.calc?.trade?.profitReturnOnCapitalPa &&
                              `${this.props.calc?.trade?.profitReturnOnCapitalPa.toFixed(
                                2
                              )}%`}
                            {this.props.calc?.trade?.profitReturnOnCapitalPa ===
                              undefined && `$0.00`}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  {this.props.user && this.props.user.role === "Admin" && (
                    <Card className="greenBorder">
                      <CardBody className="bg-light ">
                        <CardTitle className="mb-0 subTitle">
                          <img alt="buy" src="/favicon.png" />
                          Calculations &nbsp;&nbsp;&nbsp;
                          <PopoverDetail
                            id="profitPerAnnum"
                            title={this.getTitle("profitPerAnnum")}
                            description={this.getDescription("profitPerAnnum")}
                          />
                        </CardTitle>

                        <Row className="rowHeight">
                          <Col md="12" className="summaryValueText">
                            <div className="textView">
                              {this.props.calc?.trade?.tradeCalcDescription}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={(state) => {
                      const { values, valid } = state;
                      this.changeValues(values);
                    }}
                  />
                </form>
              )}
            />

            <Footer />
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.currentTrade,
    currentTrade: state.currentTrade,
    carry: state.carry,
    currentGroup: state.group,
    calc: state.calc,
    groups: state.groups.groups,
    weightCarrys: state.carrys.weightCarrys,
    weeksHeldCarrys: state.carrys.weeksHeldCarrys,
    animalSubTypes: state.animalTypes.animalTypes,
    navIsPendingDelete: state.nav.isPendingDelete,
    navIsPendingSave: state.nav.isPendingSave,
    navIsPendingDuplicate: state.nav.isPendingDuplicate,
    navIsPendingLoad: state.nav.isPendingLoad,
    nav: state.nav,
    infos: state.infos.infos,
    user: state.user,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUpdateCurrentType: updateCurrentType,
      saveTrade,
      fetchTrade,
      addTrade,
      clearTrade,
      deleteTrade,
      fetchGroups,
      changeAnimalSubType,
      changeCarryType,
      changeSellWeight,
      changeSellCost,
      changeSellCostKg,
      changeWoolWeight,
      changeWoolPriceKg,
      changeWoolPriceHead,
      changeBuyWeight,
      changeBuyCost,
      changeBuyCostKg,
      navControls,
      navSave,
      navDelete,
      navDuplicate,
      fetchAllAnimalSubTypes,
      changeAnimalType,
      navDirty,
      addGroup,
      updateTradeCalc,
      updateCarryCalc,
      fetchCarry,
      fetchAllInfos,
      fetchCarrysByType,
      navSummary,
      changeTradeName,
      changeTradeNumber,
      changeCarry,
      changeGroup,
      updateDraft,
      navLoad,
    },
    dispatch
  );
};

// You have to connect() to any reducers that you wish to connect to yourself
TradeDetail = connect(mapStateToProps, mapActionsToProps)(TradeDetail);

//(Form({ form: "TRADE_DETAILS", enableReinitialize: true })(TradeDetail))

export default TradeDetail;
