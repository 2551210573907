import { useNavigate, useParams } from "react-router-dom";
import UserDetail from "./UserDetail";

const UserDetailFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <UserDetail useNavigate={nav} useParams={params} {...props} />;
};

export default UserDetailFn;
