import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  animalTypes: [],
};

export const animalTypesSlice = createSlice({
  name: "animalTypes",
  initialState,
  reducers: {
    animalTypeFetchAll: (state, action) => {
      state = { ...state, animalTypes: action.payload };

      return state;
    },
    animalTypesFetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };
    },
  },
});

export const { animalTypeFetchAll, animalTypesFetchError } =
  animalTypesSlice.actions;

export default animalTypesSlice.reducer;
