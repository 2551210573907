import React from "react";
import { Field, Form } from "react-final-form";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchInfo } from "../../actions/infoActions.js";

//import { getQueryString } from '../../utils/parseQueryString';

import { Card, CardBody, CardTitle, Row, Col, Label, Button } from "reactstrap";

import Banner from "../../components/banner/banner";
import Expire from "../../components/expire/expire";

import Loading from "../../components/spinners/loading";

class InfoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchInfo(this.props.useParams.Info);
  }

  back() {
    this.props.history.goBack();
  }

  // outside your render() method
  renderField = (field) => (
    <div className="input-row">
      <Label>{field.input.value}</Label>
      {/* <input {...field.input} type="text"/> */}
      {field.meta.touched && field.meta.error && (
        <span className="error">{field.meta.error}</span>
      )}
    </div>
  );

  render() {
    var banner;

    if (
      this.props.currentInfo.message !== "no message" &&
      this.props.currentInfo.message !== undefined &&
      !this.props.currentInfo.isLoading
    ) {
      banner = (
        <Expire delay={5000}>
          {" "}
          <Banner message={this.props.currentInfo.message} />
        </Expire>
      );
    }

    return (
      // <div className="d-flex justify-content-center ">
      <div className="d-flex  justify-content-center">
        {/*--------------------------------------------------------------------------------*/}
        {/* Highlighted Header Form                                                        */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {this.props.auth ? this.props.auth.isLoggedIn ? 'true': 'false': 'nada'} */}

        {this.props.currentInfo.isLoading ? (
          <Loading />
        ) : (
          <Col md="6">
            {banner}
            <Card>
              <CardBody className="bg-light ">
                <CardTitle className="mb-0 subTitle">
                  <img alt="buy" src="/favicon.png" /> Info View
                  <div className="alignRight">
                    <Button
                      type="button"
                      className="btn btn-dark ml-2"
                      onClick={this.back.bind(this)}
                    >
                      Back
                    </Button>
                  </div>
                </CardTitle>
              </CardBody>
              <CardBody className="fullScreen">
                <Row>
                  <Col md="12">
                    <Field
                      component={this.renderField}
                      name="title"
                      placeholder="information"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Field
                      component={this.renderField}
                      name="description"
                      placeholder="description"
                      type="text"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.info,
    currentInfo: state.info,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInfo,
    },
    dispatch
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// InfoView = Form({ form: "INFO_FORM", enableReinitialize: true })(InfoView);

// You have to connect() to any reducers that you wish to connect to yourself
InfoView = connect(mapStateToProps, mapActionsToProps)(InfoView);

export default InfoView;
