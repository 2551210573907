import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  message: "no message",
  isLoading: false,
};

export const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  reducers: {
    NotifyFetchAll: (state, action) => {
      state = action.payload;
      return state;
    },
    NotifyFetchError: (state, action) => {
      return state;
    },
  },
});

export const { NotifyFetchAll, NotifyFetchError } = notificationsSlice.actions;

export default notificationsSlice.reducer;

// import { NOTIFY_FETCH_ALL } from '../actions/notificationActions.js';

// export type State = {
//   notifications: Array,

//   message: string,
//   isLoading: boolean
// };

// const initialState: State = {
//   notifications: [],

//   message: 'no message',
//   isLoading: false
// };

// export default function notificationsReducer(
//   state: State = initialState,
//   { type, payload }
// ): State {
//   switch (type) {
//     case NOTIFY_FETCH_ALL:
//       state = payload;
//       break;

//     default:
//       return state;
//   }

//   return state;
// }
