import {
  animalTypefetch,
  animalTypeFetchError,
} from "../reducers/animalTypeReducer";
import {
  animalTypeFetchAll,
  animalTypesFetchError,
} from "../reducers/animalTypesReducer";

import axios from "axios";

export async function loadTypes(dispatch, animalTypeId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/animaltype/detail/${animalTypeId}`
    );

    var list = response.data;

    dispatch(animalTypeFetchAll(list));
  } catch (error) {
    console.error("Load Error");

    dispatch(animalTypesFetchError("Cannot load Animal types"));
  }
}

async function loadType(dispatch, animalSubTypeId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/animaltype/detail/${animalSubTypeId}`
    );

    var detail = {
      animalTypeId: response.data.animalTypeId,
      animalType: response.data.animalType,
      animalSubTypeId: response.data.animalSubTypeId,
      animalSubType: response.data.animalSubType,
    };

    dispatch(animalTypefetch(detail));
  } catch (error) {
    console.error("Load Error");

    dispatch(animalTypeFetchError("Cannot load Animal"));
  }
}

export const fetchAnimalSubType = (animalSubTypeId) => (dispatch) => {
  loadType(dispatch, animalSubTypeId);
};

export const fetchAllAnimalSubTypes = (animalTypeId) => (dispatch) => {
  loadTypes(dispatch, animalTypeId);
};
