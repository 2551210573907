import { batchActions } from "redux-batched-actions";
import { redirect } from "react-router-dom";


import {
  ClearGroup,
  GroupChangeName,
  GroupFetch,
  GroupFetchError,
  GroupIsLoading,
} from "../reducers/groupReducer";

import { GroupsFetchAll } from "../reducers/groupsReducer";

import axios from "axios";

async function saveCurrentGroup(dispatch, auth, userId, id, newGroup) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/TradeGroups/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    await axios.put(
      updateURL,
      {
        Name: newGroup.name,
      },
      { headers }
    );

    dispatch(
      batchActions([
        loadGroups(dispatch, auth, userId),
        loadGroup(dispatch, auth, userId, id),
      ])
    );
  } catch (error) {
    console.error(error);
  }
}

async function deleteCurrentGroup(dispatch, auth, userId, id, nav) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    console.log("Delete current group");
    var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/TradeGroups/${userId}/detail/${id}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'
    await axios.delete(updateURL, { headers });

    nav(`/trades/${userId}/tradeGroups`);

    dispatch(
      batchActions([
        loadGroups(dispatch, auth, userId),
        loadGroup(dispatch, auth, userId, id),
      ])
    );
  } catch (error) {
    console.error(error);
  }
}

async function addNewTrade(dispatch, auth, userId, id, tradeId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    var putURL = `${process.env.REACT_APP_API_DOMAIN}/api/TradeGroups/${userId}/detail/${id}/trades/${tradeId}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'

    await axios.put(putURL, {}, { headers });

    dispatch(loadGroup(dispatch, auth, userId, id));
  } catch (error) {
    console.error(error);
  }
}

async function removeCurrentTrade(dispatch, auth, userId, id, tradeId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    var deleteURL = `${process.env.REACT_APP_API_DOMAIN}/api/TradeGroups/${userId}/detail/${id}/trades/${tradeId}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'

    await axios.delete(deleteURL, { headers });

    dispatch(
      batchActions([
        loadGroups(dispatch, auth, userId),
        loadGroup(dispatch, auth, userId, id),
      ])
    );
  } catch (error) {
    console.error(error);
  }
}

async function addNewGroup(dispatch, auth, userId, newGroup, nav) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    var postURL = `${process.env.REACT_APP_API_DOMAIN}/api/TradeGroups/${userId}`; //?Name=' + newName + '&Id=1&TradeGroupId=1&AnimalTypeId=1'

    var response = await axios.post(
      postURL,
      {
        Name: newGroup.name,
      },
      { headers }
    );

   
    nav(`/trades/${userId}/tradeGroupDetail/${response.data.id}`);

   
    console.log(`New Group: ${response.data.id}`);
    dispatch(
      batchActions([
        loadGroups(dispatch, auth, userId),
        loadGroup(dispatch, auth, userId, response.data.id),
      ])
    );
  } catch (error) {
    console.error(error);
  }
}

export async function loadGroups(dispatch, auth, userId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    dispatch(GroupIsLoading(true));

    var urlCarrys = `${process.env.REACT_APP_API_DOMAIN}/api/tradegroups/${userId}`;

    const response = await axios.get(urlCarrys, { headers });

    var groups = response.data;

    dispatch(GroupsFetchAll(groups));
  } catch (error) {
    console.error(error);

    //---- I think this is where we call
    dispatch(GroupFetchError("Cannot load groups"));
  }
}

async function loadGroup(dispatch, auth, userId, id) {
  try {
    dispatch(GroupIsLoading(true));

    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/tradegroups/${userId}/detail/${id}`,
      { headers }
    );

    var group = {
      name: response.data.name,
      id: response.data.id,
      trades: response.data.trades,
      orphans: response.data.orphans,
      message: "Fetched Successfully",
      hello: "this is a message",
    };

    dispatch(GroupFetch(group));
  } catch (error) {
    console.error("Load Error");

    dispatch(GroupFetchError("Cannot load Group"));
  }
}

async function loadBlankGroup(dispatch, auth, slug) {
  let newGroup = {
    name: "",

    trades: [],

    orphans: [],

    message: "no message",
    isLoading: false,
    isSaving: false,
  };

  dispatch(ClearGroup(newGroup));
}

export const fetchGroup = (auth, userId, id) => (dispatch) => {
  loadGroup(dispatch, auth, userId, id);
};

export const fetchGroups = (auth, userId) => (dispatch) => {
  loadGroups(dispatch, auth, userId);
};

export const addGroup = (auth, userId, newGroup, nav) => (dispatch) => {
  addNewGroup(dispatch, auth, userId, newGroup, nav);
};

export const addTrade = (auth, userId, id, tradeId) => (dispatch) => {
  addNewTrade(dispatch, auth, userId, id, tradeId);
};

export const removeTrade = (auth, userId, id, tradeId) => (dispatch) => {
  removeCurrentTrade(dispatch, auth, userId, id, tradeId);
};

export const saveGroup = (auth, userId, id, newGroup) => (dispatch) => {
  saveCurrentGroup(dispatch, auth, userId, id, newGroup);
};

export const deleteGroup = (auth, userId, id, nav) => (dispatch) => {
  deleteCurrentGroup(dispatch, auth, userId, id, nav);
};

export const clearGroup = (auth, slug) => (dispatch) => {
  loadBlankGroup(dispatch, auth, slug);
};

export const changeGroupName = (newValue) => (dispatch) => {
  dispatch(GroupChangeName(newValue));
};
