import {
  NotifyFetchAll,
  NotifyFetchError,
} from "../reducers/notificationsReducer";
import axios from "axios";

async function expireNotify(dispatch, auth, userId, notificationId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/api/notify/${userId}/expire?notificationId=${notificationId}`,
      { headers }
    );

    dispatch(loadNotifications(dispatch, auth, userId));
  } catch (error) {
    console.error("Load Error");

    dispatch(NotifyFetchError("Cannot expire notifications"));
  }
}

export async function loadNotifications(dispatch, auth, userId) {
  try {
    const headers = { Authorization: `Bearer ${auth}` };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/notify/${userId}/all`,
      { headers }
    );

    const notifications = {
      notifications: response.data,

      isLoading: false,
      message: "Fetched Successfully",
    };

    dispatch(NotifyFetchAll(notifications));
  } catch (error) {
    console.error("Load Error");

    dispatch(NotifyFetchError("Cannot load notifications"));
  }
}

export const fetchAllNotifications = (auth, userId) => (dispatch) => {
  loadNotifications(dispatch, auth, userId);
};

export const expireNotification =
  (auth, userId, notificationId) => (dispatch) => {
    expireNotify(dispatch, auth, userId, notificationId);
  };
