import React from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

//import profilephoto from '../../assets/images/users/1.jpg';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from "../../assets/images/logo-icon.png";
import logolighticon from "../../assets/images/logo-light-icon.png";
import logodarktext from "../../assets/images/logo-light-text.png";
import logolighttext from "../../assets/images/logo-light-text.png";

import { connect } from "react-redux";
import {
  updateUser,
  updateSlug,
  updateLogin,
} from "../../actions/userActions.js";

import {
  fetchAllNotifications,
  expireNotification,
} from "../../actions/notificationActions.js";

import {
  navSave,
  navDelete,
  navGroupDelete,
  navDuplicate,
  navSummary,
} from "../../actions/navActions.js";

import { redirect } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.sidebarHandler = this.sidebarHandler.bind(this);
    this.state = {
      isOpen: true,
      reduceNotifyCount: 0,
      currentSlug: "",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.logout.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }

  componentDidMount() {
    //console.log("Header");
    //console.log(this.props.accessToken);
    //console.log(this.props.authUser.sub);
    //console.log("End header");

    //var hardcodedsub = "auth0|5bd651d05cfa62289c5c642f";

    this.props.updateLogin(this.props.accessToken, this.props.authUser.sub);

    this.setState({ reduceNotifyCount: 0 });

    this.props.navSummary(false);
  }

  /*--------------------------------------------------------------------------------*/
  /*To open Search Bar                                                              */
  /*--------------------------------------------------------------------------------*/
  toggleMenu() {
    document.getElementById("search").classList.toggle("show-search");
  }
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      reduceNotifyCount: 0,
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  }
  sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");

    if (element.getAttribute("data-sidebartype") == "full") {
      element.setAttribute("data-sidebartype", "overlay");
      //document.getElementById("main-wrapper").classList.toggle("show-sidebar");
      element.classList.remove("show-sidebar");
    } else {
      if (this.state.width >= 767) {
        element.setAttribute("data-sidebartype", "full");
      }
      element.classList.add("show-sidebar");
    }
  };

  deletePending() {
    this.setState({ isDeletePending: true });
  }

  deleteCancel() {
    this.setState({ isDeletePending: false });
  }

  deleteGroup() {
    var navSatus = {
      isPendingDelete: true,
    };

    this.props.navGroupDelete(navSatus);

    this.setState({ isDeletePending: false });
  }

  delete() {
    var navSatus = {
      isPendingDelete: true,
    };

    this.props.navDelete(navSatus);

    this.setState({ isDeletePending: false });
  }

  onSave() {
    var navSatus = {
      isPendingSave: true,
    };

    this.props.navSave(navSatus);
  }

  back() {
    redirect(-1);
  }

  onDuplicate() {
    var navSatus = {
      isPendingDuplicate: true,
    };

    this.props.navDuplicate(navSatus);
  }

  onOpenLink(link, id) {
    window.open(link, "_blank");

    this.setState({ reduceNotifyCount: this.state.reduceNotifyCount - 1 });

    this.props.expireNotification(this.props.user.slug, id);
  }

  render() {
    return (
      <header
        className="topbar navbarbg navHeight navHeaderBg "
        data-navbarbg={this.props.data?.settings[0].navbarbg}
      >
        <Navbar
          className={
            " top-navbar" +
            (this.props.data?.settings[0].navbarbg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          <div
            className="navbar-header  width100Percent"
            id="logobg"
            data-logobg={this.props.data?.settings[0].logobg}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}

            <div className="nav-toggler  d-block d-md-none text-white  width100Percent">
              {/* <div className="nav-toggler d-block d-md-none text-white"> */}{" "}
              <span className=" align-items-center btn menuTop">
                <a href="/">
                  <img
                    src={logodarkicon}
                    alt="homepage"
                    className="dark-logo"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  className="text-black cursorHand "
                  onClick={this.sidebarHandler.bind(this)}
                >
                  <i className="ti-menu ti-close mr-2 navWhiteColor">
                    <span className="menuText">Menu </span>
                  </i>
                </a>
              </span>
              {/* </div>
            <div className="nav-toggler d-block d-md-none text-white redBack"> */}
              {/* {this.props.nav?.isShowControls === true ||
              this.props.nav?.isShowSave === true ? ( */}
              {/* <Nav className="float-left  btnActionsTop" navbar>
                 <div className="greenBack"> */}
              {this.props.nav.isShowControls === true &&
              !this.state.isDeletePending &&
              this.props.nav.isDirty ? (
                <Button
                  type="submit"
                  className="btn  btn-green btnActions"
                  onClick={this.onSave.bind(this)}
                >
                  Save
                </Button>
              ) : (
                <span />
              )}
              {(this.props.nav.isShowControls === true ||
                this.props.nav.isShowSave === true) &&
              !this.state.isDeletePending &&
              !this.props.nav.isDirty ? (
                <Button
                  type="submit"
                  className="btn  btn-green btnActionsSmall"
                  onClick={this.onSave.bind(this)}
                >
                  Save
                </Button>
              ) : (
                <span />
              )}
              {this.props.nav.isShowControls === true &&
              this.state.isDeletePending ? (
                <Button
                  type="button"
                  className="btn btn-danger ml-2 btnActionsDelete"
                  onClick={this.delete.bind(this)}
                >
                  Delete
                </Button>
              ) : (
                <span />
              )}
              {this.props.nav.isShowDelete === true &&
              this.state.isDeletePending ? (
                <Button
                  type="button"
                  className="btn btn-danger ml-2 btnActionsDelete"
                  onClick={this.deleteGroup.bind(this)}
                >
                  Delete Group
                </Button>
              ) : (
                <span />
              )}
              {(this.props.nav.isShowControls === true ||
                this.props.nav.isShowDelete === true) &&
              this.state.isDeletePending ? (
                <Button
                  type="button"
                  className="btn btn-light ml-2 btnActionsDelete btnActionsSmall"
                  onClick={this.deleteCancel.bind(this)}
                >
                  Cancel
                </Button>
              ) : (
                <span />
              )}
              {(this.props.nav.isShowControls === true ||
                this.props.nav.isShowDelete === true) &&
              !this.state.isDeletePending ? (
                <Button
                  type="button"
                  className="btn btn-green ml-2 btnActionsSmall"
                  onClick={this.deletePending.bind(this)}
                >
                  Del
                </Button>
              ) : (
                <span />
              )}
              {this.props.nav.isShowControls === true &&
              !this.state.isDeletePending ? (
                <Button
                  type="button"
                  className="btn btn-green ml-2 btnActionsSmall"
                  onClick={this.onDuplicate.bind(this)}
                >
                  Copy
                </Button>
              ) : (
                <span />
              )}
              {this.props.notifications.data &&
                this.props.notifications.count > 0 && (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <i className="mdi mdi-message" />
                      {this.props.notifications.countNotExpired > 0 && (
                        <div className="notify">
                          <span className="heartbit" />{" "}
                          <span className="point" />
                          <Badge color="danger" className="badgePoint" pill>
                            {this.props.notifications.countNotExpired}
                          </Badge>
                        </div>
                      )}
                    </DropdownToggle>

                    <DropdownMenu end className="mailbox">
                      <div className="p-4 text-dark border-bottom">
                        <h6 className="mb-0 font-medium">Notifications</h6>
                      </div>
                      <div className="message-center notifications">
                        {this.props.notifications.data.map(
                          (notification, index) => {
                            return (
                              <span
                                onClick={this.onOpenLink.bind(
                                  this,
                                  notification.urlLink,
                                  notification.id
                                )}
                                className="message-item"
                                key={index}
                              >
                                <span
                                  className={
                                    // 'btn btn-circle btn-' + notification.iconbg
                                    " btnNotify btn-circle btn-" +
                                    notification.iconClass
                                  }
                                >
                                  <i className={notification.buttonClass} />
                                </span>
                                <div className="mail-contnet">
                                  <h5 className="message-title">
                                    {notification.title}
                                  </h5>
                                  <span className="mail-desc">
                                    {notification.description}
                                  </span>
                                  <span className="time">
                                    {notification.displayDate}
                                  </span>
                                </div>
                              </span>
                            );
                          }
                        )}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Notifications Dropdown                                                     */}
              {/*--------------------------------------------------------------------------------*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Profile Dropdown                                                         */}
              {/*--------------------------------------------------------------------------------*/}
              {/* <span className="btn noTop navRedBg  btnActionsSmall btnNoLeft"> */}
              {/* <span className="align-items-center  btn noTop4   btnActionsSmall">
                <UncontrolledDropdown className="">
                  <DropdownToggle nav caret className="pro-pic">
                    <img
                      src={this.props.authUser?.picture}
                      alt="user"
                      className="rounded-circle"
                      width="36"
                    />
                  </DropdownToggle>

                  <DropdownMenu end className="user-dd">
                    <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                      <div className="">
                        <img
                          src={this.props.authUser?.picture}
                          alt="user"
                          className="rounded"
                          width="80"
                        />
                      </div>
                      <div className="ml-3">
                        <h4 className="mb-0">
                          {this.props.authUser?.nickname}
                        </h4>
                        <p className="text-muted mb-0">
                          {this.props.authUser?.name}
                        </p>
                      </div>
                    </div>

                    <DropdownItem>
                      <div onClick={this.logout.bind(this)}>
                        <div className="fa fa-power-off mr-1 ml-1" />
                        <div className="logout">Logout</div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </span> */}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Profile Dropdown                                                           */}
              {/*--------------------------------------------------------------------------------*/}
              {/* </Nav> */}
              {/* </div>
              ) : (
                <div />
              )} */}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <NavbarBrand>
              <div className="float-left">
                <a href="/">
                  <b className="logo-icon">
                    <img
                      src={logodarkicon}
                      alt="homepage"
                      className="dark-logo"
                    />

                    <img
                      src={logolighticon}
                      alt="homepage"
                      className="light-logo"
                    />
                  </b>
                  <span className="logo-text">
                    <img
                      src={logodarktext}
                      alt="homepage"
                      className="dark-logo"
                    />

                    <img
                      src={logolighttext}
                      className="light-logo"
                      alt="homepage"
                    />
                  </span>
                </a>
              </div>
            </NavbarBrand> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}

            <div className="d-none d-md-block">
              <a
                className="topbartoggler d-block d-md-none text-white"
                onClick={this.toggle}
              >
                <i className="ti-more-alt" />
              </a>

              <Nav className="float-left" navbar>
                <NavItem>
                  <NavLink href="/" className="d-none d-md-block">
                    <img
                      src={logodarkicon}
                      alt="homepage"
                      className="dark-logo"
                    />
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    href="#"
                    className="d-none d-md-block menuLeft"
                    onClick={this.sidebarHandler}
                  >
                    <i className="ti-menu navWhiteColor">
                      <span className="menuText ">Menu</span>
                    </i>
                  </NavLink>
                </NavItem>

                {(this.props.nav?.isShowControls === true ||
                  this.props.nav?.isShowSave === true) &&
                !this.state.isDeletePending &&
                this.props.nav?.isDirty ? (
                  <Button
                    type="submit"
                    className="btn btn-green btnActions"
                    onClick={this.onSave.bind(this)}
                  >
                    {" "}
                    Save
                  </Button>
                ) : (
                  <span />
                )}

                {(this.props.nav?.isShowControls === true ||
                  this.props.nav?.isShowSave === true) &&
                !this.state.isDeletePending &&
                !this.props.nav?.isDirty ? (
                  <Button
                    type="submit"
                    className="btn btn-green btnActions"
                    onClick={this.onSave.bind(this)}
                  >
                    {" "}
                    Save
                  </Button>
                ) : (
                  <span />
                )}
                {this.props.nav?.isShowControls === true &&
                this.state.isDeletePending ? (
                  <Button
                    type="button"
                    className="btn btn-danger ml-2 btnActionsDelete"
                    onClick={this.delete.bind(this)}
                  >
                    Delete
                  </Button>
                ) : (
                  <span />
                )}

                {this.props.nav?.isShowDelete === true &&
                this.state.isDeletePending ? (
                  <Button
                    type="button"
                    className="btn btn-danger ml-2 btnActionsDelete"
                    onClick={this.deleteGroup.bind(this)}
                  >
                    Delete
                  </Button>
                ) : (
                  <div />
                )}
                {(this.props.nav?.isShowControls === true ||
                  this.props.nav?.isShowDelete === true) &&
                this.state.isDeletePending ? (
                  <Button
                    type="button"
                    className="btn btn-light ml-2 btnActionsDelete"
                    onClick={this.deleteCancel.bind(this)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <div />
                )}
                {(this.props.nav?.isShowControls === true ||
                  this.props.nav?.isShowDelete === true) &&
                !this.state.isDeletePending ? (
                  <Button
                    type="button"
                    className="btn btn-green ml-2 btnActions"
                    onClick={this.deletePending.bind(this)}
                  >
                    Delete
                  </Button>
                ) : (
                  <div />
                )}
                {this.props.nav?.isShowControls === true &&
                  !this.state.isDeletePending && (
                    <Button
                      type="button"
                      className="btn btn-green ml-2 btnActions"
                      onClick={this.onDuplicate.bind(this)}
                    >
                      Copy
                    </Button>
                  )}

                {this.props.notifications?.data &&
                  this.props.notifications?.count > 0 && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        <i className="mdi mdi-message" />
                        {this.props.notifications.countNotExpired > 0 && (
                          <div className="notify">
                            <span className="heartbit" />{" "}
                            <span className="point" />
                            <Badge color="danger" className="badgePoint" pill>
                              {this.props.notifications.countNotExpired}
                            </Badge>
                          </div>
                        )}
                      </DropdownToggle>

                      <DropdownMenu end className="mailbox navWhiteColor">
                        <div className="p-4 text-dark border-bottom">
                          <h6 className="mb-0 font-medium">Notifications</h6>
                        </div>
                        <div className="message-center notifications">
                          {this.props.notifications?.data.map(
                            (notification, index) => {
                              return (
                                <span
                                  onClick={this.onOpenLink.bind(
                                    this,
                                    notification.urlLink,
                                    notification.id
                                  )}
                                  className="message-item"
                                  key={index}
                                >
                                  <span
                                    className={
                                      " btnNotify btn-circle btn-" +
                                      notification.iconClass
                                    }
                                  >
                                    <i className={notification.buttonClass} />
                                  </span>
                                  <div className="mail-contnet">
                                    <h5 className="message-title">
                                      {notification.title}
                                    </h5>
                                    <span className="mail-desc">
                                      {notification.description}
                                    </span>
                                    <span className="time">
                                      {notification.displayDate}
                                    </span>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}

                {/*--------------------------------------------------------------------------------*/}
                {/* End Notifications Dropdown                                                     */}
                {/*--------------------------------------------------------------------------------*/}

                {/*--------------------------------------------------------------------------------*/}
                {/* Start Profile Dropdown                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="pro-pic navWhiteColor">
                    <img
                      src={this.props.authUser?.picture}
                      alt="user"
                      className="rounded-circle"
                      width="31"
                    />
                  </DropdownToggle>

                  <DropdownMenu end className="user-dd ">
                    <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                      <div className="">
                        <img
                          src={this.props.authUser?.picture}
                          alt="user"
                          className="rounded"
                          width="80"
                        />
                      </div>
                      <div className="ml-3">
                        <h4 className="mb-0">
                          {this.props.authUser?.nickname}
                        </h4>
                        <p className="text-muted mb-0">
                          {this.props.authUser?.email}
                        </p>
                      </div>
                    </div>

                    <DropdownItem>
                      <div onClick={this.logout.bind(this)}>
                        <div className="fa fa-power-off mr-1 ml-1" />
                        <div className="logout">Logout</div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* End Profile Dropdown                                                           */}
                {/*--------------------------------------------------------------------------------*/}
              </Nav>
            </div>
          </div>

          {/*--------------------------------------------------------------------------------*/}
          {/* End Search-box toggle                                                          */}
          {/*--------------------------------------------------------------------------------*/}
          {/*--------------------------------------------------------------------------------*/}
          {/* Start Mega Menu Dropdown                                                       */}
          {/*--------------------------------------------------------------------------------*/}
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentTrade: state.currentTrade,
    nav: state.nav,
    navIsShowControls: state.nav.isShowControls,
    notifications: state.notifications.notifications,
  };
};

const mapActionsToProps = {
  updateUser,
  updateSlug,
  navSave,
  navDelete,
  navGroupDelete,
  navDuplicate,
  fetchAllNotifications,
  expireNotification,
  navSummary,
  updateLogin,
};

export default connect(mapStateToProps, mapActionsToProps)(Header);

//export default Header;
