import { useLocation, useNavigate, useParams } from "react-router-dom";
import CarryDetail from "./CarryDetail";
import { useAuth0 } from "@auth0/auth0-react";

const CarryDetailFn = (props) => {
  const nav = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { user, isAuthenticated } = useAuth0();

  return (
    <CarryDetail
      useNavigate={nav}
      useParams={params}
      authUser={user}
      location={location}
      isAuthenticated={isAuthenticated}
      {...props}
    />
  );
};

export default CarryDetailFn;
