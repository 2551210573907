import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPendingSave: false,
  isShowControls: false,
  isShowDelete: false,
  isShowSave: false,
  isPendingDuplicate: false,
  isPendingDelete: false,
  isPendingLoad: false,
  isDirty: false,
  isShowSummary: false,
};

export const navSlice = createSlice({
  name: "navSlice",
  initialState,
  reducers: {
    NavSave: (state, action) => {
      state = { ...state, isPendingSave: action.payload };
      return state;
    },
    NavDuplicate: (state, action) => {
      //console.log("Set pending duplicate");
      state = { ...state, isPendingDuplicate: action.payload };
      return state;
    },
    NavLoad: (state, action) => {
      state = { ...state, isPendingLoad: action.payload };
      return state;
    },
    NavDelete: (state, action) => {
      state = { ...state, isPendingDelete: action.payload };
      return state;
    },
    NavGroupDelete: (state, action) => {
      state = { ...state, isPendingDelete: action.payload };
      return state;
    },
    NavDirty: (state, action) => {
      state = { ...state, isDirty: action.payload };
      return state;
    },
    NavControl: (state, action) => {
      state = {
        ...state,
        isShowControls: action.payload.isShowControls,
        isShowDelete: action.payload.isShowDelete ?? state.isShowDelete,
        isShowSave: action.payload.isShowSave ?? state.isShowSave,
      };

      return state;
    },
    NavShowSummary: (state, action) => {
      state = { ...state, isShowSummary: action.payload };
      return state;
    },
  },
});

export const {
  NavControl,
  NavDelete,
  NavDirty,
  NavDuplicate,
  NavGroupDelete,
  NavSave,
  NavLoad,
  NavShowSummary,
} = navSlice.actions;

export default navSlice.reducer;

// import {
//   NAV_SAVE,
//   NAV_DUPLICATE,
//   NAV_DELETE,
//   NAV_GROUP_DELETE,
//   NAV_DIRTY,
//   NAV_CONTROL,
//   NAV_SHOW_SUMMARY
// } from '../actions/navActions.js';

// export type State = {
//   isPendingSave: boolean,
//   isShowControls: boolean,
//   isShowDelete: boolean,
//   isShowSave: boolean,
//   isPendingDel: boolean,
//   isPendingDelete: boolean,
//   isDirty: boolean,
//   isShowSummary: boolean
// };

// const initialState: State = {
//   isPendingSave: false,
//   isShowControls: false,
//   isShowDelete: false,
//   isShowSave: false,
//   isPendingDuplicate: false,
//   isPendingDelete: false,
//   isDirty: false,
//   isShowSummary: false
// };

// export default function navReducer(state = initialState, { type, payload }) {
//   switch (type) {
//     case NAV_SAVE:
//       state = { ...state, isPendingSave: payload };
//       break;

//     case NAV_DUPLICATE:
//       state = { ...state, isPendingDuplicate: payload };
//       break;

//     case NAV_DELETE:
//       state = { ...state, isPendingDelete: payload };
//       break;

//     case NAV_GROUP_DELETE:
//       state = { ...state, isPendingDelete: payload };
//       break;

//     case NAV_DIRTY:
//       state = { ...state, isDirty: payload };
//       break;

//     case NAV_CONTROL:
//       state = {
//         ...state,
//         isShowControls: payload.isShowControls,
//         isShowDelete: payload.isShowDelete,
//         isShowSave: payload.isShowSave
//       };
//       break;

//     case NAV_SHOW_SUMMARY:
//       state = { ...state, isShowSummary: payload };
//       break;

//     default:
//       return state;
//   }

//   return state;
// }
