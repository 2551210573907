import React from "react";
import { Form } from "react-final-form";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchInfo } from "../../actions/infoActions.js";

import { Card, CardBody, CardTitle, Row, Col, Label, Button } from "reactstrap";

import Footer from "../../components/footer/footer.jsx";

class MainMenuView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchInfo(this.props.useParams.Info);
  }

  back() {
    this.props.useNavigate(-1);
  }

  // outside your render() method
  renderField = (field) => (
    <div className="input-row">
      <Label>{field.input.value}</Label>
      {/* <input {...field.input} type="text"/> */}
      {field.meta.touched && field.meta.error && (
        <span className="error">{field.meta.error}</span>
      )}
    </div>
  );

  render() {
    var banner;

    return (
      <div className="minWidth">
        <div className="screenView">
          <Card>
            <CardTitle className="mb-0 bg-light p-3 ">
              <Row>
                <Col>
                  <h2>Welcome to the</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>KLR Trade Calculator 2023</h2>{" "}
                </Col>
              </Row>
            </CardTitle>

            <CardBody>
              <Row>
                <Col>
                  The KLR Mastermind is a support program that KLR delivers to
                  assist livestock producers to implement the KLR methodology
                  into their livestock business. There are a range of services
                  delivered online and offline, which include a unique market
                  report called the KLR 30 Second Market report, profit
                  calculators, teleconferences as well as mentoring days in
                  regional areas.
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row>
              <Row>
                <Col>
                  <a href="https://klrmarketing.com/apptraining/">
                    Click here to watch the video
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.info,
    currentInfo: state.info,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInfo,
    },
    dispatch
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// MainMenuView = Form({ form: "MAINMENU_FORM", enableReinitialize: true })(
//   MainMenuView
// );

// You have to connect() to any reducers that you wish to connect to yourself
MainMenuView = connect(mapStateToProps, mapActionsToProps)(MainMenuView);

export default MainMenuView;
