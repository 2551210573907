import {
  NavControl,
  NavDelete,
  NavDirty,
  NavDuplicate,
  NavLoad,
  NavGroupDelete,
  NavSave,
  NavShowSummary,
} from "../reducers/navReducer";

async function navCurrentSave(dispatch, status) {
  dispatch(NavSave(status.isPendingSave));
}

async function navCurrentLoad(dispatch, status) {
  dispatch(NavLoad(status));
}

async function navCurrentDuplicate(dispatch, status) {
  dispatch(NavDuplicate(status.isPendingDuplicate));
}

async function navCurrentDelete(dispatch, status) {
  dispatch(NavDelete(status.isPendingDelete));
}

async function navCurrentGrouptDelete(dispatch, status) {
  dispatch(NavGroupDelete(status.isPendingDelete));
}

async function navCurrentDirty(dispatch, status) {
  dispatch(NavDirty(status.isDirty));
}

async function navCurrentControls(dispatch, status) {
  dispatch(NavControl(status));
}

async function navCurrentSummary(dispatch, status) {
  dispatch(NavShowSummary(status));
}

export const navControls = (status) => (dispatch) => {
  navCurrentControls(dispatch, status);
};

export const navSave = (status) => (dispatch) => {
  navCurrentSave(dispatch, status);
};

export const navLoad = (status) => (dispatch) => {
  navCurrentLoad(dispatch, status);
};

export const navDuplicate = (status) => (dispatch) => {
  navCurrentDuplicate(dispatch, status);
};

export const navDelete = (status) => (dispatch) => {
  navCurrentDelete(dispatch, status);
};

export const navGroupDelete = (status) => (dispatch) => {
  navCurrentGrouptDelete(dispatch, status);
};

export const navDirty = (status) => (dispatch) => {
  navCurrentDirty(dispatch, status);
};

export const navSummary = (status) => (dispatch) => {
  navCurrentSummary(dispatch, status);
};
