import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slug: "",
  email: "",
  role: "",
  isLoading: true,
  message: "no message",
  auth: "",
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    UpdateUser: (state, action) => {
      state = { ...state, email: action.payload, message: "saving" };
      return state;
    },

    UpdateRole: (state, action) => {
      state = { ...state, role: action.payload };
      return state;
    },

    UpdateSlug: (state, action) => {
      state = { ...state, slug: action.payload };
      return state;
    },

    UpdateAuth: (state, action) => {
      state = { ...state, auth: action.payload };
      return state;
    },

    LoadingUser: (state, action) => {
      state = { ...state, isLoading: action.payload, message: "no message" };
      return state;
    },

    SavingUser: (state) => {
      state = { ...state, message: "saving user" };
      return state;
    },

    SavedUser: (state) => {
      state = { ...state, message: "User Saved successfully" };
      return state;
    },

    FetchUserError: (state) => {
      state = { ...state, message: "Cannot save link to user" };
      return state;
    },

    UserChangeDraft: (state, action) => {
      state = {
        ...state,

        slug: action.payload.slug,
        email: action.payload.email,
        role: action.payload.role,
        isLoading: false,
        message: "no message",
        auth: action.payload.auth,
      };
      return state;
    },
  },
});

export const {
  UpdateUser,
  UpdateRole,
  UpdateSlug,
  LoadingUser,
  SavingUser,
  SavedUser,
  FetchUserError,
  UpdateAuth,
  UserChangeDraft,
} = userSlice.actions;

export default userSlice.reducer;

// import { UPDATE_USER } from '../actions/userActions.js';
// import { UPDATE_ROLE } from '../actions/userActions.js';
// import { UPDATE_SLUG } from '../actions/userActions.js';
// import { LOADING_USER } from '../actions/userActions.js';
// import { SAVING_USER } from '../actions/userActions.js';
// import { SAVED_USER } from '../actions/userActions.js';
// import { FETCH_USER_ERROR } from '../actions/userActions.js';

// export type State = {
//   slug: string,
//   email: string,
//   role: string,
//   isLoading: boolean
// };

// const initialState: State = {
//   slug: '',
//   email: '',
//   role: '',
//   isLoading: true,
//   message: 'no message'
// };

// export default function userReducer(state = initialState, { type, payload }) {
//   switch (type) {
//     case UPDATE_SLUG:
//       return { ...state, slug: payload };

//     case UPDATE_USER:
//       return { ...state, email: payload, message: 'saving' };

//     case UPDATE_ROLE:
//       return { ...state, role: payload };

//     case LOADING_USER:
//       return { ...state, isLoading: payload, message: 'no message' };

//     case SAVING_USER:
//       return { ...state, message: 'saving user' };

//     case SAVED_USER:
//       return { ...state, message: 'User Saved successfully' };

//     case FETCH_USER_ERROR:
//       return { ...state, message: 'Cannot save link to user' };

//     default:
//       return state;
//   }
// }
