import { useNavigate, useParams } from "react-router-dom";
import TermsView from "./termsandconditions";

const TermsViewFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <TermsView useNavigate={nav} useParams={params} {...props} />;
};

export default TermsViewFn;
