import { useNavigate, useParams } from "react-router-dom";
import MainMenuView from "./mainMenu";

const MainMenuViewFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <MainMenuView useNavigate={nav} useParams={params} {...props} />;
};

export default MainMenuViewFn;
