import React, { useState, useEffect } from "react";

import Header from "./header";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/spinners/loading";

const HeaderFn = (props) => {
  const [token, setToken] = useState();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();

      // const hardCodedAccessToken =
      //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqQXpSa1JDTXpSQk9FVkJOVFE1TUVFd01EYzRNVGhDTTBKRE9UVTNRams0TlVFMU5rTTFOUSJ9.eyJpc3MiOiJodHRwczovL2tsci5hdS5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NWJkNjUxZDA1Y2ZhNjIyODljNWM2NDJmIiwiYXVkIjpbImh0dHBzOi8vZGV2LWFwaS1rbHIuY29tLmF1IiwiaHR0cHM6Ly9rbHIuYXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY4NTkzMDkyNCwiZXhwIjoxNjg1OTM4MTI0LCJhenAiOiJsY0N2Q2dXV0ZpRmw1c0wzcDZWbG93b21aemUyWkkyUSIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUifQ.CLsWsCokWzH6yALJwIbeZFHVPrCUXy8By3eJRmzVW1f6mXSAf6C4KDBLTPSszcO8YrjkeafgRRbImPbXnydolsjG2605aCsOI7Cz9IUQR_bO49uf4mKZ0_41EAk5ywN7y3BBqOFmqsbpvmT5SboNeuH889umvxFabiSOickNNBhBCUAdcFpXn64_wHIYywkqVEiKxm5nbjqUaA-ulWKib7ARA8oyI1wfmxNMPcL3BpdPjtJFKAy4XBQqUcL-HN4VsMCBVMK48DzDXSuBYxJYivyRa8tMWoO2QOKXT1tgmWOz9iFaHj6_1CHogwq2iSjG3j-C3t5EyeSfvqT6y1M1XA";

      //console.log("Set access token");
      //console.log(accessToken);

      setToken(accessToken);
    })();
  });

  const { user, isAuthenticated } = useAuth0();
  const logout = useAuth0();

  if (token == undefined) {
    return <Loading />;
  }

  return (
    <div>
      <Header
        authUser={user}
        logout={logout}
        isAuthenticated={isAuthenticated}
        accessToken={token}
        {...props}
      />
      ;
    </div>
  );
};

export default HeaderFn;
