import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  name: null,
  animalTypeId: 1,
  animalSubTypeId: 1,
  carryTypeId: 1,
  carryTypeName: null,
  carryPerHd: null,
  weeksHeld: null,
  weightGainKg: null,
  losePercentage: null,
  freightIn: null,
  buyingFee: null,
  drench: null,
  drenchDose: null,
  vet: null,
  vetDose: null,
  vaccine: null,
  vaccineDose: null,
  dipping: null,
  dippingDose: null,
  jetting: null,
  jettingDose: null,
  tags: null,
  processingOther: null,
  backgronundRate: null,
  agistmentRate: null,
  supplements: null,
  shearingPerHHead: null,
  shearingSundry: null,
  numberOfBales: null,
  freightPerBale: null,
  totalFreightOnWool: null,
  woolLevy: null,
  brokerageFee: null,
  awexTradingFee: null,
  othingTestingCosts: null,
  yardLevy: null,
  agentCommission: null,
  advertising: null,
  freightOut: null,
  annualInterestRate: null,
  interestOnCapital: null,
  interestOnCarry: null,
  aircraft: null,
  labourDays: null,
  labourUnits: null,
  labourRate: null,
  costOfLabour: null,

  message: "no message",
  isLoading: false,
  isSaving: false,
};

export const carrySlice = createSlice({
  name: "carrySlice",
  initialState,
  reducers: {
    CarryIsLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };
      return state;
    },

    CarryIsSaving: (state, action) => {
      state = {
        ...state,
        isSaving: action.payload,
        message: "Carry is saving",
      };
      return state;
    },

    CarryAdd: (state, action) => {
      state = action.payload;

      return state;
    },

    CarryDelete: (state, action) => {
      state = action.payload;
      return state;
    },

    CarryFetch: (state, action) => {
      action.payload.data.isLoading = false;
      action.payload.data.isSaving = false;
      action.payload.data.message = "no message";
      state = action.payload.data;

      return state;
    },

    CarryFetchError: (state, action) => {
      state = { ...state, message: action.payload, isLoading: false };
      return state;
    },

    CarryClear: (state) => {
      state = initialState;
      return state;
    },

    CarryError: (state, action) => {
      state = { ...state, message: action.payload };
      return state;
    },

    CarryMessage: (state, action) => {
      state = { ...state, message: action.payload };
      return state;
    },

    CarryUpdate: (state, action) => {
      state = {
        ...state,
        weeksHeld: action.payload.weeksHeld,
        name: action.payload.name,
        carryTypeId: action.payload.carryTypeId,
      };
      return state;
    },

    CarryChangeAnimalType: (state, action) => {
      state = { ...state, animalTypeId: action.payload };

      return state;
    },

    CarryChangeAnimalSubType: (state, action) => {
      state = { ...state, animalSubTypeId: action.payload };
      return state;
    },

    CarryChangeType: (state, action) => {
      state = { ...state, carryTypeId: action.payload };
      return state;
    },

    CarryChangeDraft: (state, action) => {
      state = {
        ...state,
        name: action.payload.name,
        advertising: action.payload.advertising,
        agentCommission: action.payload.agentCommission,
        agistmentRate: action.payload.agistmentRate,
        aircraft: action.payload.aircraft,
        annualInterestRate: action.payload.annualInterestRate,
        awexTradingFee: action.payload.awexTradingFee,
        backgronundRate: action.payload.backgronundRate,
        brokerageFee: action.payload.brokerageFee,
        buyingFee: action.payload.buyingFee,
        carryPerHd: action.payload.carryPerHd,
        costOfLabour: action.payload.costOfLabour,
        dipping: action.payload.dipping,
        dippingDose: action.payload.dippingDose,
        drench: action.payload.drench,
        drenchDose: action.payload.drenchDose,
        freightIn: action.payload.freightIn,
        freightOut: action.payload.freightOut,
        freightPerBale: action.payload.freightPerBale,
        interestOnCapital: action.payload.interestOnCapital,
        interestOnCarry: action.payload.interestOnCarry,
        jetting: action.payload.jetting,
        jettingDose: action.payload.jettingDose,
        labourDays: action.payload.labourDays,
        labourRate: action.payload.labourRate,
        labourUnits: action.payload.labourUnits,
        losePercentage: action.payload.losePercentage,
        numberOfBales: action.payload.numberOfBales,
        othingTestingCosts: action.payload.othingTestingCosts,
        processingOther: action.payload.processingOther,
        shearingPerHHead: action.payload.shearingPerHHead,
        shearingSundry: action.payload.shearingSundry,
        supplements: action.payload.supplements,
        tags: action.payload.tags,
        totalFreightOnWool: action.payload.totalFreightOnWool,
        vaccine: action.payload.vaccine,
        vaccineDose: action.payload.vaccineDose,
        vet: action.payload.vet,
        vetDose: action.payload.vetDose,
        weeksHeld: action.payload.weeksHeld,
        weightGainKg: action.payload.weightGainKg,
        woolLevy: action.payload.woolLevy,
        yardLevy: action.payload.yardLevy,
      };
      return state;
    },
  },
});

export const {
  CarryIsLoading,
  CarryIsSaving,
  CarryAdd,
  CarryDelete,
  CarryChangeAnimalType,
  CarryChangeAnimalSubType,
  CarryChangeDraft,
  CarryClear,
  CarryFetch,
  CarryChangeType,
  CarryError,
  CarryFetchError,
  CarryUpdate,
  CarryMessage,
} = carrySlice.actions;

export default carrySlice.reducer;

// import {
//   CARRY_UPDATE,
//   CARRY_FETCH,
//   CARRY_ADD,
//   CARRY_FETCH_ERROR,
//   CARRY_ISLOADING,
//   CARRY_ISSAVING,
//   CARRY_DELETE,
//   CARRY_CLEAR,
//   CARRY_CHANGE_ANIMAL_TYPE,
//   CARRY_CHANGE_ANIMAL_SUBTYPE,
//   CARRY_CHANGE_TYPE,
//   CARRY_CHANGE_DRAFT,
//   CARRY_ERROR
// } from '../actions/carryActions.js';
// export type State = {
//   name: string,
//   animalTypeId: number,
//   animalSubTypeId: number,
//   carryTypeId: number,
//   carryTypeName: string,
//   carryPerHd: number,
//   weeksHeld: number,
//   weightGainKg: number,
//   losePercentage: number,
//   freightIn: number,
//   buyingFee: number,
//   drench: number,
//   drenchDose: number,
//   vet: number,
//   vetDose: number,
//   vaccine: number,
//   vaccineDose: number,
//   dipping: number,
//   dippingDose: number,
//   jetting: number,
//   jettingDose: number,
//   tags: number,
//   processingOther: number,
//   backgronundRate: number,
//   agistmentRate: number,
//   supplements: number,
//   shearingPerHHead: number,
//   shearingSundry: number,
//   numberOfBales: number,
//   freightPerBale: number,
//   totalFreightOnWool: number,
//   woolLevy: number,
//   brokerageFee: number,
//   awexTradingFee: number,
//   othingTestingCosts: number,
//   yardLevy: number,
//   agentCommission: number,
//   advertising: number,
//   freightOut: number,
//   annualInterestRate: number,
//   interestOnCapital: number,
//   interestOnCarry: number,
//   aircraft: number,
//   labourDays: number,
//   labourUnits: number,
//   labourRate: number,
//   costOfLabour: number,
//   message: string,
//   isLoading: boolean,
//   isSaving: boolean
// };

// const initialState: State = {
//   name: null,
//   animalTypeId: 1,
//   animalSubTypeId: 1,
//   carryTypeId: 1,
//   carryTypeName: null,
//   carryPerHd: null,
//   weeksHeld: null,
//   weightGainKg: null,
//   losePercentage: null,
//   freightIn: null,
//   buyingFee: null,
//   drench: null,
//   drenchDose: null,
//   vet: null,
//   vetDose: null,
//   vaccine: null,
//   vaccineDose: null,
//   dipping: null,
//   dippingDose: null,
//   jetting: null,
//   jettingDose: null,
//   tags: null,
//   processingOther: null,
//   backgronundRate: null,
//   agistmentRate: null,
//   supplements: null,
//   shearingPerHHead: null,
//   shearingSundry: null,
//   numberOfBales: null,
//   freightPerBale: null,
//   totalFreightOnWool: null,
//   woolLevy: null,
//   brokerageFee: null,
//   awexTradingFee: null,
//   othingTestingCosts: null,
//   yardLevy: null,
//   agentCommission: null,
//   advertising: null,
//   freightOut: null,
//   annualInterestRate: null,
//   interestOnCapital: null,
//   interestOnCarry: null,
//   aircraft: null,
//   labourDays: null,
//   labourUnits: null,
//   labourRate: null,
//   costOfLabour: null,

//   message: 'no message',
//   isLoading: false,
//   isSaving: false
// };

// export default function carryReducer(
//   state: State = initialState,
//   { type, payload }
// ): State {
//   switch (type) {
//     case CARRY_ISLOADING:
//       state = { ...state, isLoading: payload };

//       break;

//     case CARRY_ISSAVING:
//       state = { ...state, isSaving: payload };
//       break;

//     case CARRY_ADD:
//       state = payload;
//       break;

//     case CARRY_DELETE:
//       state = payload;
//       break;

//     case CARRY_FETCH:
//       payload.data.isLoading = false;
//       payload.data.message = 'no message';
//       state = payload.data;

//       break;

//     case CARRY_FETCH_ERROR:
//       state = { ...state, message: payload, isLoading: false };
//       break;

//     case CARRY_CLEAR:
//       state = initialState;
//       break;

//     case CARRY_ERROR:
//       state = { ...state, message: payload };
//       break;
//     case CARRY_UPDATE:
//       state = {
//         ...state,
//         weeksHeld: payload.weeksHeld,
//         name: payload.name,
//         carryTypeId: payload.carryTypeId
//       };

//       break;

//     case CARRY_CHANGE_ANIMAL_TYPE:
//       state = { ...state, animalTypeId: payload };
//       break;

//     case CARRY_CHANGE_ANIMAL_SUBTYPE:
//       state = { ...state, animalSubTypeId: payload };
//       break;

//     case CARRY_CHANGE_TYPE:
//       state = { ...state, carryTypeId: payload };
//       break;

//     case CARRY_CHANGE_DRAFT:
//       state = {
//         ...state,
//         name: payload.name,
//         advertising: payload.advertising,
//         agentCommission: payload.agentCommission,
//         agistmentRate: payload.agistmentRate,
//         aircraft: payload.aircraft,
//         annualInterestRate: payload.annualInterestRate,
//         awexTradingFee: payload.awexTradingFee,
//         backgronundRate: payload.backgronundRate,
//         brokerageFee: payload.brokerageFee,
//         buyingFee: payload.buyingFee,
//         carryPerHd: payload.carryPerHd,
//         costOfLabour: payload.costOfLabour,
//         dipping: payload.dipping,
//         dippingDose: payload.dippingDose,
//         drench: payload.drench,
//         drenchDose: payload.drenchDose,
//         freightIn: payload.freightIn,
//         freightOut: payload.freightOut,
//         freightPerBale: payload.freightPerBale,
//         interestOnCapital: payload.interestOnCapital,
//         interestOnCarry: payload.interestOnCarry,
//         jetting: payload.jetting,
//         jettingDose: payload.jettingDose,
//         labourDays: payload.labourDays,
//         labourRate: payload.labourRate,
//         labourUnits: payload.labourUnits,
//         losePercentage: payload.losePercentage,
//         numberOfBales: payload.numberOfBales,
//         othingTestingCosts: payload.othingTestingCosts,
//         processingOther: payload.processingOther,
//         shearingPerHHead: payload.shearingPerHHead,
//         shearingSundry: payload.shearingSundry,
//         supplements: payload.supplements,
//         tags: payload.tags,
//         totalFreightOnWool: payload.totalFreightOnWool,
//         vaccine: payload.vaccine,
//         vaccineDose: payload.vaccineDose,
//         vet: payload.vet,
//         vetDose: payload.vetDose,
//         weeksHeld: payload.weeksHeld,
//         weightGainKg: payload.weightGainKg,
//         woolLevy: payload.woolLevy,
//         yardLevy: payload.yardLevy
//       };

//       break;

//     default:
//       return state;
//   }

//   return state;
// }
