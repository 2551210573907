import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trades: [],

  message: "no message",
  isLoading: false,
};

export const tradesSlice = createSlice({
  name: "calcSlice",
  initialState,
  reducers: {
    FetchTrades: (state, action) => {
      state = { ...state, trades: action.payload, isLoading: false };
      return state;
    },

    TradesAreLoading: (state, action) => {
      state = { ...state, isLoading: action.payload };
      return state;
    },
  },
});

export const { FetchTrades, TradesAreLoading } = tradesSlice.actions;

export default tradesSlice.reducer;

// import {
//   FETCH_TRADES,
//   TRADES_ARELOADING
// } from '../actions/currentTradeActions.js';

// export type State = {
//   trades: array,

//   message: string,
//   isLoading: boolean
// };

// const initialState: State = {
//   trades: [],

//   message: 'no message',
//   isLoading: false
// };

// export default function tradesReducer(
//   state: State = initialState,
//   { type, payload }
// ): State {
//   switch (type) {
//     case FETCH_TRADES:
//       state = { ...state, trades: payload, isLoading: false };
//       break;

//     case TRADES_ARELOADING:
//       state = { ...state, isLoading: payload };

//       break;

//     default:
//       return state;
//   }

//   return state;
// }
