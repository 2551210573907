import { loadTrades } from "../actions/currentTradeActions";
import { batchActions } from "redux-batched-actions";

import { TradesAreLoading } from "../reducers/tradesReducer";
import { UpdateTrade as updateTradeName } from "../reducers/currentTradeReducer";

import axios from "axios";

async function deleteCurrentGroupTrades(dispatch, auth, userId, ids, nav) {
  try {
    if (ids.length > 0) {
      const headers = { Authorization: `Bearer ${auth}` };

      dispatch(TradesAreLoading(true));

      for (let i = 0; i < ids.length; i++) {
        var updateURL = `${process.env.REACT_APP_API_DOMAIN}/api/Trades/${userId}/detail/${ids[i]}`;
        await axios.delete(updateURL, { headers });
      }

      nav(`/trades/${userId}/TradeList`);

      dispatch(batchActions([loadTrades(dispatch, auth, userId)]));
    }
  } catch (error) {
    console.error(error);
  }
}

export const updateTrade = (newTradeName) => (dispatch) => {
  dispatch(updateTradeName(newTradeName));
};

export const deleteGroupTrades = (auth, userId, Ids, nav) => (dispatch) => {
  deleteCurrentGroupTrades(dispatch, auth, userId, Ids, nav);
};
