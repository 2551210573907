import { useNavigate, useParams } from "react-router-dom";
import InfoView from "./info";

const InfoViewFn = (props) => {
  const nav = useNavigate();
  const params = useParams();

  return <InfoView useNavigate={nav} useParams={params} {...props} />;
};

export default InfoViewFn;
