import React from 'react';

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

class PopoverDetail extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      popover: false
    };
  }

  toggle() {
    this.setState({ popover: !this.state.popover });
  }

  componentDidMount() {}

  render() {
    return (
      <span>
        <i
          className=" fas fa-info-circle"
          id={this.props.id}
          onClick={this.toggle}
        />

        <Popover
          placement="right"
          isOpen={this.state.popover}
          target={this.props.id}
          toggle={this.toggle}>
          <PopoverHeader>{this.props.title}</PopoverHeader>
          <PopoverBody>{this.props.description}</PopoverBody>
        </Popover>
      </span>
    );
  }
}
export default PopoverDetail;
