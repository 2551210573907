import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carry: 0,
  trade: {},
};

export const calcSlice = createSlice({
  name: "calcSlice",
  initialState,
  reducers: {
    CarryUpdateCalc: (state, action) => {
      state = { ...state, carry: action.payload };

      return state;
    },

    TradeUpdateCalc: (state, action) => {
      state = { ...state, trade: action.payload };
      return state;
    },
  },
});

export const { CarryUpdateCalc, TradeUpdateCalc } = calcSlice.actions;

export default calcSlice.reducer;

// import {
//   CARRY_UPDATE_CALC,
//   TRADE_UPDATE_CALC,
//} from "../actions/calcActions.js";

// export type State = {
//   carry: Number,
//   trade: object
// };

// const initialState: State = {
//   carry: 0,
//   trade: {}
// };

// export default function calcReducer(
//   state: State = initialState,
//   { type, payload }
// ): State {
//   switch (type) {
//     case CARRY_UPDATE_CALC:
//       state = { ...state, carry: payload };
//       break;

//     case TRADE_UPDATE_CALC:
//       state = { ...state, trade: payload };

//       break;

//     default:
//       return state;
//   }

//   return state;
// }
