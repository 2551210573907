import React from "react";

//import { Form } from "react-final-form";
//import styled from "styled-components";
import { Card, CardBody, CardTitle } from "reactstrap";
//import "react-table/react-table.css";
//import * as data from "./reactable-data";
import { fetchGroups } from "../../actions/groupActions.js";
import Footer from "../../components/footer/footer.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loading from "../../components/spinners/loading.jsx";

import { navControls } from "../../actions/navActions.js";

import ReactTable from "../../components/table/table.js";

class TradeGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      obj: {},
      data: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleClick = (id, e) => {

    
    this.props.useNavigate(
      `/trades/${this.props.useParams.Slug}/tradeGroupDetail/${id}`
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navControls({
      isShowControls: false,
      isShowDelete: false,
      isShowSave: false,
    });

    this.props.fetchGroups(null, null);
  }

  render() {
    const columns = {
      columns: [
        // {
        //   Header: "",
        //   accessor: "isGoing",
        //   width: 30,

        //   Cell: (props) => (
        //     <span>
        //       <input
        //         name="isGoing"
        //         height="50px"
        //         width="20px"
        //         type="checkbox"
        //         className="checkbox"
        //       />
        //     </span>
        //   ),
        // },
        {
          Header: "Group Name",
          accessor: "name",

          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
      ],
    };

    return (
      <div>
        <Card>
          <CardTitle className="bg-light p-3 mb-0">
            <img alt="buy" src="/buyLogo.png" />
            <span>&nbsp;</span>Trade Groups
          </CardTitle>
          <CardBody className="tableCells">
            {/* <MyTable /> */}
            {this.props.groups && (
              <ReactTable
                columns={columns.columns}
                data={this.props.groups}
                getRowProps={(row) => ({
                  onClick: () => this.handleClick(row.original.id),
                })}
              />
            )}
            {/* <table
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: (e) => {
                  this.handleClick(rowInfo.original.id);
                },
              })}
              data={this.props.data}
              columns={[
                {
                  Header: "Group Name",
                  accessor: "name",
                  Cell: (props) => (
                    <span className="cursorHand">{props.value}</span>
                  ),
                },
              ]}
              defaultPageSize={20}
              loadingText="Loading..."
              className="-striped -highlight"
              showPageSizeOptions={false}
              showPagination={false}
            />
            <table
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: (e) => {
                  this.handleClick(rowInfo.original.id);
                },
              })}
              data={this.props.groups}
              columns={[
                {
                  Header: "Group Name",
                  accessor: "name",
                  Cell: (props) => (
                    <span className="cursorHand">{props.value}</span>
                  ),
                },
              ]}
              defaultPageSize={20}
              loadingText="Loading..."
              className="-striped -highlight"
              showPageSizeOptions={false}
              showPagination={false}
            /> */}
          </CardBody>
        </Card>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.groups.groups,
    groups: state.groups.groups,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchGroups,
      navControls,
    },
    dispatch
  );
};

TradeGroups = connect(mapStateToProps, mapActionsToProps)(TradeGroups);

export default TradeGroups;
