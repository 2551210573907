import React from "react";

import { Card, CardBody, CardTitle } from "reactstrap";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Banner from "../../components/banner/banner";
import Expire from "../../components/expire/expire";

import Loading from "../../components/spinners/loading";
import Footer from "../../components/footer/footer.jsx";

import { navControls, navDelete } from "../../actions/navActions.js";

import { deleteGroupCarrys } from "../../actions/carryActions.js";
import ReactTable from "../../components/table/table.js";
class CarryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      obj: {},
      selectedData: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  // handleClick = (id, e) => {
  //   this.props.useNavigate(
  //     `/carry/${this.props.useParams.Slug}/carryDetail/${id}`
  //   );
  // };

  componentDidUpdate() {
    if (this.props.navIsPendingDelete) {




      this.props.navDelete({ isPendingDelete: false });


      console.log("To delete");
      console.log(this.state.selectedData);

      this.props.deleteGroupCarrys(
        this.props.user.auth,
        this.props.useParams.Slug,
        this.state.selectedData,
        this.props.trades,
        this.props.useNavigate
      );
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navControls({
      isShowControls: false,
      isShowDelete: true,
      isShowSave: false,
    });
  }

  handleClick = (id, e) => {
    this.props.useNavigate(
      `/carry/${this.props.useParams.Slug}/carryDetail/${id}`
    );
  };

  handleInputChange = (id) => {

    console.log(id);

     const local = this.state.selectedData;
     var index = this.state.selectedData.indexOf(id);
     if (index < 0) {
       local.push(id);
     } else {
       local.splice(index, 1);
     }

     console.log(local);

    // this.setState({
    //   selectedData: local,
    // });

    // console.log(this.state.selectedData);
  };

  render() {
    var banner;

    if (this.props.message !== "no message") {
      banner = (
        <Expire delay={5000}>
          {" "}
          <Banner message={this.props.message} />
        </Expire>
      );
    }

    const columns = {
      columns: [
        // {
        //   Header: "",
        //   accessor: "isSaleOverprice",
        //   width: 30,

        //   Cell: (props) => (
        //     <span>
        //       <input
        //         name="isGoing"
        //         height="50px"
        //         type="checkbox"
        //         className="checkbox"
        //       />
        //     </span>
        //   ),
        // },
        {
          Header: "",
          accessor: "isSaleOverprice",
          width: 50,
          Cell: (props) => <span className="checkbox"><input name="isGoing" height="50px" type="checkbox" className="checkbox"></input></span>,
        },
        {
          Header: "Carry Name",
          accessor: "name",
          width: 160,
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "Animal Type",
          accessor: "carryTypeName",
          width: 160,
          Cell: (props) => <span className="cursorHand">{props.value}</span>,
        },
        {
          Header: "Time  Held",
          accessor: "weeksHeld",
          width: 160,
          Cell: (props) => (
            <span className="cursorHand">{props.value} weeks</span>
          ),
        },
      ],
    };

    return (
      <div className="minWidth">
        {this.props.isLoading ? (
          <Loading />
        ) : (
          <span>
            {banner}
            <Card>
              <CardTitle className="bg-light p-3 mb-0">
                <img alt="buy" src="/carry.png" />
                <span>&nbsp;</span>Cost Of Carry - Growth Rate
              </CardTitle>
              <CardBody className="tableContainer">
                <ReactTable
                  columns={columns.columns}
                  data={this.props.weightCarrys}
                  getRowProps={(row) => ({
                    onClick: (e) => {
                      if (e.target.className === "cursorHand") {

                        this.handleClick(row.original.id)
                      } else {

                        if (e.target.className === "checkbox") {
                          this.handleInputChange(row.original.id);
                        }
                      }
                      
                      
                    },
                  })}
                />

                {/* <table
                data={this.props.weightCarrys}
                getTrProps={(state, rowInfo, column, instance) => ({
                  onClick: (e) => {
                    if (e.target.className === "cursorHand") {
                      this.handleClick(rowInfo.original.id);
                    } else {
                      if (e.target.className === "checkbox") {
                        this.handleInputChange(rowInfo.original.id);
                      }
                    }
                  },
                })}
                columns={[
                  {
                    Header: "",
                    accessor: "isSaleOverprice",
                    width: 30,

                    Cell: (props) => (
                      <span>
                        <input
                          name="isGoing"
                          height="50px"
                          type="checkbox"
                          className="checkbox"
                        />
                      </span>
                    ),
                  },
                  {
                    Header: "Carry Name",
                    accessor: "name",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value}</span>
                    ),
                  },
                  {
                    Header: "Animal Type",
                    accessor: "carryTypeName",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value}</span>
                    ),
                  },
                  {
                    Header: "Growth Rate",
                    accessor: "weightGainKg",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value}kg</span>
                    ),
                  },
                ]}
                defaultPageSize={8}
                className="-striped -highlight"
                showPageSizeOptions={false}
                showPagination={false}
              /> */}
              </CardBody>
            </Card>

            <Card>
              <CardTitle className="bg-light p-3 mb-0">
                <img alt="buy" src="/carry.png" />
                <span>&nbsp;</span>Cost Of Carry - Weeks Held
              </CardTitle>
              <CardBody className="tableContainer">
                <ReactTable
                  columns={columns.columns}
                  data={this.props.weeksHeldCarrys}
                  getRowProps={(row) => ({
                    onClick: (e) => {
                      if (e.target.className === "cursorHand") {

                        this.handleClick(row.original.id)
                      } else {

                        if (e.target.className === "checkbox") {
                          this.handleInputChange(row.original.id);
                        }
                      }
                      
                      
                    }
                  })}
                />

                {/* <table
                data={this.props.weeksHeldCarrys}
                getTrProps={(state, rowInfo, column, instance) => ({
                  onClick: (e) => {
                    if (e.target.className === "cursorHand") {
                      this.handleClick(rowInfo.original.id);
                    } else {
                      if (e.target.className === "checkbox") {
                        this.handleInputChange(rowInfo.original.id);
                      }
                    }
                  },
                })}
                columns={[
                  {
                    Header: "",
                    accessor: "isSaleOverprice",
                    width: 30,

                    Cell: (props) => (
                      <span>
                        <input
                          name="isGoing"
                          height="50px"
                          type="checkbox"
                          className="checkbox"
                        />
                      </span>
                    ),
                  },
                  {
                    Header: "Carry Name",
                    accessor: "name",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value}</span>
                    ),
                  },
                  {
                    Header: "Animal Type",
                    accessor: "carryTypeName",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value}</span>
                    ),
                  },
                  {
                    Header: "Time  Held",
                    accessor: "weeksHeld",
                    width: 160,
                    Cell: (props) => (
                      <span className="cursorHand">{props.value} weeks</span>
                    ),
                  },
                ]}
                defaultPageSize={8}
                className="-striped -highlight"
                showPageSizeOptions={false}
                showPagination={false}
              /> */}
              </CardBody>
            </Card>
            <Footer />
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.carrys.carrys,
    weightCarrys: state.carrys.weightCarrys,
    weeksHeldCarrys: state.carrys.weeksHeldCarrys,
    isLoading: state.carrys.isLoading,
    navIsPendingDelete: state.nav.isPendingDelete,
    message: state.carrys.message,
    user: state.user,
    trades: state.trades.trades,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      navControls,
      navDelete,
      deleteGroupCarrys,
    },
    dispatch
  );
};

// You have to connect() to any reducers that you wish to connect to yourself
CarryList = connect(mapStateToProps, mapActionsToProps)(CarryList);

//(Form({ form: "CARRY_LIST", enableReinitialize: true })(CarryList));

export default CarryList;
