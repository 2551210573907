import React from "react";

class BasicInfoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.props.fetchInfo(this.props.useParams.Info);
  }

  render() {
    return (
      // <div className="d-flex justify-content-center ">
      <div className="d-flex  justify-content-center">
        {/*--------------------------------------------------------------------------------*/}
        {/* Highlighted Header Form                                                        */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {this.props.auth ? this.props.auth.isLoggedIn ? 'true': 'false': 'nada'} */}

        <h1>Victory</h1>
      </div>
    );
  }
}

export default BasicInfoView;
