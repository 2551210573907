import React from "react";
import { Route, Routes, redirect } from "react-router-dom"; //Switch

import Auth from "../auth/auth";
import Home from "../views/home/home";

class LandingLayout extends React.Component {
  /*--------------------------------------------------------------------------------*/
  /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);

    const auth = null; //new Auth(); //CB TODO

    this.state = {
      auth: auth,
      isOpen: false,
      width: window.innerWidth,
      settings: [
        {
          theme: "light",
          layout: "vertical",
          dir: "ltr",
          sidebartype: "full",
          sidebarpos: "fixed",
          headerpos: "fixed",
          boxed: "full",
          navbarbg: "skin4",
          sidebarbg: "skin6",
          logobg: "skin4",
        },
      ],
    };

    // this.props.history.listen((location, action) => {
    //   if (
    //     window.innerWidth < 767 &&
    //     document
    //       .getElementById("main-wrapper")
    //       .className.indexOf("show-sidebar") !== -1
    //   ) {
    //     document
    //       .getElementById("main-wrapper")
    //       .classList.toggle("show-sidebar");
    //   }
    // });
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    // if (this.state.auth.isAuthenticated() === "true") {
    //   this.state.auth.renewSession();
    // }
  }

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById("main-wrapper");
    this.setState({
      width: window.innerWidth,
    });
  }

  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    //window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <div id="main-wrapper">
        {/*--------------------------------------------------------------------------------*/}
        {/* Page Main-Content                                                              */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="page-wrapper d-block whiteBack">
          <Home />
        </div>
      </div>
    );
  }
}
export default LandingLayout;
